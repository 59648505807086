<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalEditSeals :edit="false" :modal="showModalEditSeals" @closeModal="showModalEditSeals=false" @child-refresh="refrescarTabla=true" />
        <div v-show="!showDamageList" >
            <CRow class="mb-3">
                <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.InspectionsDone') }}</h6>
                </CCol>
                <CCol sm="7" lg="4" class="d-flex align-items-center justify-content-end">
                    <CButton
                        color="excel"
                        class="mr-1" 
                        size="sm"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XSLX
                    </CButton>
                    <CButton
                        color="watch"
                        size="sm"  
                        @click="onBtnExport(false)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; CSV
                    </CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="11" lg="5" class="center-field">
                    <CSelect
                        :value.sync="InspectionType"
                        v-model.trim="InspectionType"
                        :horizontal="{ label: 'col-sm-10 col-lg-auto text-right', input: 'col-sm-10 col-lg-7 px-0'}"
                        :label="$t('label.inspectionType')"
                        :options="InspectionTypelist"
                        @change="filterInspectionType"
                    />
                </CCol>
                <CCol sm="11" lg="4" class="center-field">
                    <CSelect
                        :horizontal="{ label: 'col-sm-10 col-lg-6 text-right', input: 'col-sm-10 col-lg-6 px-0'}"
                        :label="$t('label.damageReport')"
                        :options="DamageTypeList"
                        v-model.trim="damageReport"
                        :value.sync="damageReport"
                        @change="filterDamageReport"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="11" lg="3" class="center-field">
                    <CInput
                        :label="$t('label.filter')"
                        :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                        :placeholder="$t('label.enterSearch')" 
                        @keyup="onQuickFilterChanged($event.target.value)" 
                        v-model="search"           
                    >
                    </CInput>  
                </CCol>
                <CCol sm="11" lg="5" class="center-field">
                </CCol>
                <CCol sm="11" lg="4" class="justify-content-end">
                    <CSelect
                        :horizontal="{ label: 'col-sm-10 col-lg-8 text-right', input: 'col-sm-10 col-lg-4 px-0'}"
                        :label="$t('label.recordsPerPage')"
                        @change="onPageSizeChanged"
                        :options="[50,100,500,1000]"
                        required 
                        v-model="pageSize"
                    >
                    </CSelect>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="11" lg="12" xl="12" class=" mb-2">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                        :tooltipShowDelay="tooltipShowDelay"
                        :tooltipHideDelay="tooltipHideDelay"
                    >
                    </ag-grid-vue>
                </CCol>
            </CRow>
        </div>
        <div v-show="showDamageList">
            <collapseDamageList 
                :damage-data="damageData" 
                @closeCollapseDamage="showDamageList=false" 
                @child-refresh="refrescarTabla=true" 
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import modalEditSeals from '@/pages/visita/tabs/inspeccion/modal-edit-seals';
    import collapseDamageList from '@/pages/visita/tabs/inspeccion/index-damage';
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';

    //Data
    function data() {
        return {
            tooltipShowDelay: 0,
            tooltipHideDelay: 2000,
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            formatedItems: [],
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            loadingOverlay:false,
            InspectionType: "",
            InspectionTypeName: "",
            InspectionSelect: [],
            damageReport: 0,
            damageTypeName: "",
            nulo:'', 
            pageSize:'',
            search: '',
            showModalEditSeals: '',
            showDamageList: false,
            damageData: '',
        };
    }
    //beforeMount
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'Editar'){
                   if(event.data.inspectionSeals){
                        this.showModalEditSeals= event.data;
                    }else if(!event.data.inspectionSeals){
                        this.damageData = event.data;
                        this.showDamageList = true;
                    }
                }
                else if(event.colDef.colId == 'Reporte'){
                    if(event.value !== ""){
                        this.getReportInspection(event.data);
                    }
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'Editar'){
                   tippy('#myEditButton', {
                        content: this.$t('label.see')+' '+this.$t('label.details'),
                    })
                } else if(event.colDef.colId == 'Reporte'){
                    tippy('#myDownloadDamageReportButton', {
                        content: this.$t('label.download')+' '+this.$t('label.damageReport'),
                    })
                }
            },

        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function created() {
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.tooltipShowDelay = 0;
        this.tooltipHideDelay = 2000;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];

        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if(this.InspectionType == ""){
                this.InspectionTypeName = this.$t('label.ALL');
            }   

            if(this.damageReport == 0){
            this.damageTypeName = this.$t('label.all');
            }
            if (this.damageReport == 1) {
                this.damageTypeName = this.$t('label.apply');
            }
            if(this.damageReport == 2){
                this.damageTypeName = "N/A";
            }
            await this.getExcelInspection(rowData,this.$t('label.InspectionsDone'),valor,this.InspectionTypeName, this.damageTypeName);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        //this.getInspectionList();
        this.loadingOverlay = false;
    }

    async function getInspectionList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i = 1;    

        let InspectionJson = {
            VisitId: this.Visit,
            TpInspectionId: this.InspectionType,
            FgDamageReport: this.damageReport,
        };

        await this.$http.post("VisitCargoInspectionReport-List", InspectionJson, { root: 'InspectionJson' })
        .then(response => {
            listado = [...response.data.data];

            let _lang = this.$i18n.locale;
            let _this = this;
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado =='') return false;
            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                Idx: i++,
                VisitCargoId: listado.VisitCargoId ? listado.VisitCargoId : '',
                Editar: _this.$t('label.see')+' '+_this.$t('label.details'),
                Reporte: _this.$t('label.download')+' '+_this.$t('label.damageReport'),
                ContainerCode: listado.ContainerCode ? listado.ContainerCode : '',
                TpCargoSize: listado.TpCargoSize ? listado.TpCargoSize : '',
                TbTpCargoStatus: listado.TbTpCargoStatus ? listado.TbTpCargoStatus : '',
                IsoCode: listado.IsoCode ? listado.IsoCode : '',
                OperatorName: listado.OperatorName ? listado.OperatorName  : '',
                SupervisorName: listado.SupervisorName ? listado.SupervisorName : '',
                DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
                TransactionRegDate: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlash(listado.TransaRegDate) : '',
                TransactionDate: listado.TransactionDate ? DateFormater.formatOnlyDateWithSlash(listado.TransactionDate) : '',
                TransaLogin: listado.TransaLogin ? listado.TransaLogin : '', 
                InpectionNumberCode: listado.InpectionNumberCode ? listado.InpectionNumberCode : '',
                ShippingLineCode: listado.ShippingLineCode ? listado.ShippingLineCode : '',
                TpInspectionName:  _lang=='en' ? listado.TpInspectionNameEn : listado.TpInspectionNameEs,
                MovStowageName:  _lang=='en' ? listado.MovStowageNameEn : listado.MovStowageNameEs,
                inspectionSeals: listado.TpInspectionId=="E27F9D05-9879-43C6-A186-87D707984033" ? true : false, //tipo de inspeccion true=precintos, false=daños
                VisitCargoInspectionId: listado.VisitCargoInspectionId ? listado.VisitCargoInspectionId : '',
                MovStowageName: _lang=='en' ? listado.MovStowageNameEn : listado.MovStowageNameEs,
                TransaRegDate: listado.TransaRegDate ? listado.TransaRegDate : '',
                FgActVisitCargoInspection:  listado.TpInspectionId=="E27F9D05-9879-43C6-A186-87D707984033" ?
                                            (listado.SealInspectionJson.length > 0 ? listado.SealInspectionJson[0].FgActVisitCargoInspection : '') : 
                                            '',
                InspectionMomentId:  listado.TpInspectionId=="E27F9D05-9879-43C6-A186-87D707984033" ?
                                            (listado.SealInspectionJson.length > 0 ? listado.SealInspectionJson[0].InspectionMomentId : '') : 
                                            '',
                SealInspection: listado.TpInspectionId=="E27F9D05-9879-43C6-A186-87D707984033" ? listado.SealInspectionJson : '',
                VisitCargoInspectionMasterId: listado.VisitCargoInspectionMasterId ? listado.VisitCargoInspectionMasterId : '',            
                TransaDate: listado.TransactionDate,
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function filterDamageReport(e) {
        this.damageReport=e.target.value;
        await this.getInspectionList();
    }

    function filterInspectionType(e) {
        this.InspectionType=e.target.value;
        const findInspectionTypeName = this.InspectionTypelist.find( item => item.value === e.target.value );
        this.InspectionTypeName = findInspectionTypeName.label;
        this.getInspectionList();
    } 
//opciones de tipo de inspecciones
    async function getInspectionSelect() {
        await this.$http
            .get('TpInspectionForReport-list')
            .then((res) => (this.InspectionSelect = res.data.data));
    }

    async function getReportInspection(item) {
        this.loadingOverlay = true;
        let listado = Array;
        let damageReportData = [];
        let inspectionData = [];
        let _this = this.$i18n.locale;  
        let estatus, InspectionMoment, seals, damage, inspectionType;

        await this.$http.get("VisitCargoDamageReport", { VisitCargoInspectionMasterId: item.VisitCargoInspectionMasterId })
            .then(response => {
                listado = [...response.data.data];
                let i = 1;
                let j = listado[0].DamageDescriptionJson.length;
                seals = listado[0].SealInspectionJson;
                damage = listado[0].DamageDescriptionJson;

                if (damage.length > 0){
                    InspectionMoment = damage[j-1].InspectionMomentId ? damage[j-1].InspectionMomentId : '';
                    inspectionType = true;

                    inspectionData = damage.map(damage => Object.assign({}, inspectionData, {
                    TpDamage: damage.TpDamageAbbrev+":"+(_this=='en' ? 
                        (damage.TpDamageNameEn ? damage.TpDamageNameEn : '') : 
                        (damage.TpDamageName ? damage.TpDamageName : '')
                    )     ,
                    TpCargoViewName: _this=='en' ? 
                        (damage.TpCargoViewNameEn ? damage.TpCargoViewNameEn : '') : 
                        (damage.TpCargoViewName ? damage.TpCargoViewName : ''),
                    Dimension: ((damage.Width!=="") && (damage.Height!=="")) ? damage.Width+" x "+damage.Height : "",
                    Observation: damage.Observation ? damage.Observation: '',
                    }));

                } else if(listado[0].SealInspectionJson.length > 0){
                    InspectionMoment = listado[0].SealInspectionJson[0].InspectionMomentId ? listado[0].SealInspectionJson[0].InspectionMomentId : '';
                    inspectionType = false;
                    inspectionData = [
                    {
                        PreviousSeal: seals[0].Seal1[0].PreviousSeal ? 'S1: '+seals[0].Seal1[0].PreviousSeal : 'S1: ',
                        Seal: seals[0].Seal1[0].Seal ? 'S1: '+seals[0].Seal1[0].Seal : '',
                        SealConditionName: _this=='en' ?
                        (seals[0].Seal1[0].SealConditionNameEn ? 'S1: '+seals[0].Seal1[0].SealConditionNameEn : '') : 
                        (seals[0].Seal1[0].SealConditionNameEs ? 'S1: '+seals[0].Seal1[0].SealConditionNameEs : ''),
                        ObservationSeal: seals[0].Seal1[0].ObservationSeal ? 'S1: '+seals[0].Seal1[0].ObservationSeal : 'S1: ',
                    },
                    {
                        PreviousSeal: seals[0].Seal2[0].PreviousSeal ? 'S2: '+seals[0].Seal2[0].PreviousSeal : 'S2: ',
                        Seal: seals[0].Seal2[0].Seal ? 'S2: '+seals[0].Seal2[0].Seal : 'S2: ',
                        SealConditionName: _this=='en' ? 
                        (seals[0].Seal2[0].SealConditionNameEn ? 'S2: '+seals[0].Seal2[0].SealConditionNameEn : '') : 
                        (seals[0].Seal2[0].SealConditionNameEs ? 'S2: '+seals[0].Seal2[0].SealConditionNameEs : ''),
                        ObservationSeal: seals[0].Seal2[0].ObservationSeal ? 'S2: '+seals[0].Seal2[0].ObservationSeal : 'S2: ',
                    },
                    {
                        PreviousSeal: seals[0].Seal3[0].PreviousSeal ? 'S3: '+seals[0].Seal3[0].PreviousSeal : 'S3: ',
                        Seal: seals[0].Seal3[0].Seal ? 'S3: '+seals[0].Seal3[0].Seal : 'S3: ',
                        SealConditionName: _this=='en' ? 
                        (seals[0].Seal3[0].SealConditionNameEn ? 'S3: '+seals[0].Seal3[0].SealConditionNameEn : '') :
                        (seals[0].Seal3[0].SealConditionNameEs ? 'S3: '+seals[0].Seal3[0].SealConditionNameEs : ''),
                        ObservationSeal: seals[0].Seal3[0].ObservationSeal ? 'S3: '+seals[0].Seal3[0].ObservationSeal : 'S3: ',
                    },
                    {
                        PreviousSeal: seals[0].Seal4[0].PreviousSeal ? 'S4: '+seals[0].Seal4[0].PreviousSeal : 'S4: ',
                        Seal: seals[0].Seal4[0].Seal ? 'S4: '+seals[0].Seal4[0].Seal : 'S4: ',
                        SealConditionName: _this=='en' ? 
                        (seals[0].Seal4[0].SealConditionNameEn ? 'S4: '+seals[0].Seal4[0].SealConditionNameEn : '') :
                        (seals[0].Seal4[0].SealConditionNameEs ? 'S4: '+seals[0].Seal4[0].SealConditionNameEs : ''),
                        ObservationSeal: seals[0].Seal4[0].ObservationSeal ? 'S4: '+seals[0].Seal4[0].ObservationSeal : 'S4: ',
                    },
                    ];   
                }   

                estatus = item.Estatus ? item.Estatus : '';
                damageReportData = listado.map(listado => Object.assign({}, damageReportData, {
                    Nro: i++,
                    BaySlot: listado.BaySlot ? listado.BaySlot : '', 
                    BerthName: listado.BerthName ? listado.BerthName : '', 
                    BlNo: listado.BlNo ? listado.BlNo : '', 
                    ContainerCode: listado.ContainerCode ? listado.ContainerCode : '', 
                    DateHourOper: listado.DateHourOper ? listado.DateHourOper : '', 
                    DischargePort: listado.DischargePort ? listado.DischargePort : '', 
                    LoadPort: listado.LoadPort ? listado.LoadPort : '', 
                    Size: listado.Size ? listado.Size : '', 
                    TpCargoCode: listado.TpCargoCode ? listado.TpCargoCode : '', 
                    TpCargoId: listado.TpCargoId ? listado.TpCargoId : '', 
                    VesselNameReport: listado.VesselName ? listado.VesselName : '', 
                    VisitCargoId: listado.VisitCargoId ? listado.VisitCargoId : '', 
                    Voyage: listado.Voyage ? listado.Voyage : '', 
                    OperatorName:  listado.OperatorName ? listado.OperatorName : '', 
                    ReceivedName: listado.ReceivedName ? listado.ReceivedName : '',           
                    SupervisorName: listado.SupervisorName ? listado.SupervisorName : '',
                    Estatus: estatus ? estatus : '',
                    InspectionMoment: InspectionMoment,
                    MovStowage: listado.MovStowageId ? listado.MovStowageId : '',
                    TransacStowage: listado.TransacStowageId ? listado.TransacStowageId : '',
                }));
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(() => {
            });

            if(listado.length > 0) {
                await this.getDamageReport(damageReportData[0], inspectionType, item.InpectionNumberCode, item.TransactionRegDate, inspectionData);
                this.loadingOverlay = false;
            }else{
                this.loadingOverlay = false;
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });

            }
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.showModalEditSeals= "";
        this.damageData = "";
        this.showDamageList = false;
        this.search = "";
        this.tooltipShowDelay = 0,
        this.tooltipHideDelay = 2000,
        this.InspectionType = "",
        this.InspectionTypeName = "",
        this.damageReport = 0,
        this.damageTypeName = "",
        this.pageSize="";
        this.onQuickFilterChanged(this.search);
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getInspectionSelect();
        await this.getInspectionList();
    }
//computed
    function InspectionTypelist(){
        let _this = this.$i18n.locale;
        const chart = [
            {
                value: "",
                label: this.$t('label.select'),
            },
        ];
        this.InspectionSelect.map((item) => {
            chart.push({
                label: _this=='en' ? item.TpInspectionNameEn : item.TpInspectionNameEs,
                value: item.TpInspectionId,
            });   
        });
        return chart;    
    }

    function DamageTypeList(){
        return [
            { label: this.$t('label.select'), value: 0 },
            { label: this.$t('label.apply'), value: 1 },
            { label: 'N/A', value: 2 },
        ];
    }

    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'Editar',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "Editar",

                cellRenderer: params => {
                    return  `
                        <div align="center">
                            <button id="myEditButton" class="btn mr-1 btn-watch btn-sm" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon" data-v-3be54f06="">
                                    <rect width="264" height="32" x="208" y="80" fill="var(--ci-primary-color, currentColor)" class="ci-primary">
                                    </rect>
                                    <path fill="var(--ci-primary-color, currentColor)" d="M40,96a64,64,0,1,0,64-64A64.072,64.072,0,0,0,40,96Zm64-32A32,32,0,1,1,72,96,32.036,32.036,0,0,1,104,64Z" class="ci-primary">
                                    </path>
                                    <rect width="264" height="32" x="208" y="240" fill="var(--ci-primary-color, currentColor)" class="ci-primary">
                                    </rect>
                                    <path fill="var(--ci-primary-color, currentColor)" d="M104,320a64,64,0,1,0-64-64A64.072,64.072,0,0,0,104,320Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,104,224Z" class="ci-primary">
                                    </path>
                                    <rect width="264" height="32" x="208" y="400" fill="var(--ci-primary-color, currentColor)" class="ci-primary">
                                    </rect><path fill="var(--ci-primary-color, currentColor)" d="M104,480a64,64,0,1,0-64-64A64.072,64.072,0,0,0,104,480Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,104,384Z" class="ci-primary">
                                    </path>
                                </svg>  
                            </button>
                        </div>
                    `
                },
                cellClass: 'center-cell-especial px-1',
            },
            {
                headerName: "",
                field: 'VisitCargoInspectionMasterId',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "Reporte",
                cellRenderer: params => {
                if(params.value !== ""){
                    return  `   
                        <Button id="myDownloadDamageReportButton" class="btn mr-1 btn-danger btn-sm"
                        >                   
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon">
                                <path fill="var(--ci-primary-color, currentColor)" d="M358.627,16H72V240H16V496H496V153.373ZM464,166.627V168H344V48h1.373ZM48,464V272H464V464Zm56-224V48H312V200H464v40Z" class="ci-primary">
                                </path>
                                <path fill="var(--ci-primary-color, currentColor)" d="M148.5,304H100V432h32V400h16.5A47.555,47.555,0,0,0,196,352.5v-1A47.555,47.555,0,0,0,148.5,304ZM164,352.5A15.517,15.517,0,0,1,148.5,368H132V336h16.5A15.517,15.517,0,0,1,164,351.5Z" class="ci-primary">
                                </path>
                                <polygon fill="var(--ci-primary-color, currentColor)" points="340 432 372 432 372 392 404 392 404 360 372 360 372 336 420 336 420 304 340 304 340 432" class="ci-primary">
                                </polygon>
                                <path fill="var(--ci-primary-color, currentColor)" d="M259.833,304H212V432h47.833A56.23,56.23,0,0,0,316,375.833V360.167A56.23,56.23,0,0,0,259.833,304ZM284,375.833A24.194,24.194,0,0,1,259.833,400H244V336h15.833A24.194,24.194,0,0,1,284,360.167Z" class="ci-primary">
                                </path> 
                            </svg>  
                        </Button>
                    `
                }
                },
                cellClass: 'center-cell-especial px-1',
            },
            {
                field: "Idx",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 70,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ContainerCode",
                headerName: this.$t('label.containerCode'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 220,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TpCargoSize",
                headerName: this.$t('label.size')+" "+"(FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 140,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineCode",
                headerName: this.$t('label.carrier'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 150,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TbTpCargoStatus",
                headerName: this.$t('label.status'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "InpectionNumberCode",
                headerName: this.$t('label.correlative'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "MovStowageName",
                headerName: this.$t('label.stowageMovement'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 225,
            },
            {
                field: "TpInspectionName",
                headerName: this.$t('label.inspectionType'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 220,
            },
            {
                field: "OperatorName",
                headerName: this.$t('label.operator'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "SupervisorName",
                headerName: this.$t('label.supervisor'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.device'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "TransactionRegDate",
                headerName: this.$t('label.TransactionDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 220,
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.inspectionDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.user'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
        ]
        return columnDefs;
    }

    export default {
        name: "inspection-done",
        data,
        beforeMount,
        created,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onGridReady,
            onBtnExport,
            getInspectionList,
            onQuickFilterChanged,
            onPageSizeChanged,
            filterDamageReport,
            refreshComponent,
            filterInspectionType,
            getInspectionSelect,
            getReportInspection,
        },
        computed:{
            InspectionTypelist,
            DamageTypeList,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
            }),
        },
        components:{
            modalEditSeals,
            collapseDamageList,
            tippy,
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==41) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .tippy-box[data-theme~='bluesimple'] {
        fill: #6ca3d7;
        color:#606a87;
    }
/**
 ****************************
 * Tooltip
 ****************************
*/
    .ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom) {
        -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
                box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    }

    .ag-theme-alpine .ag-tooltip {
        background-color: #000015;
        background-color: var(--ag-header-background-color, #000015);
        color: #fff;
        color: var(--ag-foreground-color, #fff);
        padding: 6px;
        border: solid 1px;
        border-color: #000015;
        border-color: var(--ag-border-color, #000015);
        border-radius: 3px;
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
        white-space: normal;
        text-align: center;
        border-radius: 0.25rem;
    }

    .ag-theme-alpine .ag-tooltip.ag-tooltip-hiding {
        opacity: 0;
    }

    .ag-theme-alpine .ag-tooltip-custom {
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
    }

    .ag-theme-alpine .ag-tooltip-custom.ag-tooltip-hiding {
        opacity: 0;
    }

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>