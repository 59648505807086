<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.dailyReport') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click.prevent="printPdf"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3">
                <div class="position-relative">
                    <div class="form-group form-row" rol="group">
                        <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2">{{$t('label.date')}}</label>
                        <div class="input-group col-sm-12 col-lg-9">
                            <CCol sm="12" class="px-0">
                                <vue-datepicker 
                                    type="date"
                                    header
                                    :lang="this.$i18n.locale"
                                    :editable="false"
                                    :clearable="false"
                                    format="DD/MM/YYYY"
                                    placeholder="DD/MM/YYYY"
                                    time-title-format="DD/MM/YYYY"
                                    v-model="searchFilterDate.Date"
                                    :disabled-date="validateDateRange"
                                    :append-to-body="false"
                                    value-type="format"
                                    :show-second="false"
                                    @change="filterDate"
                                >
                                    <template #icon-calendar>
                                        <div style="display: none"></div>
                                    </template>
                                </vue-datepicker>
                            </CCol>
                        </div>
                    </div>
                </div>
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-2 col-xl-2 text-right', input: 'col-sm-12 col-lg-7 col-xl-7'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                />
            </CCol>
        </CRow>
        <CRow>
            <div class="table-responsive col-sm-12">
                <table class="table table-bordered text-size-11">   
                    <tbody>
                        <tr>
                            <td style='width:25%' class="text-color-negrita gb-color-cell">{{$t('label.benningOperation')}}</td>
                            <td style='width:25%'>{{ DateFormat(Header?.BeginningOfOperations) }}</td>                                                       
                            <td style='width:25%' class="text-color-negrita gb-color-cell">{{$t('label.Agency')}}</td>
                            <td style='width:25%'>{{ Header?.AgencyName }}</td>                                                      
                        </tr>
                        <tr>
                            <td style='width:25%' class="text-color-negrita gb-color-cell">{{$t('label.client')}}</td>                                                       
                            <td style='width:25%'>{{ Header?.ClientName }}</td>
                            <td style='width:25%' class="text-color-negrita gb-color-cell">{{$t('label.operator')}}</td>
                            <td style='width:25%'>{{ Header?.OperatorName }}</td>                                                       
                        </tr>
                    </tbody>
                </table>
            </div>
            <CCol sm="12" lg="9">
                <CRow>
                    <div class="table-responsive col-sm-12 col-lg-6">
                        <table class="table table-bordered text-size-11">   
                            <tbody>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{'TON'+' '+$t('label.PerHours')}}</td>
                                    <td class="text-color-negrita">{{ formatNumber(Embarked.EmbarkedCargoXHour) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.ToLoad')}}</td>
                                    <td>{{ formatNumber(Embarked?.Remaining) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.AveragePerBin')+$t('label.metricTonnageAcronym')}}</td>
                                    <td>{{ formatNumber(Embarked?.AverageTonPerImplement) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.Diurnal')}}</td>
                                    <td>{{ formatNumber(Embarked?.DayTon) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.Nocturnal')}}</td>
                                    <td>{{ formatNumber(Embarked?.NightTon) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">SUBTOTAL</td>
                                    <td>{{ formatNumber(Embarked?.SubTotalTon) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">{{`${$t('label.Accumulated')} (${StartsDate} - ${searchFilterDate.Date})`}}</td>
                                    <td>{{ formatNumber(Embarked?.AccumulatedTon) }}</td>                                                                                                            
                                </tr>
                                <tr>
                                    <td style='width:60%' class="text-color-negrita gb-color-cell">TOTAL</td>
                                    <td>{{ formatNumber(Embarked?.TotalTon) }}</td>                                                                                                            
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CCol class="col-sm-6">
                        <div class="table-responsive">
                            <table class="table table-bordered text-size-11">   
                                <tbody>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">MPH</td>
                                        <td class="text-color-negrita">{{ (Movement?.MovXHour) }}</td>                                                                                                            
                                    </tr>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.movement')}}</td>
                                        <td>{{ (Movement?.Movement) }}</td>                                                                                                            
                                    </tr>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.Diurnal')}}</td>
                                        <td>{{ (Movement?.DayMovs) }}</td>                                                                                                            
                                    </tr>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">{{$t('label.Nocturnal')}}</td>
                                        <td>{{ (Movement?.NightMovs) }}</td>                                                                                                            
                                    </tr>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">{{`${$t('label.Accumulated')} (${StartsDate} - ${searchFilterDate.Date})`}}</td>
                                        <td>{{ (Movement?.AccumulatedMovs) }}</td>                                                                                                            
                                    </tr>
                                    <tr>
                                        <td style='width:60%' class="text-color-negrita gb-color-cell">TOTAL 24 HRS</td>
                                        <td>{{ (Movement?.Total24Hrs) }}</td>                                                                                                            
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </CCol>
                    <CCol sm="12">
                        <div class="table-responsive">
                            <table class="table table-bordered text-size-11">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="text-center gb-color-cell">{{ $t('label.turn') }}</th>
                                    </tr>
                                </thead>   
                                <tbody>
                                    <tr v-if="Turn.length > 0">
                                        <td v-for="(Turns) in Turn" :key="Turns.TurnId">
                                            <table class="table table-borderless mb-0">
                                                <thead>
                                                    <tr>
                                                        <th colspan="4" class="text-center">{{ TurnName(Turns) }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th style='width:25%;' class="text-center">VC</th>
                                                        <th style='width:25%;' class="text-center">HD</th>
                                                        <th style='width:20%' class="text-center">CANT</th>
                                                        <th style='width:30%' class="text-center">TON</th>
                                                    </tr>
                                                </thead>   
                                                <tbody v-if="Turns.CraneJson.length > 0" class="text-center">
                                                    <tr v-for="TurnCranes in Turns.CraneJson" :key="TurnCranes.IdX">
                                                        <td><label>{{TurnCranes.CraneAlias}}</label></td>
                                                        <td><label>{{TurnCranes.VesselHoldName}}</label></td>
                                                        <td class="text-center">{{ TurnCranes.CantImplement }}</td>
                                                        <td class="text-center">{{ formatNumber(TurnCranes.Ton) }}</td>                                                                                                            
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td class="text-color-negrita">TOT</td>
                                                        <td class="text-color-negrita">{{ Turns.TotalMov }}</td>
                                                        <td class="text-color-negrita">{{ formatNumber(Turns.TotalTon) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>                                                                                                           
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </CCol>
                    
                </CRow>
            </CCol>

            <div class="table-responsive col-sm-12 col-lg-3">
                <table class="table table-bordered text-size-11 text-center">
                    <thead>
                            <tr>
                                <th colspan="2" class="gb-color-cell">{{$t('label.LoadedPerTurns')+$t('label.metricTonnageAcronym')}}</th>
                            </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style='width:50%' class="text-color-negrita">{{$t('label.lapse')+' Nº'}}</td>
                            <td style='width:50%' class="text-color-negrita">TON</td>                                                                                                            
                        </tr>
                        <tr>
                            <td colspan="2" class="px-0 pt-0 pb-0">
                                <table class="text-size-11 text-center lapse-table table-striped">  
                                    <tbody>
                                        <tr v-for="Lapses in Lapse" :key="Lapses.IdX">
                                            <td style='width:50%'>{{ Lapses.LapseCode }}</td>
                                            <td style='width:50%'>{{ formatNumber(Lapses.Ton) }}</td>                                                                                                            
                                        </tr>
                                        <tr>
                                            <td style='width:50%' class="text-color-negrita">TOTAL</td>
                                            <td class="text-color-negrita">{{TotalLapses}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>                                                                                                         
                        </tr>
                    </tbody>
                </table>
            </div>
            <CCol sm="12" class="d-flex align-items-center justify-content-center mb-5">
                <TransversalView 
                    :vesselType="TpVesselMasterId"
                    :TpVesselId="itinerarySelected.TpVesselId"
                    :positionJson="Position"
                    :fullscreen="true"
                    legend
                />
            </CCol>
        </CRow>  
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import TransversalView from '@/pages/situacion-puerto/transversal-view-small';
    import { DateFormater } from '@/_helpers/funciones';


    //Data
    function data() {
        return {
            loadingOverlay: false,
            nulo: "null",
            searchFilterDate:{
                Date:"",
            },
            previousDate: new Date(),
            StartsDate: new Date(),
            laterDate: new Date(),
            VisitCraneId: "",
            CraneAlias: '',
            craneOptions: [],
            Header: {},
            Embarked: {},
            Movement: {},
            Turn: [],
            Lapse: [],
            Position: [],
        };
    }

    //methods
    function printPdf() {
        if (this.Header?.BeginningOfOperations) {
            let pageInfo = {
                Visit: this.Visit,
                TpVessel: this.TpVesselMasterId,
                itinerarySelected: this.itinerarySelected,
                Date: this.searchFilterDate.Date,
                StartsDate: this.StartsDate,
                VisitCraneId: this.VisitCraneId,
                CraneAlias: this.CraneAlias,
                Report: 1,
            };

            localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

            let routeData = this.$router.resolve({name: 'pdfSumary'});
            window.open(routeData.href, '_blank');
        } else {
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.TheVisitHasNotStartedOperations'),
                type: "error" 
            });
        } 
    }

    function TurnName(Turns) {
        let _lang = this.$i18n.locale;
        let Name = _lang=='en' ? Turns.TurnNameEn : Turns.TurnNameEs;
        return Name;
    }

    function formatNumber(number) {
        if (number == ""  || number == null) return "0,00";
        let format = Number.parseFloat(number).toFixed(2);
        format = format.replace(".", ",");

        return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
    }

     async function filterDate() {
        this.getBulkCarrierDailyReport();
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.getBulkCarrierDailyReport();
    }

    function DateFormat(Date) {
        if (Date != null && Date != '') {
            Date = DateFormater.formatDateTimeWithSlash(Date);
            return Date;
        } else {
            return Date;
        }
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined){ 
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
            this.StartsDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
        }

        if(this.itinerarySelected.Ata==undefined){
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
            this.StartsDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
        }

        if(this.itinerarySelected.Atd!=undefined){  
            this.searchFilterDate.Date= DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined){
            this.searchFilterDate.Date= DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(currentDate));
            this.laterDate = DateFormater.formatDateRange(currentDate);
        }
    }

    async function getCraneList() {
        this.VisitCraneId = '';
        await this.$http.get("VisitVesselCrane-list", { VisitId: this.Visit } )
        .then(response => {       
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    function getBulkCarrierDailyReport() {
        this.loadingOverlay = true;
        if (this.VisitCraneId=='') {
            this.CraneAlias =  'N/A';
        }else{
            let DataCrane = this.craneOptions.find(item => item.VisitCraneId == this.VisitCraneId);
            this.CraneAlias = DataCrane.CraneAlias;
        }
        let ReportJson = {
            VisitId: this.Visit,
            DateReport: this.searchFilterDate.Date,
            VisitCraneId: this.VisitCraneId,
        };
        this.$http.post("VisitBulkCarrierDailyReport", ReportJson, { root: 'ReportJson' } )
        .then(response => {       
            let list = response.data.data[0];
            if (list) {
                this.Header = list.HeaderJson[0]?list.HeaderJson[0]:{};
                this.Embarked = list.EmbarkedJson[0]?list.EmbarkedJson[0]:{};
                this.Movement = list.MovementJson[0]?list.MovementJson[0]:{};
                this.Turn = list.TurnCraneJson?list.TurnCraneJson:[];
                this.Lapse = list.EmbarkedByLapseJson?list.EmbarkedByLapseJson:[];
                this.Position = list.PositionJson?list.PositionJson:[];
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            this.loadingOverlay = false;
        });
    }

//computed
    function TotalLapses() {
        let TLapses = 0;
        for (let index = 0; index < this.Lapse.length; index++) {
            TLapses += this.Lapse[index].Ton;
        }
        return formatNumber(TLapses);
    } 

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }
    
    export default {
        name: "sumaries-daily-report",
        data,
        props: ['value'],
        mixins: [ReportesVisitas],
        components: {
            TransversalView,
        },
        methods:{
            printPdf,
            TurnName,
            formatNumber,
            filterDate,
            filterCrane,
            DateFormat,
            validateDateRange,
            getDateRange,
            getCraneList,
            getBulkCarrierDailyReport,
        },
        computed:{
            TotalLapses,
            craneListFormatted,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,
                TpVesselMasterId: state => state.visitas.TpVesselMasterId,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },

        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==60 && this.Visit != '') {
                    this.loadingOverlay = true;
                    await this.getDateRange();
                    await this.getCraneList();
                    await this.getBulkCarrierDailyReport();
                }
            },
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss">
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>