<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" /> 
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.vgmDetail') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <!--CButton
                    color="danger" 
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnPdf()"
                >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton-->
                <CButton
                    color="excel" 
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :value.sync="preference"
                    v-model.trim="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="filterPreference"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    :disabled = "disabledFilterByPreference"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-auto center-field" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getVgmDetailList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
      
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"            
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],

            preference:1,
            preferenceOptions: [],
            VisitCraneId: '',
            craneOptions: [],
            craneName: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            disabledFilterByPreference: false,
            nulo:'',
            search:'',
            labelUnitMeasure: 'TON',
            pageSize:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;        
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        
        if (params.node.data.section=="total") {
            return 'bg-secondary font-weight-bold'
        }    
        if (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES") {
            return 'bg-confirmed'
        }
    }



    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;     
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
        }).catch( err => {  
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let listado = this.formatedItems;
            let computedItems = [];
            for (let i=0; i < listado.length; i++) {
                if (listado[i].Weigth!==undefined) {
                    computedItems.push({
                        Nro: listado[i].Nro,
                        ContainerCode: listado[i].ContainerCode ? listado[i].ContainerCode : '',
                        LoadPort: listado[i].LoadPort ? listado[i].LoadPort : '',
                        DischargePort: listado[i].DischargePort ? listado[i].DischargePort  : '',
                        ShippingLineCode: listado[i].ShippingLineCode ? listado[i].ShippingLineCode : '',
                        ShippingLineName: listado[i].ShippingLineName ? listado[i].ShippingLineName : '',
                        Status: listado[i].Status ? listado[i].Status : '',
                        TpCargoDetailCode: listado[i].TpCargoDetailCode ? listado[i].TpCargoDetailCode : '',
                        ImdgCode: listado[i].ImdgCode ? listado[i].ImdgCode : '',
                        Seals: listado[i].Seals ? listado[i].Seals : '', 
                        IsoCode: listado[i].IsoCode ? listado[i].IsoCode : '', 
                        DeliveryPort: listado[i].DeliveryPort ? listado[i].DeliveryPort : '', 
                        Weigth: listado[i].Weigth ? listado[i].Weigth : 0,
                        CtaFunctionCode: listado[i].CtaFunctionCode ? listado[i].CtaFunctionCode : '',
                        CtaIdentifier: listado[i].CtaIdentifier ? listado[i].CtaIdentifier : '',
                        CtaName: listado[i].CtaName ? listado[i].CtaName : '',
                        ComAddressIdentifier: listado[i].ComAddressIdentifier ? listado[i].ComAddressIdentifier : '',
                        ComMeansTypeCode: listado[i].ComMeansTypeCode ? listado[i].ComMeansTypeCode : '',
                        HanDescriptionCode: listado[i].HanDescriptionCode ? listado[i].HanDescriptionCode : '',
                        HanIdentificationCode: listado[i].HanIdentificationCode ? listado[i].HanIdentificationCode : '',
                        HanResponsibleAgencyCode: listado[i].HanResponsibleAgencyCode ? listado[i].HanResponsibleAgencyCode : '',
                        HanInstructionDescription: listado[i].HanInstructionDescription ? listado[i].HanInstructionDescription : '',
                        TsrServicePriorityCode: listado[i].TsrServicePriorityCode ? listado[i].TsrServicePriorityCode : '',
                        TsrIdentificationCode: listado[i].TsrIdentificationCode ? listado[i].TsrIdentificationCode : '',
                        TsrResponsibleAgencyCode: listado[i].TsrResponsibleAgencyCode ? listado[i].TsrResponsibleAgencyCode : '',
                        ConfirmationDate: listado[i].ConfirmationDate ? listado[i].ConfirmationDate : '',
                        FgConfirmed: listado[i].FgConfirmed,
                        CraneName: listado[i].CraneName ? listado[i].CraneName : '',
                        Procedence:  listado[i]?.Procedence,
                        YardNamePlanning:  listado[i]?.YardNamePlanning, 
                        FgDirect:  listado[i]?.FgDirect
                    })
                }
            }
            //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
            await this.getPdf(computedItems,this.$t('label.vgmDetail'));
            
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            if (this.craneName == null || this.craneName =='') this.craneName=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.vgmDetail'),valor, this.craneName, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
    }

    async function getVgmDetailList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);      

        let VgmSumaryJson = {
            VisitId: this.Visit,
            UnitMeasureId: this.unitMeasureId,
            CompanyBranchId: this.branch.CompanyBranchId,
            DateStart:dateStart,
            DateEnd:dateEnd,
            UserPreference: this.preference,
        };

        await this.$http.post("VisitVgmDetail-list", VgmSumaryJson, { root: 'VgmSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            if (this.nulo=="null") return false;
            this.formatedItems.push({
                section: 'total',
                IdX: '',
                CtaFunctionCode: 'CONTACT INFORMATION',
                ComAddressIdentifier: 'COMMUNICATION CONTACT',
                HanDescriptionCode: 'HANDLING INSTRUCTIONS',
                TsrServicePriorityCode: 'TRANSPORT SERVICE REQUIREMENTS',
            });
            let _this = this;
            for (let i=0; i < listado.length; i++) {
                if (listado[i].Weigth!==undefined) {
                    this.CraneName = listado[0].CraneName;
                    this.formatedItems.push({
                        IdX: listado[i].Nro,
                        ContainerCode: listado[i].ContainerCode ? listado[i].ContainerCode : '',
                        LoadPort: listado[i].LoadPort ? listado[i].LoadPort : '',
                        DischargePort: listado[i].DischargePort ? listado[i].DischargePort  : '',
                        ShippingLineCode: listado[i].ShippingLineCode ? listado[i].ShippingLineCode : '',
                        ShippingLineName: listado[i].ShippingLineName ? listado[i].ShippingLineName : '',
                        Status: listado[i].Status ? listado[i].Status : '',
                        TpCargoDetailCode: listado[i].TpCargoDetailCode ? listado[i].TpCargoDetailCode : '',
                        ImdgCode: listado[i].ImdgCode ? listado[i].ImdgCode : '',
                        Seals: listado[i].Seals ? listado[i].Seals : '', 
                        IsoCode: listado[i].IsoCode ? listado[i].IsoCode : '', 
                        DeliveryPort: listado[i].DeliveryPort ? listado[i].DeliveryPort : '', 
                        Weigth: listado[i].Weigth ? listado[i].Weigth : 0,
                        //FgVgm: listado[i].FgVgm==1 ? true : false,
                        CtaFunctionCode: listado[i].CtaFunctionCode ? listado[i].CtaFunctionCode : '',
                        CtaIdentifier: listado[i].CtaIdentifier ? listado[i].CtaIdentifier : '',
                        CtaName: listado[i].CtaName ? listado[i].CtaName : '',
                        ComAddressIdentifier: listado[i].ComAddressIdentifier ? listado[i].ComAddressIdentifier : '',
                        ComMeansTypeCode: listado[i].ComMeansTypeCode ? listado[i].ComMeansTypeCode : '',
                        HanDescriptionCode: listado[i].HanDescriptionCode ? listado[i].HanDescriptionCode : '',
                        HanIdentificationCode: listado[i].HanIdentificationCode ? listado[i].HanIdentificationCode : '',
                        HanResponsibleAgencyCode: listado[i].HanResponsibleAgencyCode ? listado[i].HanResponsibleAgencyCode : '',
                        HanInstructionDescription: listado[i].HanInstructionDescription ? listado[i].HanInstructionDescription : '',
                        TsrServicePriorityCode: listado[i].TsrServicePriorityCode ? listado[i].TsrServicePriorityCode : '',
                        TsrIdentificationCode: listado[i].TsrIdentificationCode ? listado[i].TsrIdentificationCode : '',
                        TsrResponsibleAgencyCode: listado[i].TsrResponsibleAgencyCode ? listado[i].TsrResponsibleAgencyCode : '',
                        ConfirmationDate: listado[i].ConfirmationDate ? DateFormater.formatDateTimeWithSlash(listado[i].ConfirmationDate) : '',
                        FgConfirmed: listado[i].FgConfirmed === true ? _this.$t('label.yes') : 'NO',/**/
                        CraneName: listado[i].CraneAlias ? listado[i].CraneAlias : '',
                        Procedence:  listado[i]?.Procedence,
                        YardNamePlanning:  listado[i]?.YardNamePlanning, 
                        FgDirect:  listado[i]?.FgDirect? 'YES' : 'NO'
                    })
                }
            }

            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
            
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }


    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.pageSize='';
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.craneName= '';
        this.unitMeasureId= '';
        this.VisitCraneId= '';
        this.preference= 1;
        this.disabledFilterByPreference= false;
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getDateRange();
        await this.getPreferenceList();
        await this.getCraneList();
        await this.getUnitMeasureWeigthList();
        await this.getVgmDetailList();
    }

    function filterPreference(e) {
        this.preference=e.target.value;
        if (this.preference == 3)
            this.disabledFilterByPreference= true;
        else
            this.disabledFilterByPreference= false;
    } 

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function isHeader(params) {
        return params.data.section === 'total';
    }

    function checkboxSelection(params) {
        return params.node.data.FgVgm;
    }

    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
        {
            field: "IdX",
            headerName: "#",
            //lockVisible: true,
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 70,
            //pinned: 'left',
            cellClass: 'text-center gb-cell',
            //checkboxSelection: true, 
            filter: 'agTextColumnFilter',
            colSpan: (params) => {
                if (isHeader(params)) {
                    return 14;
                } else {
                    return 1;
                }
            },
        },
        {
            field: "ContainerCode",
            headerName: "CONTAINER",
            //lockVisible: true,
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 150,
            //pinned: 'left',
            cellClass: 'text-center gb-cell',
            //checkboxSelection: true, 
        },
        {
            field: "TpCargoDetailCode",
            headerName: "SIZE (FT)",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "IsoCode",
            headerName: "ISO CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Seals",
            headerName: "SEALS",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Status",
            headerName: "STATUS",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ShippingLineCode",
            headerName: "CARRIER CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ShippingLineName",
            headerName: "CARRIER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ImdgCode",
            headerName: "IMDG CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "LoadPort",
            headerName: "LOAD PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "DischargePort",
            headerName: "DISCHARGE PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "DeliveryPort",
            headerName: "DELIVERY PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Weigth",
            headerName: "GROSS \nWEIGHT (ton)",
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 190,
            cellClass: 'center-cell-especial',
            filter: "agNumberColumnFilter",
            cellRenderer: params => {
                let variable = 0.00;   
                if(params.value !== null && params.value !== ''){ 
                    let newValue =  Number.parseFloat(params.value).toFixed(2);
                    variable = formatMilDecimal(newValue);
                }
                return variable; 
            },
        },
        {
            field: "CraneName",
            headerName: "CRANE",
            //lockVisible: true,
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 100,
            cellClass: 'text-center',
        },
        /*{
            //field: "FgVgm",
            headerName: "VGM",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 120,
            cellClass: 'text-center',
            //checkboxSelection: checkboxSelection
            cellRenderer: params => {
                if (params.data.FgVgm==undefined) {
                    return "";
                } else {
                    return `<input type='checkbox' ${params.data.FgVgm ? 'checked' : ''} disabled />`;
                }
            }
        },*/
        {
            field: "CtaFunctionCode",
            headerName: "FUNCTION \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            colSpan: (params) => {
                if (isHeader(params)) {
                    return 3;
                } else {
                    return 1;
                }
            },
            cellClass: (params) => {
                if (isHeader(params)) {
                    return 'center-cell-especial bg-watch';
                } else {
                    return 'center-cell-especial';
                }
            },
        },
        {
            field: "CtaIdentifier",
            headerName: "IDENTIFIER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            cellClass: 'center-cell-especial',
        },
        {
            field: "CtaName",
            headerName: "NAME",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 160,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ComAddressIdentifier",
            headerName: "ADDRESS \nIDENTIFIER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 200,
            colSpan: (params) => {
                if (isHeader(params)) {
                    return 2;
                } else {
                    return 1;
                }
            },
            cellClass: (params) => {
                if (isHeader(params)) {
                    return 'center-cell-especial bg-watch';
                } else {
                    return 'center-cell-especial';
                }
            },
        },
        {
            field: "ComMeansTypeCode",
            headerName: "MEANS \nTYPE \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 200,
            cellClass: 'center-cell-especial',
        },
        {
            field: "HanDescriptionCode",
            headerName: "INSTRUCTION \nDESCRIPTION \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 270,
            colSpan: (params) => {
                if (isHeader(params)) {
                    return 4;
                } else {
                    return 1;
                }
            },
            cellClass: (params) => {
                if (isHeader(params)) {
                    return 'center-cell-especial bg-plan';
                } else {
                    return 'center-cell-especial';
                }
            },
        },
        {
            field: "HanIdentificationCode",
            headerName: "CODE LIST \nIDENTIFICATION \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 270,
            cellClass: 'center-cell-especial',
        },
        {
            field: "HanResponsibleAgencyCode",
            headerName: "CODE LIST \nRESPONSIBLE \nAGENCY CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            // sortable: true,
            lockPosition: true,
            minWidth: 300,
            cellClass: 'center-cell-especial',
        },
        {
            field: "HanInstructionDescription",
            headerName: "INSTRUCTION \nDESCRIPCION",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            sortable: true,
            lockPosition: true,
            minWidth: 250,
            cellClass: 'center-cell-especial',
        },
        {
            field: "TsrServicePriorityCode",
            headerName: "SERVICE \nPRIORITY \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            //sortable: true,
            lockPosition: true,
            minWidth: 250,
            colSpan: (params) => {
                if (isHeader(params)) {
                    return 3;
                } else {
                    return 1;
                }
            },
            cellClass: (params) => {
                if (isHeader(params)) {
                    return 'center-cell-especial bg-edit';
                } else {
                    return 'center-cell-especial';
                }
            },
        },
        {
            field: "TsrIdentificationCode",
            headerName: "CODE LIST \nIDENTIFICATION \nCODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            sortable: true,
            lockPosition: true,
            minWidth: 280,
            cellClass: 'center-cell-especial',
        },
        {
            field: "TsrResponsibleAgencyCode",
            headerName: "CODE LIST \nRESPONSIBLE \nAGENCY CODE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 300,
            cellClass: 'center-cell-especial',
        },
        {
            field: "FgConfirmed",
            headerName: "CONFIRMED",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 190,
            cellClass: 'center-cell-especial',
        },
        {
            field: "ConfirmationDate",
            headerName: "CONFIRMATION",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            lockPosition: true,
            suppressMovable: true,
            minWidth: 190,
            cellClass: 'center-cell-especial',
        },
        {
            field: "YardNamePlanning",
            headerName: "YARD",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 300,
            cellClass: 'center-cell-especial',
        },
        {
            field: "Procedence",
            headerName: "PROCEDENCE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 300,
            cellClass: 'center-cell-especial',
        },
        {
            field: "FgDirect",
            headerName: "DIRECT DISCHARGE",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            minWidth: 300,
            cellClass: 'center-cell-especial',
        },
        ]

      
        return columnDefs;
    }

    export default {
        name: "vgm-detail",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnExport,
            getVgmDetailList,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            onBtnPdf,
            getCraneList,
            filterCrane,
            validateDateRange, 
            refreshComponent,
            filterPreference,
            getPreferenceList,
            getDateRange,
        },
        computed:{
            isHeader,
            checkboxSelection,
            preferenceListFormatted,
            craneListFormatted,
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,       
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
            }),
        },
        components:{
            
        },
        watch:{            
            dropItemReport: async function (newValue) {
                if (newValue==30) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>