<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.ordersByScrapContainer') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="4" xl="4" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4 text-right', input: 'col-sm-11 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                />
            </CCol>
            <CCol sm="12" lg="4" class="center-field px-0">
                 <CSelect
                    :horizontal="{ label: 'col-sm-11 col-lg-3 col-xl-3 text-right', input: 'col-sm-11 col-lg-9 col-xl-9'}"
                    :label="$t('label.hold')"
                    :options="HoldOptions"
                    v-model.trim="HoldId"
                    :value.sync="HoldId"
                />
            </CCol>
            <CCol sm="12" lg="4" class="center-field">
                <CRow>
                    <CCol sm="12" lg="3" class="text-right pr-1 ">
                        <label class="col-form-label text-right"> {{ $t('label.Gamela') }} </label>
                    </CCol>
                    <CCol sm="12" lg="9" style="padding-left: 0.8rem !important;">
                        <v-select 
                            :placeholder="$t('label.select')"
                            :options="scrapContainerFormatted"
                            label="label"
                            v-model="scrapContainer"
                            :value.sync="scrapContainer"
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
           <CCol sm="12" lg="4" xl="4" class="center-field">
                <CSelect
                    :value.sync="Transportation"
                    v-model.trim="Transportation"
                    :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4 text-right', input: 'col-sm-11 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.transport')"
                    :options="transportationListFormatted"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2 px-1">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getOrderList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-11 col-lg-3 col-xl-3 text-right', input: 'col-sm-11 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-11 col-lg-8 col-xl-8 text-right', input: 'col-sm-11 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowStyle="getRowStyle"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';


    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,

            HoldId: '',
            HoldName: '',
            HoldOptions: [],

            scrapContainer: '',
            scrapContainerName: '',
            scrapContainerOptions: [],

            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
            },
            Transportation: '',
            TransportationName: '',
            TransportationOptions: [],
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            nulo:'', 
            pageSize:'',
            search: '',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass() {
       return 'bg-gradient';
    }

    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }


    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Nro: rowData[i].Nro,
                OrderCode: rowData[i].OrderCode,
                ClientName: rowData[i].ClientName,
                CarrierAlias: rowData[i].CarrierAlias,
                LicensePlate: rowData[i].LicensePlate,
                ImplementAlias: rowData[i].ImplementAlias,
                DriverName: rowData[i].DriverName,
                DriverCi: rowData[i].DriverCi,
                NroBl: rowData[i].NroBl,
                YardName: rowData[i].YardName,
                YardClientName: rowData[i].YardClientName,
                NetWeight: rowData[i].NetWeightOrigin,
                NetWeightTon: rowData[i].NetWeightTonOrigin,
                BlClientName: rowData[i].BlClientName,
                CraneAlias: rowData[i].CraneAlias,
                VesselHoldName: rowData[i].VesselHoldName,
                TransactionDate: rowData[i].TransactionDate,
                FinishDate: rowData[i].FinishDate,
                TotalTime: rowData[i].TotalTime,
                Observation: rowData[i].Observation,
                DeviceCode: rowData[i].DeviceCode,
                TransaLogin: rowData[i].TransaLogin,
                OrderStatusDs: rowData[i].OrderStatusDs,
                ColorStatus: rowData[i].ColorStatus,
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.ordersByScrapContainer'), '', {from:24,to:27});
            }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        let currentDate = new Date();
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        let valores = []; 
        valores[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
        valores[1] = this.$t('label.transport')+': '+this.TransportationName;
        valores[2] = this.$t('label.hold')+': '+this.HoldName;
        valores[3] = this.$t('label.Gamela')+': '+this.scrapContainerName;
        valores[4] = this.$t('label.crane')+': '+this.CraneAlias;
        valores[5] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[6] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Nro: rowData[i].Nro,
                OrderCode: rowData[i].OrderCode,
                CarrierAlias: rowData[i].CarrierAlias,
                ClientName: rowData[i].ClientName,
                LicensePlate: rowData[i].LicensePlate,
                ImplementAlias: rowData[i].ImplementAlias,
                DriverName: rowData[i].DriverName,
                DriverCi: rowData[i].DriverCi,
                NroBl: rowData[i].NroBl,
                YardName: rowData[i].YardName,
                YardClientName: rowData[i].YardClientName,
                NetWeight: rowData[i].NetWeight,
                NetWeightTon: rowData[i].NetWeightTon,
                BlClientName: rowData[i].BlClientName,
                CraneAlias: rowData[i].CraneAlias,
                VesselHoldName: rowData[i].VesselHoldName,
                TransactionDate: rowData[i].TransactionDate,
                FinishDate: rowData[i].FinishDate,
                TotalTime: rowData[i].TotalTime,
                Observation: rowData[i].Observation,
                DeviceCode: rowData[i].DeviceCode,
                TransaLogin: rowData[i].TransaLogin,
                OrderStatusDs: rowData[i].OrderStatusDs,
                AnulationDate: rowData[i].AnulationDate,
                AnulationDeviceCode: rowData[i].AnulationDeviceCode,
                AnulationLogin: rowData[i].AnulationLogin,
                ObservationAnulation: rowData[i].ObservationAnulation,
                ColorStatus: rowData[i].ColorStatus,
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.ordersByScrapContainer'),valor, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    async function getOrderList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);       

        let ShipmentOrderJson = [{
            VisitId: this.Visit,
            StowagePlanningImplementId: (this.scrapContainer !== '') ? this.scrapContainer.value : '',
            StowagePlanningResourceId: this.Transportation,
            VisitCraneId :this.VisitCraneId,
            VesselHoldId: this.HoldId,
            DateStart: dateStart,
            DateEnd: dateEnd,
        }];

        await this.$http.post("ShipmentOrderList-by-Implement", ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then(response => {
            listado = [...response.data.data];
            let _lang = this.$i18n.locale;
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado ==''){
                return false;
            } 
            if(this.VisitCraneId === '' || this.VisitCraneId === null){
                this.CraneAlias='N/A';  
            }else{
                this.CraneAlias = listado[0].CraneAlias;
            }
            if(this.Transportation === '' || this.Transportation === null){
                this.TransportationName = 'N/A';
            }else{
                this.TransportationName = listado[0].ClientName;
            }
            if(this.HoldId === '' || this.HoldId === null){
                this.HoldName = 'N/A';
            }else{
                this.HoldName = listado[0].VesselHoldName;
            }
            if(this.scrapContainer === '' || this.scrapContainer === null){
                this.scrapContainerName = 'N/A';
            }else{
                this.scrapContainerName = listado[0].ImplementAlias;
            }
            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                Nro: listado.Nro ? listado.Nro : '',
                OrderCode: listado.OrderCode ? listado.OrderCode : '',
                ClientName: listado.ClientName ? listado.ClientName : '',
                CarrierAlias: listado.CarrierAlias ? listado.CarrierAlias : '',
                LicensePlate: listado.LicensePlate ? listado.LicensePlate : '',
                ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                DriverName: listado.DriverName ? listado.DriverName : '',
                DriverCi: listado.DriverCi ? listado.DriverCi : '',
                NroBl: listado.NroBl ? listado.NroBl : '',
                YardName: listado.YardName ? listado.YardName : '',
                YardClientName: listado.YardClientName ? listado.YardClientName : '',
                NetWeight: listado.NetWeight ? listado.NetWeight  : 0,
                NetWeightOrigin: listado.NetWeight ? formatMilDecimal(parseFloat(listado.NetWeight).toFixed(2)) : '0,00',
                NetWeightTon: listado.NetWeightTon ? listado.NetWeightTon  : 0,
                NetWeightTonOrigin: listado.NetWeightTon ? formatMilDecimal(parseFloat(listado.NetWeightTon).toFixed(2)) : '0,00',
                BlClientName: listado.BlClientName ? listado.BlClientName : '',
                CraneAlias: listado.CraneAlias ? listado.CraneAlias : '',
                VesselHoldName: listado.VesselHoldName ? listado.VesselHoldName : '',
                TransactionDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : '',
                FinishDate: listado.FinishDate ? DateFormater.formatDateTimeWithSlash(listado.FinishDate) : '',
                TotalTime: listado.TotalTime ? listado.TotalTime : '',
                Observation: listado.Observation ? listado.Observation : '',
                DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
                TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
                OrderStatusDsEn: listado.OrderStatusDsEn ? listado.OrderStatusDsEn : '',
                OrderStatusDsEs: listado.OrderStatusDsEs ? listado.OrderStatusDsEs : '',
                ColorStatus: listado.ColorStatus ? listado.ColorStatus : '',
                AnulationDate: listado.AnulationDate ? DateFormater.formatDateTimeWithSlash(listado.AnulationDate) : '',
                AnulationDeviceCode: listado.AnulationDeviceCode ? listado.AnulationDeviceCode : '',
                AnulationLogin: listado.AnulationLogin ? listado.AnulationLogin : '',
                ObservationAnulation: listado.ObservationAnulation ? listado.ObservationAnulation : '',
            }));

           
            
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getscrapContainer() {
        await this.$http.get("VisitImplement-list?VisitId="+this.Visit)
        .then(response => {       
            this.scrapContainerOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {       
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getTransportationList() {
        await this.$http.get("VisitCarrier-list", { VisitId: this.Visit })
        .then(response => {
            this.TransportationOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getHoldList (Crane) {
        if(Crane == true){
            this.loadingOverlay = true;
        }
        this.HoldOptions = [];
        let Lista = Array;
        let metodo = "VisitVesselHold-list";
        if (this.VisitCraneId !== '') {
            metodo = "VisitVesselCraneHold-list";
        }
        await this.$http.get( metodo, { VisitId: this.Visit, VisitCraneId: this.VisitCraneId })
        .then(response => {
            Lista = [...response.data.data];
            if (Lista.length > 0) {
                for (let i = 0; i < Lista.length; i++) {
                    if (i==0) {
                        this.HoldOptions.push({
                            value: "",
                            label: this.$t('label.select'),
                        })
                    }
                    this.HoldOptions.push({
                        value: Lista[i].VesselHoldId,
                        label: Lista[i].VesselHoldName,
                    })
                }
            }else{
                this.HoldOptions.push({
                    value: "",
                    label: this.$t('label.select'),
                })
            }
            if(Crane == true){
                this.loadingOverlay = false;
            }
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            if(Crane == true){
                this.loadingOverlay = false;
            }
        });
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.HoldId = '';
        this.getHoldList(true);
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.HoldId= '';
        this.HoldName='';
        this.VisitCraneId= '';
        this.CraneAlias= '';
        this.Transportation= '';
        this.TransportationName= '',
        this.scrapContainer='';
        this.scrapContainerName='';
        this.pageSize="";
        this.getDateRange();
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getTransportationList();
        await this.getCraneList();
        await this.getHoldList();
        await this.getscrapContainer();
        await this.getOrderList();
    } 

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
//computed
    function RowData(){
        if(this.formatedItems.length != 0){
            let _lang = this.$i18n.locale;
            return this.formatedItems.map((item) => {
                return {
                    ...item,
                    OrderStatusDs: _lang=='en'? item.OrderStatusDsEn : item.OrderStatusDsEs,
                };
            });
        }else{
            return [];
        }
    }

    function transportationListFormatted(){
        if(this.TransportationOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.TransportationOptions.map(function(e){
                    chart.push({
                        value: e.StowagePlanningResourceId, 
                        label: e.ClientName,
                    })
            })
            return chart;
        }
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    function scrapContainerFormatted(){
        if(this.scrapContainerOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.scrapContainerOptions.map(function(e){
                    chart.push({
                        value: e.StowagePlanningImplementId, 
                        label: e.ImplementAlias,
                    })
                
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderCode",
                    headerName: this.$t('label.order'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell text-color-negrita',
                    //checkboxSelection: true, 
                },
                {
                    field: "ClientName",
                    headerName: this.$t('label.transport'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "CarrierAlias",
                    headerName: this.$t('label.transport')+' - '+this.$t('label.alias'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 190,
                    minWidth: 190,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "LicensePlate",
                    headerName: this.$t('label.vehicle'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "ImplementAlias",
                    headerName: this.$t('label.Gamela'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'gb-cell',
                    //pinned: 'left'
                },
                {
                    field: "DriverName",
                    headerName: this.$t('label.driver'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DriverCi",
                    headerName: this.$t('label.IdNumber'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "NroBl",
                    headerName: "BL",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',

                },
                {
                    field: "BlClientName",
                    headerName: this.$t('label.client'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "YardName",
                    headerName: this.$t('label.yard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "YardClientName",
                    headerName: this.$t('label.clientYard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "NetWeight",
                    headerName: this.$t('label.weight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "NetWeightTon",
                    headerName: this.$t('label.weight')+' '+this.$t('label.metricTonnageAcronym'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        return variable;
                    },
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "CraneAlias",
                    headerName: this.$t('label.crane'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: this.$t('label.hold'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "TransactionDate",
                    headerName: this.$t('label.inicio'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "FinishDate",
                    headerName: this.$t('label.fin'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TotalTime",
                    headerName: this.$t('label.time'),
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',

                },
                {
                    field: "Observation",
                    headerName: this.$t('label.observation'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DeviceCode",
                    headerName: this.$t('label.device'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "TransaLogin",
                    headerName: this.$t('label.user'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "OrderStatusDs",
                    headerName: this.$t('label.status'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial text-color-negrita',
                },
                {
                    field: "AnulationDate",
                    headerName: this.$t('label.AnulationDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationDeviceCode",
                    headerName: this.$t('label.AnulationDeviceCode'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 220,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "AnulationLogin",
                    headerName: this.$t('label.AnulationLogin'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ObservationAnulation",
                    headerName: this.$t('label.ObservationAnulation'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 230,
                    cellClass: 'center-cell-especial',
                },
            ]
            return columnDefs;
    }
    export default {
        name: "orders-by-scrap-container",
        data,
        beforeMount,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            getscrapContainer,
            getHoldList,
            onGridReady,
            onBtnExport,
            getOrderList,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowStyle,
            getRowClass,
            onBtnPdf,
            getCraneList,
            filterCrane,
            validateDateRange,
            refreshComponent,
            getTransportationList,
            getDateRange,
        },
        computed:{
            RowData,
            transportationListFormatted,
            craneListFormatted,
            scrapContainerFormatted,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==55 && this.Visit != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss">

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>