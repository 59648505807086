import {
    required,
  } from 'vuelidate/lib/validators';
  
  export default () => {
    return {
      binWeighing: { 
        searchFilterDate: {
          fromDay: { required },
          toDay: { required },
        },
        typeReport: { required },
      }
    }
  }