<template>
    <div class="container-fluid ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.listOfMovementsRestow')}}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end ">
                <CButton
                    color="danger" 
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton>
                <CButton
                    color="excel"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field ">
                <CSelect
                    label="U.M"
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :options="unitMeasureOptions"
                    v-model="unitMeasureId"
                    :value.sync="unitMeasureId"
                    
                    required 
                >
                </CSelect>
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>


            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getRestowList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required
                    add-label-classes="text-right" 
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field  ">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
                
            </CCol>
        </CRow>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    //Data
    function data() {
        return {
            loadingOverlay: false,
            dataBuque:{
                CountryName:'',
                DatePlanning:'',
                VesselFlagRoute:'',
            },
            textSearch:'',
            gridOptions: null,
            rows: [],
            SizeChanged:50,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            dataWeight:[],
            unitMeasureId:null,
            nulo: '', 
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            unitMeasureOptions: [],
            labelUnitMeasure: 'TON',

        }
    }

    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        //console.log(this.gridApi);
    }
    function filterUnitMeasureWeight(event){

        if(event.target.value !== '' && event.target.value !== null){
            this.loadingOverlay = true;

            let CargoSumaryJson = {
                UnitMeasureId: event.target.value,
                EdiFileId: this.EdiFileId,
                VisitId: (this.Visit === undefined || this.Visit === null) ? '': this.Visit 
            }

            this.$http.ejecutar('POST', 'VisitCargoRestow-list', CargoSumaryJson, { root: 'CargoSumaryJson' })
            .then(response => {
                this.rows = response.data.data;
                this.loadingOverlay = false;
            }).catch( err => {
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error" 
                });
                this.loadingOverlay = false;
            });
        }
    }
    async function onBtnExport(valor){
        this.loadingOverlay = true;

        let rowData = [];
        if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            await this.getExcel(rowData,this.$t('label.listOfMovementsRestow'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }
    async function onBtnPdf() {

        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];
        if (this.FgGeneralCargo) {
            rowData.forEach(item =>{
                rowDataFormated.push({
                    id: item.id,
                    ArrivalPosition: item.ArrivalPosition,
                    DepartPosition: item.DepartPosition,
                    ContainerCode: item.ContainerCode,
                    TpCargoDetailCode: item.TpCargoDetailCode,
                    VesselUbicationName: item.VesselUbicationName ?? '',
                    VesselHoldName: item.VesselHoldName ?? '',
                    HatchCoverName: item.HatchCoverName ?? '',
                    IsoCode: item.IsoCode,
                    Seals: item.Seals,
                    Status: item.Status,
                    ShippingLineCode: item.ShippingLineCode,
                    ShippingLineName: item.ShippingLineName,
                    LoadPort: item.LoadPort,
                    DischargePort: item.DischargePort,
                    DeliveryPort: item.DeliveryPort,
                    TranshipmentPort: item.TranshipmentPort,
                    CraneAlias: item.CraneAlias,
                    weigth: formatMilDecimal(parseFloat(item.weigth).toFixed(2)),
                    UnNumber: item.UnNumber,
                    restowType: item.restowType,
                    reason: item.reason,
                    observation_reason: item.observation_reason,
                    ConfirmationDate: item.ConfirmationDate,
                    DeviceCode: item.DeviceCode,
                    TransaLogin: item.TransaLogin,
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect 
                });   
            })
        } else {
            for (let i = 0; i < rowData.length; i++) {
                rowDataFormated.push({
                    id: rowData[i].id,
                    ArrivalPosition: rowData[i].ArrivalPosition,
                    DepartPosition: rowData[i].DepartPosition,
                    ContainerCode: rowData[i].ContainerCode,
                    TpCargoDetailCode: rowData[i].TpCargoDetailCode,
                    IsoCode: rowData[i].IsoCode,
                    Seals: rowData[i].Seals,
                    Status: rowData[i].Status,
                    ShippingLineCode: rowData[i].ShippingLineCode,
                    ShippingLineName: rowData[i].ShippingLineName,
                    LoadPort: rowData[i].LoadPort,
                    DischargePort: rowData[i].DischargePort,
                    DeliveryPort: rowData[i].DeliveryPort,
                    TranshipmentPort: rowData[i].TranshipmentPort,
                    CraneAlias: rowData[i].CraneAlias,
                    weigth: formatMilDecimal(parseFloat(rowData[i].weigth).toFixed(2)),
                    UnNumber: rowData[i].UnNumber,
                    restowType: rowData[i].restowType,
                    reason: rowData[i].reason,
                    observation_reason: rowData[i].observation_reason,
                    ConfirmationDate: rowData[i].ConfirmationDate,
                    DeviceCode: rowData[i].DeviceCode,
                    TransaLogin: rowData[i].TransaLogin,
                    Procedence: rowData[i].Procedence,
                    YardNamePlanning: rowData[i].YardNamePlanning, 
                    FgDirect: rowData[i].FgDirect 
                });   
            }
        }

        try{
            if(this.nulo!="null" && this.formatedItems.length !== 0){
                this.loadingOverlay = true;
                await this.getPdf(rowDataFormated,this.$t('label.listOfMovementsRestow'));
                this.loadingOverlay = false;
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }
        } catch (error) {
            this.loadingOverlay = false;
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error" 
            });
        }
    }
    function getRestowList() {
        this.loadingOverlay = true;

        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);  

        let CargoSumaryJson = {
            UnitMeasureId: this.unitMeasureId,
            VisitId: (this.Visit === undefined || this.Visit === null) ? '': this.Visit,
            CompanyBranchId:this.branch.CompanyBranchId,
            VisitCraneId:this.VisitCraneId,
            DateStart:dateStart,
            DateEnd:dateEnd,
            //UserPreference:this.confirmados
        }

        this.$http.ejecutar('POST', 'VisitCargoRestow-list', CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            this.rows = response.data.data;
            this.nulo = String(response.data.data[0].Json);
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

            this.loadingOverlay = false;
        }).catch( err => {
            this.loadingOverlay = false;
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error" 
            });
        });
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.rows = [];
        this.gridApi.refreshCells();
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.unitMeasureId = '';
        this.VisitCraneId= '';
        this.SizeChanged = 50;
        this.gridApi.paginationSetPageSize(parseInt(this.SizeChanged));
        await this.getDateRange();
        await this.getUnitMeasureWeigthList();
        await this.getCraneList();
        await this.getRestowList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }
    
    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                lockPosition: true,
                //lockVisible: true,
                suppressMovable: true,
                //pinned: 'left',
                minWidth: 70,
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                //checkboxSelection: true, 
            },
            {
                field: "ArrivalPosition",
                headerName: "ARRIVAL SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 150,
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "DepartPosition",
                headerName: "DEPARTURE SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 180,
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "ContainerCode",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                //pinned: 'left',
                suppressMovable: true,
                lockPosition: true,
                resizable: true,
                cellClass: 'gb-cell',
                minWidth: 150,
            },
        ]
        if (this.FgGeneralCargo) {
                columnDefs.push({
                    field: "VesselUbicationName",
                    headerName: "LOCATION",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: "HOLD",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "HatchCoverName",
                    headerName: "HATCH COVER",
                    filter: "agTextColumnFilter",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                })
            }
            columnDefs.push({
                field: "TpCargoDetailCode",
                headerName: "SIZE (FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "IsoCode",
                headerName: "ISO CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Seals",
                headerName: "SEALS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Status",
                headerName: "STATUS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineName",
                headerName: "CARRIER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ImdgCode",
                headerName: "IMDG CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "LoadPort",
                headerName: "LOAD PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "DeliveryPort",
                headerName: "DELIVERY PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TranshipmentPort",
                headerName: "TRANSHIPMENT PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
                lockPosition: true,
            },
            {
                field: "CraneAlias",
                headerName: "CRANE", 
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                lockPosition: true,
            },
            {
                field: "weigth",
                headerName: "WEIGTH"+' ('+this.labelUnitMeasure+')',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                minWidth: 150,
            },
            {
                field: "UnNumber",
                headerName: "UN NUMBER", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            },
            {
                field: "restowType",
                headerName: "RESTOW TYPE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            },
            {
                field: "reason",
                headerName: "RESTOW REASON", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "observation_reason",
                headerName: "OBSERVATION RESTOW", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "ConfirmationDate",
                headerName: "CONFIRMATION", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 190,
            },
            {
                field: "DeviceCode",
                headerName: "DEVICE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 190,
            },
            {
                field: "TransaLogin",
                headerName: "USER", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 190,
            },
            {
                field: "YardNamePlanning",
                headerName: "YARD", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "Procedence",
                headerName: "PROCEDENCE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            })
        return columnDefs;
    }
   
    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0  && this.nulo!="null"){
            this.CraneAlias = this.rows[0].CraneAlias;

            let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            this.rows.map(function(item,index){
                computedItems.push({
                    id: index+1,
                    ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                    VesselUbicationName: item['VesselUbicationName'+_lang] ?? '',
                    VesselHoldName: item.VesselHoldName ?? '',
                    HatchCoverName: item.HatchCoverName ?? '',
                    size: item.Size ? item.Size : '',
                    Status: item.Status ? item.Status : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    weigth: item.Weigth ? item.Weigth : 0,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ArrivalPosition: item.ArrivalPosition ? item.ArrivalPosition : '',
                    DepartPosition: item.DepartPosition ? item.DepartPosition : '',
                    restowType: item.TransacStowageName ? item.TransacStowageName : '',
                    reason: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                    observation_reason: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    Seals: item.Seals ? item.Seals : '', 
                    IsoCode: item.IsoCode ? item.IsoCode : '', 
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '', 
                    TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                    CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                    ConfirmationDate: item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '',
                    DeviceCode: item.DeviceCode ? item.DeviceCode : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }
    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    /*function dropItemReport(newQuestion,oldQuestion){
        if(newQuestion === 25){
            this.SizeChanged = 10;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            //this.getCraneList();

            //this.getRestowList();
        }else if(oldQuestion === 12){
            this.SizeChanged = 10;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
        }
    } */
    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }
    export default {
        name: "restow-list",
        data,
        beforeMount,
        props: ['value'],
        methods:{
            getRestowList,
            onBtnPdf,
            onBtnExport,
            onGridReady,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            getCraneList,
            filterCrane,
            validateDateRange,
            refreshComponent, 
            getDateRange,
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                user: state => state.auth.user,
                dropItemReport: state => state.visitas.dropItemReport,
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,
            }),
            craneListFormatted,
        },
        watch:{
            textSearch,
            SizeChanged,
            dropItemReport: async function (newValue) {
                if (newValue==25) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>