
<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.yardClientSummary') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>

            </CCol>
        </CRow>      
        <CRow>
            <CCol sm="11" lg="6" class="center-field">
                <CSelect
                    :value.sync="ClientTpId"
                    v-model.trim="ClientTpId"
                    :horizontal="{ label: 'col-sm-12 col-lg-auto text-right', input: 'col-sm-12 col-lg-7'}"
                    :label="$t('label.yardConsignee')"
                    :options="clientListFormatted"
                    @change="getBlVesselHoldSummary"
                />
            </CCol>
            <CCol sm="11" lg="3" class="center-field  ">
                <CSelect
                    :value.sync="VisitCraneId"
                    v-model.trim="VisitCraneId"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    @change="getBlVesselHoldSummary"
                />
            </CCol>
            <CCol sm="11" lg="3" class="center-field  ">
                <CSelect
                    :value.sync="vesselHoldId"
                    v-model.trim="vesselHoldId"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :label="$t('label.hold')"
                    :options="holdListFormatted"
                    @change="getBlVesselHoldSummary"
                />
            </CCol>
            <CCol sm="11" lg="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="5" class="center-field  ">
            </CCol>
            <CCol sm="11" lg="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 text-right', input: 'col-sm-12 col-lg-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            craneOptions: [],
            clientOptions: [],
            holdOptions: [],
            formatedItems: [],

            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
     
            loadingOverlay:false,

            nulo:'',
            pageSize:'',
            search:'',
            VisitCraneId:'',
            vesselHoldId: '',
            ClientTpId: '',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ImplementAlias:rowData[i].ImplementAlias,
                YardName:rowData[i].YardName,
                YardClientName:rowData[i].YardClientName,
                CraneAlias:rowData[i].CraneAlias,
                VesselHoldName: rowData[i].VesselHoldName,

                EstimatedTon:rowData[i].EstimatedTonOrigin,
                WeighedTon:rowData[i].WeighedTonOrigin,
            });
        } 

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.yardClientSummary'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(value) {
        this.loadingOverlay = true;
        let rowData = [];

        let valores = []; 
        let currentDate = new Date();
        let CraneData = this.craneOptions.find( item => item.VisitCraneId === this.VisitCraneId );
        let holdData = this.holdOptions.find( item => item.VesselHoldId === this.vesselHoldId );

        if(CraneData !== '' && CraneData !== null && CraneData !== undefined){
            valores[0] = this.$t('label.crane')+': '+CraneData.CraneAlias;
        }else{
            valores[0] = this.$t('label.crane')+': '+this.$t('label.ALL');
        }
 
        if(holdData !== '' && holdData !== null && holdData !== undefined){
            valores[1] = this.$t('label.hold')+': '+holdData.VesselHoldName;
        }else{
            valores[1] = this.$t('label.hold')+': '+this.$t('label.ALL');
        }

        valores[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ImplementAlias:rowData[i].ImplementAlias,
                YardName:rowData[i].YardName,
                YardClientName:rowData[i].YardClientName,
                CraneAlias:rowData[i].CraneAlias,
                VesselHoldName: rowData[i].VesselHoldName,

                EstimatedTon:rowData[i].EstimatedTon,
                WeighedTon:rowData[i].WeighedTon,
            });
        } 

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.yardClientSummary'),value, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }

    async function getBlVesselHoldSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;

        let SummaryJson = {
            VisitId: this.Visit,
            ClientTpId: this.ClientTpId,
            VisitCraneId: this.VisitCraneId,
            VesselHoldId: this.vesselHoldId,
        };

        await this.$http.post("VisitImplementSummaryByYardClient", SummaryJson, { root: 'SummaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;

            for (i=0; i < listado.length; i++) {
                this.formatedItems.push({
                    IdX: listado[i].IdX,
                    ImplementAlias: listado[i].ImplementAlias ? listado[i].ImplementAlias : '',
                    YardName: listado[i].YardName ? listado[i].YardName : '',
                    YardClientName: listado[i].YardClientName ? listado[i].YardClientName : '',
                    CraneAlias: listado[i].CraneAlias ? listado[i].CraneAlias : '',
                    VesselHoldName: listado[i].VesselHoldName ? listado[i].VesselHoldName : '',
                    EstimatedTon: listado[i].EstimatedTon ? listado[i].EstimatedTon : 0,
                    EstimatedTonOrigin: listado[i].EstimatedTon ? formatMilDecimal(parseFloat(listado[i].EstimatedTon).toFixed(2)) : '0,00',
                    WeighedTon: listado[i].WeighedTon ? listado[i].WeighedTon : 0,
                    WeighedTonOrigin: listado[i].WeighedTon ? formatMilDecimal(parseFloat(listado[i].WeighedTon).toFixed(2)) : '0,00',
                })
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize='';
        this.search=''; 
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getCraneList();
        await this.getClientList();
        await this.getHoldList();
        await this.getBlVesselHoldSummary();
        this.$emit('child-refresh',true);
    }

    async function getClientList() {
        await this.$http.ejecutar('GET', 'ConsigneBlYard-list', { CompanyBranchId:this.branch.CompanyBranchId, Filter: 'ACTIVO' })
            .then((response) => {
                this.clientOptions = response.data.data;
                
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    async function getCraneList() {
        await this.$http.get('VisitVesselCrane-list?VisitId='+this.Visit)
            .then((response) => {
                this.craneOptions = response.data.data;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    async function getHoldList() {
        await this.$http.get('VisitVesselHold-list?VisitId='+this.Visit)
            .then((response) => {
                this.holdOptions = response.data.data;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                width: 70,
                minWidth: 70,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ImplementAlias",
                headerName: this.$t('label.Gamela'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardClientName", 
                headerName: this.$t('label.clientYard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardName", 
                headerName: this.$t('label.yard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "CraneAlias",
                headerName: this.$t('label.crane'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "VesselHoldName",
                headerName: this.$t('label.hold'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "EstimatedTon",
                headerName: this.$t('label.estimatedTons'),
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    } 

                    return variable;
                },
                filter: "agNumberColumnFilter",
            },
            {
                field: "WeighedTon",
                headerName: this.$t('label.tonsWeighed'),
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    } 
                    
                    return variable;
                },
                filter: "agNumberColumnFilter",
            },
        ]

      
        return columnDefs;
    }


    function clientListFormatted(){
        let chart = [
            {
                value: "",
                label: this.$t('label.all'),
            },
        ];
        this.clientOptions.map((item) => {
            chart.push({
                label: item.ClientName,
                value: item.ClientTpId,
            });   
        });
        return chart; 
    }

    function craneListFormatted(){
        let chart = [
            {
                value: "",
                label: this.$t('label.ALL'),
            },
        ];
        this.craneOptions.map((item) => {
            chart.push({
                label: item.CraneAlias,
                value: item.VisitCraneId,
            });   
        });
        return chart;    
    }

    function holdListFormatted(){
        let chart = [
            {
                value: "",
                label: this.$t('label.ALL'),
            },
        ];
        this.holdOptions.map((item) => {
            chart.push({
                label: item.VesselHoldName,
                value: item.VesselHoldId,
            });   
        });
        return chart;    
    }
    export default {
        name: "yard-client-summary",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getBlVesselHoldSummary,
            onQuickFilterChanged,
            onPageSizeChanged,
            refreshComponent,
            getCraneList,
            getClientList,
            getHoldList,
        },
        computed:{
            clientListFormatted,
            craneListFormatted,
            holdListFormatted,
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch, 
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==63) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-12, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>