<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
            
                <div> 
                    <CRow class="my-3">
                        <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                            <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.OrdersByLapse') }}</h6>
                        </CCol>
                        <CCol sm="12" lg="4" class="d-flex align-items-center justify-content-end">
                            <CButton
                                color="excel"
                                class="mr-1"
                                size="sm" 
                                @click="onBtnExport(true)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; XSLX
                            </CButton>
                            <CButton
                                color="watch"
                                size="sm" 
                                @click="onBtnExport(false)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; CSV
                            </CButton>
                            
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol sm="12" lg="3" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9 px-0'}"
                                :label="$t('label.crane')"
                                :options="craneListFormatted"
                                v-model.trim="VisitCraneId"
                                :value.sync="VisitCraneId"
                                @change="filterCrane"
                            />        
                        </CCol>
                        <CCol sm="12" lg="3" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-8 px-0'}"
                                :label="$t('label.hold')"
                                :options="holdListFormatted"
                                v-model.trim="vesselHoldId"
                                :value.sync="vesselHoldId"
                            />        
                        </CCol>
                        <CCol sm="12" lg="4" class="center-field">
                            <CSelect
                                :value.sync="clientId"
                                v-model.trim="clientId"
                                :horizontal="{ label: 'col-sm-12 col-lg-auto text-right', input: 'col-sm-12 col-lg-8 px-0'}"
                                :label="$t('label.transport')"
                                :options="carrierListFormatted"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="3" class="center-field">
                            <div class="form-group form-row">
                                <label class="col-form-label col-sm-11 col-lg-3 text-right pt-2">{{$t('label.date')}}</label>
                                <CCol sm="12" lg="9" class="center-field px-0">
                                    <vue-datepicker 
                                        type="date"
                                        header
                                        :lang="this.$i18n.locale"
                                        :editable="false"
                                        :clearable="false"
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        time-title-format="DD/MM/YYYY"
                                        v-model="DateReport"
                                        :disabled-date="validateDateRange"
                                        :append-to-body="false"
                                        value-type="format"
                                        :show-second="false"
                                        @change="filterDateRange"
                                    >
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                    </vue-datepicker> 
                                </CCol>
                            </div>
                        </CCol>
                        <CCol sm="12" lg="3" class="center-field">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9 px-0'}"
                                :label="$t('label.lapse')"
                                :options="LapseFormatted"
                                v-model.trim="LapseId"
                                :value.sync="LapseId"
                            />        
                        </CCol>
                        <CCol class="col-sm-12 col-lg-auto center-field mr-0" style="text-align: right;">
                            <CButton
                                color="watch"
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                                @click="getOrderList()"
                                >
                                <CIcon name="cil-search" />
                            </CButton>
                            <CButton
                                color="wipe"
                                class="justify-content-end"
                                size="sm"
                                v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                                @click="refreshComponent()"
                                >
                                <CIcon name="cil-brush-alt" />
                            </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="3" class="center-field">
                            <CInput
                                :label="$t('label.filter')"
                                :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9 px-0'}"
                                :placeholder="$t('label.enterSearch')" 
                                @keyup="onQuickFilterChanged($event.target.value)" 
                                v-model="search"           
                            >
                            </CInput>  
                        </CCol>
                        <CCol sm="12" lg="5" class="center-field">
                        </CCol>
                        <CCol sm="12" lg="4" class="justify-content-end">
                            <CSelect
                                :horizontal="{ label: 'col-sm-12 col-lg-8 text-right', input: 'col-sm-12 col-lg-4 px-0'}"
                                :label="$t('label.recordsPerPage')"
                                @change="onPageSizeChanged"
                                :options="[50,100,500,1000]"
                                required 
                                v-model="pageSize"
                            >
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" lg="12" class=" mb-2">
                            <ag-grid-vue
                                v-if="showGrid"
                                style="width: 100%; height: 100vh;"
                                class="ag-theme-alpine"
                                :getRowClass="getRowClass"
                                :getRowStyle="getRowStyle"
                                :gridOptions="gridOptions"
                                :defaultColDef="defaultColDef"
                                :localeText="localeText"
                                :columnDefs="columnDefs"
                                :rowData="RowData"
                                :suppressRowClickSelection="true"
                                :groupSelectsChildren="true"
                                :enableRangeSelection="true"
                                :pagination="true"
                                :paginationPageSize="paginationPageSize"
                                :paginationNumberFormatter="paginationNumberFormatter"
                                rowSelection="single"
                                @grid-ready="onGridReady"
                                :tooltipShowDelay="tooltipShowDelay"
                                :tooltipHideDelay="tooltipHideDelay"
                            >
                            </ag-grid-vue>
                        </CCol>
                    </CRow>
                </div>  
        
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';


    //Data
    function data() {
        return {
            tooltipShowDelay: 0,
            tooltipHideDelay: 2000,
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,

            loadingOverlay:false,

            rows: [],
            carrierOptions: [],
            craneOptions: [],
            LapseOptions: [],
            holdOptions: [],

            clientId: "",
            nulo:'', 
            pageSize:'',
            search: '',

            VisitCraneId: '',
            LapseId: '',
            vesselHoldId: '',
            DateReport: '',
            DateReportfiltre: '',
            LapseCode: '',

            previousDate: new Date(),
            laterDate: new Date(),
        };
    }
    //beforeMount
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'generateOrder'){
                    if(event.value !== ""){
                        this.getOrderReport(event.data);
                    }
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'generateOrder'){
                   tippy('#myGenerateOrderButton', {
                        content: this.$t('label.generateOrder'),
                    })
                }
            },
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function created() {
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.tooltipShowDelay = 0;
        this.tooltipHideDelay = 2000;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    function getRowClass() {
        return 'bg-gradient';
    }

    async function getOrderList () {
        this.loadingOverlay = true;

        let ShipmentOrderJson = {
            VisitId: this.Visit,
            StowagePlannigResourceId: this.clientId,
            VisitCraneId: this.VisitCraneId,
            VesselHoldId: this.vesselHoldId,
            VisitLapseId: this.LapseId,
            DateReport: this.DateReport ? DateFormater.formatDateTimeWithoutSlash(this.DateReport) : '',
        };

        await this.$http.post("ShipmentOrderLapse-list", ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then(response => {
            let _lang = this.$i18n.locale;
            let computedItems = [];
            let listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado =='')
            { 
                this.rows = [];
                return false;
            }
            
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                };
            });
            
            this.DateReportfiltre = this.DateReport;
            if(this.LapseId){
                let lapseData = this.LapseOptions.find( item => item.VisitLapseId === this.LapseId );
                this.LapseCode = lapseData.VisitLapseCode;
            }else{
                this.LapseCode = 'N/A'
            }

            let _this = this;
            if(this.rows.length !== 0 && this.nulo!="null"){

                this.rows.map(function(item){
                    var CircuitId = '';
                    var CircuitName = ''; 
                    var CircuitOrder = '';

                    if(item.CurrentCircuitJson.length > 0){
                        CircuitId= item.CurrentCircuitJson[0].CircuitId ? item.CurrentCircuitJson[0].CircuitId : '';
                        CircuitName= _lang=='en' ?  item.CurrentCircuitJson[0].CircuitNameEn : item.CurrentCircuitJson[0].CircuitNameEs;
                        CircuitOrder= item.CurrentCircuitJson[0].CircuitOrder ? item.CurrentCircuitJson[0].CircuitOrder : '';
                    }

                    computedItems.push({
                        Idx: item.Nro,
                        generateOrder: _this.$t('label.generateOrder'),
                        OrderId: item.OrderId ? item.OrderId : '',

                        StowagePlanningVehicleId: item.StowagePlanningVehicleId ? item.StowagePlanningVehicleId : '',
                        VehicleId: item.VehicleId ? item.VehicleId : '',
                        LicensePlate: item.LicensePlate ? item.LicensePlate : '',
                        DriverName: item.DriverName ? item.DriverName : '',
                        StowagePlanningImplementVehicleId: item.StowagePlanningImplementVehicleId ? item.StowagePlanningImplementVehicleId : '',
                        StowagePlanningImplementId: item.StowagePlanningImplementId ? item.StowagePlanningImplementId : '',
                        ImplementId: item.ImplementId ? item.ImplementId : '',
                        ImplementAlias: item.ImplementAlias ? item.ImplementAlias : '',

                        NroBl: item.NroBl ? item.NroBl : '',

                        OrderCode: item.OrderCode ? item.OrderCode : '',

                        ClientId: item.ClientId ? item.ClientId : '',
                        ClientName: item.ClientName ? item.ClientName : '',
                        ClientRif: item.ClientRif ? item.ClientRif : '',
                        ClientTpId: item.ClientTpId ? item.ClientTpId : '',
                        YardId: item.YardId ? item.YardId : '',
                        YardName: item.YardName ? item.YardName : '',
                        YardClientName: item.YardClientName ? item.YardClientName : '',
                        YardClientTpId: item.YardClientTpId ? item.YardClientTpId : '',
                        
                        OrderDate: item.TransactionDate ? item.TransactionDate : '', 
                        TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '', //fecha emision
                        LastDate: item.LastDate ? DateFormater.formatDateTimeWithSlash(item.LastDate) : '', //ultima fecha
                        FinishDate: item.FinishDate ? DateFormater.formatDateTimeWithSlash(item.FinishDate) : '', //fecha embarqyue

                        VisitLapseId: item.VisitLapseId ? item.VisitLapseId : '', //corte
                        VisitLapseCode: item.VisitLapseCode ? item.VisitLapseCode : '', //corte

                        TotalTime: item.TotalTime ? item.TotalTime : '',

                        VisitCraneId: item.VisitCraneId ? item.VisitCraneId : '',
                        CraneAlias: item.CraneAlias ? item.CraneAlias : '',

                        VesselHoldId: item.VesselHoldId ? item.VesselHoldId : '',
                        VesselHoldName: item.VesselHoldName ? item.VesselHoldName : '',

                        DeviceId: item.DeviceId ? item.DeviceId : '',
                        DeviceCode: item.DeviceCode ? item.DeviceCode : '',

                        OrderStatus: item.OrderStatus ? item.OrderStatus : '',
                        OrderStatusDs: _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs,
                        
                        TransaLogin: item.TransaLogin ? item.TransaLogin : '',

                        CircuitId: CircuitId,
                        CircuitName: CircuitName,
                        CircuitOrder: CircuitOrder,

                        TransactionFinishTurnId: item.TransactionFinishTurnId ? item.TransactionFinishTurnId : '',
                        TransactionFinishTurnName: item.TransactionFinishTurnName ? item.TransactionFinishTurnName+' ('+item.TransactionFinishStartHour+'-'+item.TransactionFinishEndHour+')' : '',

                        ColorStatus: item.ColorStatus ? item.ColorStatus : '',

                        StowagePlanningBillOfLadingId: item.StowagePlanningBillOfLadingId ? item.StowagePlanningBillOfLadingId : '',
                        StowagePlanningResourceId: item.StowagePlanningResourceId ? item.StowagePlanningResourceId : '',
                        StowagePlanningDriverId: item.StowagePlanningDriverId ? item.StowagePlanningDriverId : '',
                        
                        TransaUserId: item.TransaUserId ? item.TransaUserId : '',

                        DriverId: item.DriverId ? item.DriverId : '',
                        DriverCi: item.DriverCi ? item.DriverCi : '',
                        DriverName: item.DriverName ? item.DriverName : '',
                        Observation: item.Observation ? item.Observation : '',
                        
                        NetWeight: item.NetWeight ? item.NetWeight : 0,
                        FullWeight: item.FullWeight ? item.FullWeight : 0,
                        TareWeight: item.TareWeight ? item.TareWeight : 0,
                    })
                })
            }
            this.rows = [];
            this.rows = computedItems; 
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function filterDateRange(event) {
        this.DateReport=event;
        this.LapseId='';
        this.getLapseList(true);
    }
    
    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.getHoldList(true);
    }
    //transportes
    async function getCarrierList() {
        await this.$http
            .get('VisitCarrier-list?VisitId='+this.Visit)
            .then((response) => {
                this.carrierOptions = response.data.data;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    //gruas
    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    //bodegas
    async function getHoldList(loading) {
        this.loadingOverlay = true;
        let metodo = "VisitVesselHold-list";
        if (this.VisitCraneId !== '') {
            metodo = "VisitVesselCraneHold-list";
        }
        await this.$http.get( metodo, { VisitId: this.Visit, VisitCraneId: this.VisitCraneId })
        .then(response => {
            this.holdOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            loading ? this.loadingOverlay = false : '';
        });
    }

    //status
    async function getLapseList(loading) {
        this.loadingOverlay = true;
        let date = this.DateReport ? DateFormater.formatDateTimeWithoutSlash(this.DateReport) : '';
        await this.$http.get("VisitLapseReport-list", {VisitId: this.Visit, DateReport: date})
        .then(response => {
            this.LapseOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            loading ? this.loadingOverlay = false : '';
        });
    }
    //rango de fechas
    function getDateRange(){
        let currentDate = new Date();
        
        if(this.itinerarySelected.Ata!=undefined){ 
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined){
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined){  
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined){
            this.laterDate = currentDate;
        }
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search = "";
        this.tooltipShowDelay = 0,
        this.tooltipHideDelay = 2000,
        this.clientId = "",
        this.pageSize="";

        this.VisitCraneId= "";
        this.vesselHoldId = "";
        this.LapseId = "";
        this.DateReport="";

        this.onQuickFilterChanged(this.search);
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getCarrierList();
        await this.getCraneList();
        await this.getLapseList();
        await this.getHoldList();
        await this.getDateRange();
        await this.getOrderList();
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

        //status
    async function getOrderReport(data) {
        this.loadingOverlay = true;
        let listado = [];
        let generalData = [];
        let incidenceData = [];
        let fullWeighingData = [];
        let emptyWeighingData = [];
        let shimpmentData = [];
        let _lang = this.$i18n.locale;
        let isAnnulled = (data.OrderStatus == "BED237C2-60CA-EC11-A87C-000D3A0DEDC7") ? true : false;

        await this.$http.ejecutar('GET', 'VisitShipmentOrderReport', { OrderId: data.OrderId }).then(response => {
            
            listado = response.data.data;

                if(listado[0].GeneralDataOrderJson.length > 0){
                    generalData.push({
                        NetWeight: listado[0].GeneralDataOrderJson[0].NetWeight ? formatMilDecimal(parseFloat(listado[0].GeneralDataOrderJson[0].NetWeight).toFixed(2)) : 0,
                        BlClientName: listado[0].GeneralDataOrderJson[0].BlClientName ? listado[0].GeneralDataOrderJson[0].BlClientName : '',
                        BlClientRif: listado[0].GeneralDataOrderJson[0].BlClientRif ? listado[0].GeneralDataOrderJson[0].BlClientRif : '',
                        ClientName: listado[0].GeneralDataOrderJson[0].ClientName ? listado[0].GeneralDataOrderJson[0].ClientName : '',
                        ClientRif: listado[0].GeneralDataOrderJson[0].ClientRif ? listado[0].GeneralDataOrderJson[0].ClientRif : '',
                        TransactionDate: listado[0].GeneralDataOrderJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].GeneralDataOrderJson[0].TransactionDate) : '',  //fecha emision
                        OrderCode: listado[0].GeneralDataOrderJson[0].OrderCode ? listado[0].GeneralDataOrderJson[0].OrderCode : '',
                        DriverCi: listado[0].GeneralDataOrderJson[0].DriverCi ? listado[0].GeneralDataOrderJson[0].DriverCi : '',
                        DriverName: listado[0].GeneralDataOrderJson[0].DriverName ? listado[0].GeneralDataOrderJson[0].DriverName : '',
                        LicensePlate: listado[0].GeneralDataOrderJson[0].LicensePlate ? listado[0].GeneralDataOrderJson[0].LicensePlate : '',
                        ImplementAlias: listado[0].GeneralDataOrderJson[0].ImplementAlias ? listado[0].GeneralDataOrderJson[0].ImplementAlias : '',
                        CommodityName: listado[0].GeneralDataOrderJson[0].CommodityName ? listado[0].GeneralDataOrderJson[0].CommodityName : '',
                        Observation: listado[0].GeneralDataOrderJson[0].Observation ? listado[0].GeneralDataOrderJson[0].Observation : '',
                        YardClientName: listado[0].GeneralDataOrderJson[0].YardClientName ? listado[0].GeneralDataOrderJson[0].YardClientName : '',
                        YardClientRif: listado[0].GeneralDataOrderJson[0].YardClientRif ? listado[0].GeneralDataOrderJson[0].YardClientRif : '',
                        YardName: listado[0].GeneralDataOrderJson[0].YardName ? listado[0].GeneralDataOrderJson[0].YardName : '',
                    })                
                };
                let i=1;
                if(listado[0].IncidenceInfoJson.length > 0){
                    for(let j=0; j < listado[0].IncidenceInfoJson.length; j++){
                        incidenceData.push({
                            Nro: i++,
                            IncidenceName: _lang =='en' ? listado[0].IncidenceInfoJson[j].IncidenceNameEn : listado[0].IncidenceInfoJson[j].IncidenceNameEs,
                            TransactionBegin: listado[0].IncidenceInfoJson[j].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[0].IncidenceInfoJson[j].TransactionBegin) : '',
                            TransactionFinish: listado[0].IncidenceInfoJson[j].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[0].IncidenceInfoJson[j].TransactionFinish) : '', 
                            TotalTimeH: listado[0].IncidenceInfoJson[j].TotalTimeH ? listado[0].IncidenceInfoJson[j].TotalTimeH : '',
                        })   
                    }             
                };

                if(listado[0].TareWeighingInfoJson.length > 0){
                    emptyWeighingData.push({
                        WeightKgs: listado[0].TareWeighingInfoJson[0].WeightKgs ? listado[0].TareWeighingInfoJson[0].WeightKgs+' KG' : '0 KG',
                        WeightTon: listado[0].TareWeighingInfoJson[0].WeightTon ? listado[0].TareWeighingInfoJson[0].WeightTon+' TON' : '0 TON',
                        TruckWeghingScaleName: listado[0].TareWeighingInfoJson[0].TruckWeghingScaleName ? listado[0].TareWeighingInfoJson[0].TruckWeghingScaleName : '',
                        TransactionDate: listado[0].TareWeighingInfoJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].TareWeighingInfoJson[0].TransactionDate) : '',
                    })                
                }else{
                    emptyWeighingData.push({
                        WeightKgs: '0 KG',
                        WeightTon: '0 TON',
                        TruckWeghingScaleName: '',
                        TransactionDate: '',
                    })
                };

                if(listado[0].FullWeighingInfoJson.length > 0){
                    fullWeighingData.push({
                        WeightKgs: listado[0].FullWeighingInfoJson[0].WeightKgs ? listado[0].FullWeighingInfoJson[0].WeightKgs+' KG' : '0 KG',
                        WeightTon: listado[0].FullWeighingInfoJson[0].WeightTon ? listado[0].FullWeighingInfoJson[0].WeightTon+' TON' : '0 TON',
                        TruckWeghingScaleName: listado[0].FullWeighingInfoJson[0].TruckWeghingScaleName ? listado[0].FullWeighingInfoJson[0].TruckWeghingScaleName : '',
                        TransactionDate: listado[0].FullWeighingInfoJson[0].TransactionDate ? DateFormater.formatDateTimeWithSlash(listado[0].FullWeighingInfoJson[0].TransactionDate) : '',
                    })                
                }else{
                    fullWeighingData.push({
                        WeightKgs: '0 KG',
                        WeightTon: '0 TON',
                        TruckWeghingScaleName: '',
                        TransactionDate: '',
                    })
                };

                if(listado[0].ShimpmentInfoJson.length > 0){
                    shimpmentData.push({
                        CraneAlias: listado[0].ShimpmentInfoJson[0].CraneAlias ? listado[0].ShimpmentInfoJson[0].CraneAlias : '',
                        VesselHoldName: listado[0].ShimpmentInfoJson[0].VesselHoldName ? listado[0].ShimpmentInfoJson[0].VesselHoldName : '',
                        TransaLoginName: listado[0].ShimpmentInfoJson[0].TransaLoginName ? listado[0].ShimpmentInfoJson[0].TransaLoginName : '',
                        TransaLogin: listado[0].ShimpmentInfoJson[0].TransaLogin ? listado[0].ShimpmentInfoJson[0].TransaLogin : '',
                        TransactionBegin: listado[0].ShimpmentInfoJson[0].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[0].ShimpmentInfoJson[0].TransactionBegin) : '',
                        TransactionFinish: listado[0].ShimpmentInfoJson[0].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[0].ShimpmentInfoJson[0].TransactionFinish) : '',
                    })                
                };
                
                this.orderMetalScrapPdf(generalData[0], incidenceData, fullWeighingData[0], emptyWeighingData[0], shimpmentData[0], this.$t('label.order'), isAnnulled);

            }).catch( err => {
                this.loadingOverlay = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).then(() => {});
            this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];
        let valores = []; 
        let date = this.DateReportfiltre ? this.DateReportfiltre : 'N/A';
        let currentDate = new Date();
        const transportData = this.carrierOptions.find( item => item.StowagePlanningResourceId === this.clientId );
        const craneData = this.craneOptions.find( item => item.VisitCraneId === this.VisitCraneId );
        const holdData = this.holdOptions.find( item => item.VesselHoldId === this.vesselHoldId );

        if(transportData !== '' && transportData !== null && transportData !== undefined){
            valores[2] = this.$t('label.transport')+': '+transportData.ClientName;
        }else{
            valores[2] = this.$t('label.transport')+': N/A';
        }

        if(holdData !== '' && holdData !== null && holdData !== undefined){
            valores[4] = this.$t('label.hold')+': '+holdData.VesselHoldName;
        }else{
            valores[4] = this.$t('label.hold')+': N/A';
        }

        if(craneData !== '' && craneData !== null && craneData !== undefined){
            valores[3] = this.$t('label.crane')+': '+craneData.CraneAlias;
        }else{
            valores[3] = this.$t('label.crane')+': N/A';
        }
 
        valores[0] = this.$t('label.shippingDate')+': '+date;
        valores[1] = this.$t('label.lapse')+': '+this.LapseCode;
        valores[5] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[6] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push( node.data ));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                Idx:rowData[i].Idx,
                LicensePlate:rowData[i].LicensePlate,
                DriverName:rowData[i].DriverName,
                ImplementAlias:rowData[i].ImplementAlias,
                NroBl:rowData[i].NroBl,
                OrderCode: rowData[i].OrderCode,
                ClientName:rowData[i].ClientName,
                ClientRif:rowData[i].ClientRif,
                YardName:rowData[i].YardName,
                YardClientName:rowData[i].YardClientName,
                OrderDate:rowData[i].OrderDate, 
                TransactionDate:rowData[i].TransactionDate, //fecha emision
                LastDate:rowData[i].LastDate, //ultima fecha
                FinishDate:rowData[i].FinishDate, //fecha embarqyue
                VisitLapseCode:rowData[i].VisitLapseCode, //corte
                TotalTime:rowData[i].TotalTime,
                CraneAlias:rowData[i].CraneAlias,
                VesselHoldName:rowData[i].VesselHoldName,
                DeviceCode:rowData[i].DeviceCode,
                OrderStatus:rowData[i].OrderStatus,
                OrderStatusDs:rowData[i].OrderStatusDs,
                TransaLogin:rowData[i].TransaLogin,
                CircuitName:rowData[i].CircuitName,
                CircuitOrder:rowData[i].CircuitOrder,
                TransactionFinishTurnId:rowData[i].TransactionFinishTurnId,
                TransactionFinishTurnName:rowData[i].TransactionFinishTurnName,
                ColorStatus: rowData[i].ColorStatus,
                DriverCi: rowData[i].DriverCi,
                DriverName: rowData[i].DriverName,
                Observation: rowData[i].Observation,
                NetWeight: rowData[i].NetWeight,
                FullWeight: rowData[i].FullWeight,
                TareWeight: rowData[i].TareWeight,
            });
        }

        if(this.nulo!="null" && this.RowData.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.OrdersByLapse'),valor, valores,1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }
    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }
//computed
    //transporte
    function carrierListFormatted(){
        const chart = [
            {
                value: "",
                label: this.$t('label.select'),
            },
        ];
        this.carrierOptions.map((item) => {
            chart.push({
                label: item.ClientName,
                value: item.StowagePlanningResourceId,
            });   
        });
        return chart;    
    }

    //gruas
    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    //bodegas
    function holdListFormatted(){
        if(this.holdOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.holdOptions.map(function(e){
                
                    chart.push({
                        value: e.VesselHoldId, 
                        label: e.VesselHoldName,
                    })
                
            })
            return chart;
        }
    }
    //Lapse
    function LapseFormatted(){
        if(this.LapseOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.LapseOptions.map(function(e){
                chart.push({
                    value: e.VisitLapseId, 
                    label: `${e.VisitLapseCode} (${e.HourRange}) `,
                })  
            })
            return chart;
        }
    }

    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'generateOrder',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "generateOrder",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="myGenerateOrderButton" class="btn mr-1 btn-danger btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon">
                                        <path fill="var(--ci-primary-color, currentColor)" d="M358.627,16H72V240H16V496H496V153.373ZM464,166.627V168H344V48h1.373ZM48,464V272H464V464Zm56-224V48H312V200H464v40Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M148.5,304H100V432h32V400h16.5A47.555,47.555,0,0,0,196,352.5v-1A47.555,47.555,0,0,0,148.5,304ZM164,352.5A15.517,15.517,0,0,1,148.5,368H132V336h16.5A15.517,15.517,0,0,1,164,351.5Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="340 432 372 432 372 392 404 392 404 360 372 360 372 336 420 336 420 304 340 304 340 432" class="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M259.833,304H212V432h47.833A56.23,56.23,0,0,0,316,375.833V360.167A56.23,56.23,0,0,0,259.833,304ZM284,375.833A24.194,24.194,0,0,1,259.833,400H244V336h15.833A24.194,24.194,0,0,1,284,360.167Z" class="ci-primary"></path>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1 gb-cell',
            },
            {
                field: "Idx",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 70,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "OrderCode",
                headerName: this.$t('label.noOrder'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial text-color-negrita gb-cell',
            },
            {
                field: "DriverName",
                headerName: this.$t('label.driver'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "LicensePlate",
                headerName: this.$t('label.vehicle'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "ImplementAlias",
                headerName: this.$t('label.Gamela'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "NroBl",
                headerName: "BL",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "ClientName",
                headerName: this.$t('label.transport'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardName",
                headerName: this.$t('label.yard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "YardClientName",
                headerName: this.$t('label.clientYard'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 180,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TareWeight",
                headerName: this.$t('label.Taraweight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "FullWeight",
                headerName: this.$t('label.FullWeight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                   
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "NetWeight",
                headerName: this.$t('label.netWeight')+" (KGM)",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
                lockPosition: true,
                suppressMovable: true,
            },
            {
                field: "TransactionDate",
                headerName: this.$t('label.emissionDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "FinishDate",
                headerName: this.$t('label.shippingDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            { 
                field: "TransactionFinishTurnName",
                headerName: this.$t('label.turn'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            {
                field: "VisitLapseCode",
                headerName: this.$t('label.lapse'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "TotalTime",
                headerName: this.$t('label.totalTime'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "CraneAlias",
                headerName: this.$t('label.crane'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "VesselHoldName",
                headerName: this.$t('label.hold'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.device'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 160,
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.checker'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 220,
            },
            {
                field: "LastDate",
                headerName: this.$t('label.lastDate'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "CircuitName",
                headerName: this.$t('label.currentCircuit'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 200,
            },
            {
                field: "OrderStatusDs",
                headerName: this.$t('label.status'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                cellClass: 'center-cell-especial text-color-negrita',
                minWidth: 160,
            },
        ]
        return columnDefs;
    }

    function RowData(){
        if(this.rows.length != 0){
            return this.rows.map((item) => {
                return {
                    ...item,
                };
            });
        }else{
            return [];
        }
    }
    export default {
        name: "shipment-order-by-lapse",
        data,
        beforeMount,
        created,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            getRowClass,
            getRowStyle,
            filterDateRange,
            onGridReady,
            getOrderList,
            onQuickFilterChanged,
            onPageSizeChanged,
            refreshComponent,
            filterCrane,
            getCarrierList,
            getCraneList,
            getLapseList,
            getHoldList,
            getDateRange,
            validateDateRange,
            getOrderReport,
            onBtnExport,
        },
        computed:{
            carrierListFormatted,
            craneListFormatted,
            holdListFormatted,
            LapseFormatted,
            columnDefs,
            RowData,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                branch: state => state.auth.branch,
            }),
        },
        components:{
            tippy,
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue == 72 && this.VisitId != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss">
    .tippy-box[data-theme~='bluesimple'] {
        fill: #6ca3d7;
        color:#606a87;
    }
    .text-color-negrita {
        font-weight: bold;
    }

/**
 ****************************
 * Tooltip
 ****************************
*/
    .ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom) {
        -webkit-box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
                box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    }

    .ag-theme-alpine .ag-tooltip {
        background-color: #000015;
        background-color: var(--ag-header-background-color, #000015);
        color: #fff;
        color: var(--ag-foreground-color, #fff);
        padding: 6px;
        border: solid 1px;
        border-color: #000015;
        border-color: var(--ag-border-color, #000015);
        border-radius: 3px;
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
        white-space: normal;
        text-align: center;
        border-radius: 0.25rem;
    }

    .ag-theme-alpine .ag-tooltip.ag-tooltip-hiding {
        opacity: 0;
    }

    .ag-theme-alpine .ag-tooltip-custom {
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
    }

    .ag-theme-alpine .ag-tooltip-custom.ag-tooltip-hiding {
        opacity: 0;
    }

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>