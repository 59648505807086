
<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.transportSummary') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>

            </CCol>
        </CRow>      
        <CRow>
            <CCol sm="11" lg="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="5" class="center-field  ">
                <CSelect
                    :value.sync="clientId"
                    v-model.trim="clientId"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9'}"
                    :label="$t('label.transport')"
                    :options="carrierListFormatted"
                    @change="getTransportSummary"
                />
            </CCol>
            <CCol sm="11" lg="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 text-right', input: 'col-sm-10 col-lg-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            carrierOptions: [],
            formatedItems: [],
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
     
            loadingOverlay:false,

            nulo:'',
            pageSize:'',
            search:'',
            clientId:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];
        rowDataFormated.push({
            IdX:'',
            ClientName:'',
            FgActRequiredTruckWeighing:'',
            OrderCantDispatched: this.$t('label.orders'),
            OrderCantEmbarked: this.$t('label.orders'),
            OrderCantReturned: this.$t('label.orders'),
            OrderCantInCircuit: this.$t('label.orders'),
            OrderCantAnnulled: this.$t('label.orders'),
            OrderCantIncidence: this.$t('label.orders'),
            TimeWorkedHours: this.$t('label.times'),
            TimeDelayHours: this.$t('label.times'), 
            TimeEffectiveHours: this.$t('label.times'), 
            Weighed: this.$t('label.Tons'),
            ImplementXHour: this.$t('label.Tons'),
            TonXHour: this.$t('label.Tons'),

        })
            
        for (let i = 1; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ClientName:rowData[i].ClientName,
                FgActRequiredTruckWeighing:rowData[i].FgActRequiredTruckWeighing,
                OrderCantDispatched:rowData[i].OrderCantDispatched,
                OrderCantEmbarked:rowData[i].OrderCantEmbarked,
                OrderCantReturned: rowData[i].OrderCantReturned,
                OrderCantInCircuit:rowData[i].OrderCantInCircuit,
                OrderCantAnnulled:rowData[i].OrderCantAnnulled,
                OrderCantIncidence:rowData[i].OrderCantIncidence,
                TimeWorkedHours:rowData[i].TimeWorkedHours,
                TimeDelayHours:rowData[i].TimeDelayHours, 
                TimeEffectiveHours:rowData[i].TimeEffectiveHours, 
          
                Weighed:rowData[i].WeightOrigin,
                ImplementXHour:rowData[i].ImplementXHourOrigin,
                TonXHour:rowData[i].TonXHourOrigin,
            });
        }  
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.transportSummary'), 'transportSummary');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(value) {
        this.loadingOverlay = true;
        let rowData = [];

        let valores = []; 
        let currentDate = new Date();
        const transportData = this.carrierOptions.find( item => item.StowagePlanningResourceId === this.clientId );

        if(transportData !== '' && transportData !== null && transportData !== undefined){
            valores[0] = this.$t('label.transport')+': '+transportData.ClientName;
        }else{
            valores[0] = this.$t('label.transport')+': '+this.$t('label.all');
        }
 
        valores[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];
        rowDataFormated.push({
            section: 'transport_summary',
            OrderCantDispatched: this.$t('label.orders'),
            TimeWorkedHours: this.$t('label.times'),
            Weighed: this.$t('label.Tons')
        })
            
        for (let i = 1; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ClientName:rowData[i].ClientName,
                FgActRequiredTruckWeighing:rowData[i].FgActRequiredTruckWeighing,
                OrderCantDispatched:rowData[i].OrderCantDispatched,
                OrderCantEmbarked:rowData[i].OrderCantEmbarked,
                OrderCantReturned: rowData[i].OrderCantReturned,
                OrderCantInCircuit:rowData[i].OrderCantInCircuit,
                OrderCantAnnulled:rowData[i].OrderCantAnnulled,
                OrderCantIncidence:rowData[i].OrderCantIncidence,
                TimeWorkedHours:rowData[i].TimeWorkedHours,
                TimeDelayHours:rowData[i].TimeDelayHours, 
                TimeEffectiveHours:rowData[i].TimeEffectiveHours, 
          
                Weighed:rowData[i].Weighed,
                ImplementXHour:rowData[i].ImplementXHour,
                TonXHour:rowData[i].TonXHour,
            });
        }

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.transportSummary'),value, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }

    async function getTransportSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;

        let SummaryJson = {
            VisitId: this.Visit,
            StowagePlannigResourceId: this.clientId,
        };

        await this.$http.post("VisitOrderSummaryByCarrier", SummaryJson, { root: 'SummaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            this.formatedItems.push({
                section: 'transport_summary',
                OrderCantDispatched: this.$t('label.orders'),
                TimeWorkedHours: this.$t('label.times'),
                Weighed: this.$t('label.Tons')
            })

            for (i=0; i < listado.length; i++) {
                this.formatedItems.push({
                    IdX: listado[i].IdX,
                    ClientName: listado[i].ClientName ? listado[i].ClientName : '',
                    FgActRequiredTruckWeighing: listado[i].FgActRequiredTruckWeighing ? this.$t('label.yes') : 'NO',
                    OrderCantDispatched: listado[i].OrderCantDispatched ? listado[i].OrderCantDispatched : 0,
                    OrderCantEmbarked: listado[i].OrderCantEmbarked ? listado[i].OrderCantEmbarked : 0,
                    OrderCantReturned: listado[i].OrderCantReturned ? listado[i].OrderCantReturned : 0,
                    OrderCantInCircuit: listado[i].OrderCantInCircuit ? listado[i].OrderCantInCircuit : 0,
                    OrderCantAnnulled: listado[i].OrderCantAnnulled ? listado[i].OrderCantAnnulled : 0,
                    OrderCantIncidence: listado[i].OrderCantIncidence ? listado[i].OrderCantIncidence : 0,
                    TimeWorkedHours: listado[i].TimeWorkedHours ? listado[i].TimeWorkedHours : 0,
                    TimeDelayHours: listado[i].TimeDelayHours ? listado[i].TimeDelayHours : 0,
                    TimeEffectiveHours: listado[i].TimeEffectiveHours ? listado[i].TimeEffectiveHours : 0,

                    Weighed: listado[i].Weighed ? listado[i].Weighed : 0,
                    WeightOrigin: listado[i].Weighed ? formatMilDecimal(parseFloat(listado[i].Weighed).toFixed(2)) : '0,00',

                    ImplementXHour: listado[i].ImplementXHour ? listado[i].ImplementXHour : 0,
                    ImplementXHourOrigin: listado[i].ImplementXHour ? formatMilDecimal(parseFloat(listado[i].ImplementXHour).toFixed(2)) : '0,00',

                    TonXHour: listado[i].TonXHour ? listado[i].TonXHour : 0,
                    TonXHourOrigin: listado[i].TonXHour ? formatMilDecimal(parseFloat(listado[i].TonXHour).toFixed(2)) : '0,00',
                })
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize='';
        this.search=''; 
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getCarrierList();
        await this.getTransportSummary();
        this.$emit('child-refresh',true);
    }

    //transportes
    async function getCarrierList() {
        await this.$http.get('VisitCarrier-list?VisitId='+this.Visit)
            .then((response) => {
                this.carrierOptions = response.data.data;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
            });
        });
    }

    function getRowClass(params) {
        if ((params.node.data.section=="transport_summary")) {
            return 'bg-secondary font-weight-bold text-center'
        }
    }

    //computed
    function isTotal(params) {
        return params.data.section === 'transport_summary';
    }

    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ClientName", 
                headerName: this.$t('label.transport'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "FgActRequiredTruckWeighing",
                headerName: this.$t('label.RequiredWeight'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "OrderCantDispatched",
                headerName: this.$t('label.Dispached'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 6;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-watch';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "OrderCantEmbarked",
                headerName: this.$t('label.Loadeds'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "OrderCantReturned",
                headerName: this.$t('label.Returned'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "OrderCantInCircuit",
                headerName: this.$t('label.onCircuit'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "OrderCantAnnulled",
                headerName: this.$t('label.Annulled'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "OrderCantIncidence",
                headerName: this.$t('label.incidences'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TimeWorkedHours",
                headerName: this.$t('label.HorasTrabajadas'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                colSpan: (params) => {
                    if (isTotal(params)) {
                        this.color = 'bg-info';
                        return 3;
                    } else {
                        this.color = '';
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-plan';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "TimeDelayHours",
                headerName: this.$t('label.HorasRetrasos'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TimeEffectiveHours",
                headerName: this.$t('label.HorasEfectivas'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Weighed",
                headerName: this.$t('label.Heavy'),
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 3;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-edit';
                    } else {
                        return 'center-cell-especial';
                    }
                },
                cellRenderer: params => {
                    let variable = 0.00;
                    
                    if(params.value !== null && params.value !== '' && params.value !== this.$t('label.Tons')){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    } else if (params.value == this.$t('label.Tons')){
                        variable = this.$t('label.Tons');
                    }
                    return variable;
                },
            },
            {
                field: "ImplementXHour",
                headerName: this.$t('label.GamelaxHora'),
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;

                    if(params.value !== null && params.value !== '' && params.value !== this.$t('label.Tons')){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }else if (params.value == this.$t('label.Tons')){
                        variable = this.$t('label.Tons');
                    }
                    return variable;
                },
            },
            {
                field: "TonXHour",
                headerName: this.$t('label.Tons')+'  '+this.$t('label.PerHours'),
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;

                    if(params.value !== null && params.value !== '' && params.value !== this.$t('label.Tons')){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }else if (params.value == this.$t('label.Tons')){
                        variable = this.$t('label.Tons');
                    }
                    return variable;
                },
            },
        ]

      
        return columnDefs;
    }


    function carrierListFormatted(){
        const chart = [
            {
                value: "",
                label: this.$t('label.select'),
            },
        ];
        this.carrierOptions.map((item) => {
            chart.push({
                label: item.ClientName,
                value: item.StowagePlanningResourceId,
            });   
        });
        return chart;    
    }

    export default {
        name: "transport-summary",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getTransportSummary,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            refreshComponent,
            getCarrierList,
        },
        computed:{
            carrierListFormatted,
            columnDefs,
            isTotal,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch, 
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==62) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>