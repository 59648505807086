<template>
    <div>
      <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  
      <CModalExtended
        :title="titleModal"
        color="dark"
        :show.sync="showModalImages"
        :closeOnBackdrop="false"
        size="lg"
        class="modal-extended"
        @update:show="$emit('close');"
      >
     
      <CRow>
        <CCol lg="12" class="text-center" v-show="inspectionImages.length != 0">
          <CCarousel 
            v-if="inspectionImages.length !=0"
            arrows
            animate
            height="400px"
            controls
            ref="myCarousel"
          >            
            <CCarouselItem v-for="item in inspectionImages" :key="item.VisitCargoInspectionPhoto">
              <img :src="checkRoute(item)" width="500" height="400"/>
              
              <div v-show="showButtonDelete" style="text-align: center;">
                <CButton color="wipe" class="m-1" size="sm" style="position: absolute;top: 3%; right: 8%;" @click="deleteImage(item)">
                  <CIcon name="cil-trash" />
                </CButton>
              </div>
            </CCarouselItem>
          </CCarousel>
        </CCol>
        <CCol md="12" lg="12" class="text-center" v-show="inspectionImages.length == 0">
          <CCarousel 
            arrows
            height="400px"
            controls
            class="mb-5"
          >   
          </CCarousel>
        </CCol>
      </CRow>
  
        <template #footer>
          <CButton color="wipe"  @click="$emit('close');">
            <CIcon name="x" />&nbsp; {{$t('button.exit')}}
          </CButton>
        </template>
      </CModalExtended>
    </div>
  </template>
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import General from '@/_mixins/general';
  import inspectionValidation from '@/_validations/inspection/inspectionValidation';
  import { alertPropertiesHelpers } from '@/_helpers/funciones';
  
  function data() {
    return {
      showModalImages: false,
      titleModal: '',
      loadingOverlay: false,
      aux: '',
      
      imageDamage: {
        VisitCargoInspDamageId: '',
        RouteImage: '',
      },
  
      items: [],
      VisitCargoInspDamageId: '',
      showButtonDelete: false,
    };
  }

  function checkRoute(item){
    return this.$store.getters['connection/getBase']  + item.SealRoute.substr(7)
  }

  function resetForm(){
    this.titleModal ='';
  }

  export default {
    name: 'modal-seals-images',
    components: {
    },
    data,
    props: {
      modal: Boolean, 
      inspectionImages: Array,
      labelModal: String,
    },
    mixins: [General],
    directives: UpperCase,
    validations:inspectionValidation,
    methods: {
      checkRoute,
      resetForm,
    },
    watch: {
      modal: function(val){
        this.showModalImages = val; 
        
        if (val) {
          this.titleModal = `${this.$t('label.inspectionSeals')}: ${this.labelModal}`;
        }else{
          this.resetForm();
        };      
      },
    },
  };
  </script>
  <style lang="scss" scope>
  .w-80 {
    width: 80% !important;
  }
  .carousel-control-prev, .carousel-control-next {
    width: 5%;
  }
  .carousel-control-next-icon {
    background-image: url("/img/iconos/NextIcon.svg");
  }
  .carousel-control-prev-icon {
    background-image: url("/img/iconos/PreviousIcon.svg");
  }
  .Z-index{
    position: relative;
    z-index: initial;
  }
  </style>
  