<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
            <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </symbol>
            <symbol id="exclamation-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </symbol>
            <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </symbol>
            <symbol id="transfer-fill" viewBox="0 0 512 512">
                <polygon fill="currentColor" points="356.687 228.687 379.313 251.313 494.627 136 
                    379.313 20.687 356.687 43.313 433.372 120 16 120 16 152 433.372 152 356.687 228.687" class="ci-primary"></polygon>
                <polygon fill="currentColor" points="496 360 78.628 360 155.313 283.313 132.687 260.687 17.373 376 
                    132.687 491.313 155.313 468.687 78.628 392 496 392 496 360" class="ci-primary">
                </polygon>
            </symbol>
            <symbol id="box-fill" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
            </symbol>
        </svg>
        <AppModalMovimientoRestow
            :movimientoOptions="movimientoOptions"
            :containerList="containerList"
            @submited="handleSubmitRestow"
        ></AppModalMovimientoRestow>
        <CRow class="mb-3">
            <CCol sm="11" lg="4" xl="4" class="d-flex align-items-center justify-content-start">
                <h6 class=" mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.listOfGeneralContainers')}}</h6>
            </CCol>
  
            <CCol sm="11" lg="8" xl="8" class="d-flex align-items-center justify-content-end">
                <h6 class=" mr-3 mt-2 d-flex align-items-center"><ul style="margin-bottom: 0px;"><li class="text-purple">S/L/S</li></ul>&nbsp;</h6>
                <h6 class=" mr-3 mt-2 d-flex align-items-center"><ul style="margin-bottom: 0px;"><li class="text-water-green">S/S</li></ul>&nbsp;</h6>
                <h6 class=" mr-3 mt-2 d-flex align-items-center"><ul style="margin-bottom: 0px;"><li class="text-dark-light">CANCEL</li></ul>&nbsp;</h6>
                <h6 class=" mr-3 mt-2 d-flex align-items-center"><ul style="margin-bottom: 0px;"><li class="text-warning">D/D</li></ul>&nbsp;</h6>
                <h6 class="text-danger mr-3 mt-2"><CIcon name='errores' color="wipe" size='lg' class="titulo-icono" />{{$t('label.errors')}}&nbsp;</h6> 
                <h6 class="text-warning mr-3 mt-2"><CIcon name='alertas' color="wipe" size='lg' class="titulo-icono" />{{$t('label.alerts')}}&nbsp;</h6> 
                 <CButton
                    color="excel" class="mr-1" size="sm"
                    @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch" size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.filter')"
                    v-model="textSearch"
                    required 
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :value.sync="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="getContainerList"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class=" mb-2 px-0">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :getRowClass="getRowClass"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnsDefsEspecial"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            rowSelection="single"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            size="xl"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
        >   
            <CRow class="m-2">
                <CCol sm="12" v-if="columnsOption.weigth || columnsOption.length || columnsOption.vgm || columnsOption.height">
                    <h5><CIcon name="cil-search" style="margin-top: -0.2rem !important;"/> {{$t('label.filters').toLowerCase()}}</h5>
                    <CRow>
                        <CCol sm="3" v-if="columnsOption.weigth">
                            <CSelect
                                :label="$t('label.weight')"
                                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                add-label-classes="text-right"
                                v-model="filtroOptions.weigth"
                                :options="optionsListWeight"
                                size="sm"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="3" v-if="columnsOption.length">
                            <CSelect
                                :label="$t('label.length')"
                                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                add-label-classes="text-right"
                                v-model="filtroOptions.lenght"
                                :options="optionsListLongitud"
                                size="sm"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="3" v-if="columnsOption.vgm">
                            <CSelect
                                label="VGM"
                                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                add-label-classes="text-right"
                                size="sm"
                                v-model="filtroOptions.vgm"
                                :options="optionsListWeight"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="3" v-if="columnsOption.height">
                            <CSelect
                                :label="$t('label.height')"
                                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                add-label-classes="text-right"
                                size="sm"
                                v-model="filtroOptions.heigth"
                                :options="optionsListLongitud"
                            >
                            </CSelect>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol sm="12" style="margin-bottom:10px">
                    <CRow>
                        <CCol sm="4">
                            <h5><CIcon name="cil-columns" style="margin-top: -0.2rem !important;"/> {{$t('label.columns').toLowerCase()}}</h5>
                        </CCol>
                        <CCol sm="8" class="justify-content-end">
                            <div class="form-group row d-flex align-items-center">
                                <label for="staticEmail" class="col-sm-11 col-form-label text-right">{{$t('label.selectAll')}}: </label>
                                <div class="col-sm-1">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="changeAll"
                                    />
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="3" md="3" lg="3" xl="3">
                            <CRow>
                                <CCol sm="12" v-if="FgGeneralCargo">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">LOCATION</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.location"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SIZE FT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.size"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">POL</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.pol"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">POD</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.pod"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">WEIGTH</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.weigth"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CARRIER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.carrier"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CARRIER CODE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.shippinglinecode"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">ISO CODE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.container_type"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CLASS</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.class"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HEIGHT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.height"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">STATUS</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.status"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DELIVERY PORT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.delivery"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">OPTIONAL PORT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.opt_port"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">ORIGINAL PORT OF LOADING</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.loading_port"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">TRANSSHIPMENT PORT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.transhipment_port"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HEADING</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.heading"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DESCRIPTION OF GOODS</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.descriptionofgoods"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12" >
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CONFIRMATION</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.ConfirmationDate"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol sm="3" md="3" lg="3" xl="3">
                            <CRow>
                                <CCol sm="12" v-if="FgGeneralCargo">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HOLD</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.hold"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">BOOKING NUMBER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.booking_number"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CONSIGNEE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.consignee"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SHIPPER OWN</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.shipperown"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SHIPPER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.shipper"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">BL No</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.blno"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DUA</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dua"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">COMMODITY</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.commodity"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">COMMODITY CODE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.commodity_code"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">COMMODITY ID NUMBER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.commodity_id_number"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HANDLING REMARK</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.handlink_remark"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CONTAINER REMARK</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.container_remark"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SPECIAL INSTRUCTION</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.special_instruction"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">GENERAL INFO</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.general_info"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">LENGTH</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.length"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">GROUP TYPE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.group_type"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">TYPE CARGO CODE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.tp_cargo_code"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">CONFIRMED</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.FgConfirmed"
                                            />
                                        </div>
                                    </div>
                                </CCol> 
                            </CRow>
                        </CCol>
                        <CCol sm="3" md="3" lg="3" xl="3">
                            <CRow>
                                <CCol sm="12" v-if="FgGeneralCargo">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HATCH COVER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.hatchCover"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">VGM</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.vgm"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DESCRIPTION</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.description"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">REEFER SETTING</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.reefer_setting"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">PLUGGED IN?</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.plugged_in"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">MINIMUM RANGE RF</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.minimun_range_rf"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">MAXIMUM RANGE RF</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.maximun_range_rf"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIM FRONT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.off_standard_dim_front"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIM BACK</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                type="checkbox"
                                                size="sm"
                                                :checked.sync="columnsOption.off_standard_dim_back"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIM LEFT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.off_standard_dim_left"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIM RIGHT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.off_standard_dim_right"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIM TOP</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.off_standard_dim_top"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">IMDG CODE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.imdg_code"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">HAZARD CLASS</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.imdg_class_code"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">IMDG PAGE NO</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.imdg_page_no"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">UN NUMBER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.un_number"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">FLASH POINT</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.flash_point"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">PROCEDENCE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.Procedence"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol sm="3" md="3" lg="3" xl="3">
                            <CRow>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">PACKAGING GROUP</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.packaging_group"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">EMERGENCY SCHEDULE NO</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.emergency_schedule_no"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">MEDICAL FIRST AID GUIDE NO</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.medical_firts_aid_guide_no"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">ORANGE HAZARD PLACE CARD UPPER PART IDENTIFIER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.orange_hazard_place_card_upper_part_identifier"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">ORANGE HAZARD PLACE CARD LOWER PART IDENTIFIER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.orange_hazard_place_card_lower_part_identifier"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DANGEROUS GOOD LABEL 1</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dangerous_good_label_1"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DANGEROUS GOOD LABEL 2</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dangerous_good_label_2"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DANGEROUS GOOD LABEL 3</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dangerous_good_label_3"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DANGEROUS LABEL OPTIONAL</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dangerous_label_optional"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DANGEROUS GOOD ADDITIONAL INFORMATION'S</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.dangerous_good_additional_information"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <!--CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SEAL 1</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.seal1"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SEAL 2</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.seal2"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SEAL 3</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.seal3"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SEAL 4</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.seal4"
                                            />
                                        </div>
                                    </div>
                                </CCol-->
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">SEALS</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.Seals"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">PORT ACTIVITY</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.portActivity"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DEVICE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.DeviceCode"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">USER</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.TransaLogin"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">YARD</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.Yard"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">DIRECT DISCHARGE</label>
                                        <div class="col-sm-3 d-flex align-items-center justify-content-end border-right">
                                            <CSwitch
                                                color="watch"
                                                variant="3d"
                                                size="sm"
                                                type="checkbox"
                                                :checked.sync="columnsOption.DirectDischarge"
                                            />
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        
                    </CRow>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="modalColumn = false"
                    class="m-2"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>



<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { DateFormater, removeTags } from '@/_helpers/funciones';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    import AppModalMovimientoRestow from './modal-movimiento-restow';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            loadingOverlay: false,
            textSearch:'',
            gridOptions: null,
            rows: [],
            SizeChanged:50,
            dataBuque:{
                CountryName:'',
                DatePlanning:'',
                VesselFlagRoute:'',
            },
            movimientoOptions:{
                FgSLS: false, // MOVIMIENTO BUQUE TIERRA BUQUE 
                FgSS: false, //MOVIMIENTO BUQUE A BUQUE
                FgCancel: false,// MOVIMIENTO CANCELADO
            },
            FgSLS: true, // MOVIMIENTO BUQUE TIERRA BUQUE 
            FgSS: false, //MOVIMIENTO BUQUE A BUQUE
            FgCancel: false,
            containerList:[],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                lenght:null,
                heigth:null,    
            },
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            dataWeight:[],
            dataLongitud:[],
            changeAll:true,
            columnsOption:{
                //columna 1
                location: true,
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                carrier:true,
                shippinglinecode:true,
                container_type:true,
                class:true,
                height:true,
                status:true,
                delivery:true,
                opt_port:true,
                loading_port:true,
                transhipment_port:true,
                heading:true,
                //columna 2
                hold: true,
                commodity:true,
                commodity_code:true,
                commodity_id_number:true,
                handlink_remark:true,
                container_remark:true,
                special_instruction:true,
                general_info:true,
                descriptionofgoods:true,
                booking_number:true,
                consignee:true,
                shipperown:true,
                shipper:true,
                blno:true,
                dua:true,
                length:true,
                group_type:true,
                tp_cargo_code:true,
                vgm:true,
                description:true,
                plugged_in:true,
                reefer_setting:true,
                //columna 3
                hatchCover: true,
                minimun_range_rf:true,//38
                maximun_range_rf:true,
                off_standard_dim_front:true,
                off_standard_dim_back:true,
                off_standard_dim_left:true,
                off_standard_dim_right:true,
                off_standard_dim_top:true,
                imdg_code:true,
                imdg_class_code:true,
                imdg_page_no:true,
                un_number:true,
                flash_point:true,
                packaging_group:true,//50
                //columna 4
                emergency_schedule_no:true,
                medical_firts_aid_guide_no:true,
                orange_hazard_place_card_upper_part_identifier:true,
                orange_hazard_place_card_lower_part_identifier:true,
                dangerous_good_label_1:true,
                dangerous_good_label_2:true,
                dangerous_good_label_3:true,
                dangerous_label_optional:true,
                dangerous_good_additional_information:true,
                PackagingGroupId: null,
                seal1: true,
                seal2: true,
                seal3: true,
                seal4: true,
                Seals: true,
                stowageMov: true,
                portActivity: true,
                FgConfirmed: true,
                ConfirmationDate:true,
                DeviceCode: true,
                TransaLogin: true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            preference:1,
            preferenceOptions: [],
            nulo:'', 
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            getRowStyle: params => {
                if (params.data.especial) {
                    return { background: params.data.especial };
                }
            },
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function handleSubmitRestow(data){
        const Index = this.rows.map(function(e) {
            return e.VisitCargoId; 
        }).indexOf(data.VisitCargoId);

        if(Index !== -1){
            this.rows[Index].CargoJson = data.CargoJson;
        }
    }
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    function getSelectedRows() {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const rowData = selectedNodes.map( node => node.data );
        if(rowData.length !== 0){
            this.movimientoOptions.FgSLS = this.FgSLS;
            this.movimientoOptions.FgSS = this.FgSS;
            this.movimientoOptions.FgCancel = this.FgCancel;
            this.containerList = rowData;
            this.$store.state.visitas.modalMovimientoRestow = true;
        }
    }
    function onBtnCsv(){
        const hoy = new Date();
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            try{
                this.$store.state.visitas.apiStateForm = ENUM.LOADING;

                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet("ContainerList");
                var headerRow = worksheet.addRow();
                let columnsDefs = this.gridApi.getColumnDefs();
                let indiceColumns = 1;
                let keyColumns = [];
                this.columnApi.getColumnState().map(function(e){
                    if(typeof e.colId === 'string'){
                        if(indiceColumns > 2) { keyColumns.push(e.colId); }
                        indiceColumns++;
                    }
                });
                indiceColumns = 1;
                let i = 0;
                columnsDefs.map(async (data) => {
                    if(indiceColumns > 2) {
                        /*if(i === 0){
                        worksheet.getColumn(i + 1).width = 10;
                        }else{
                            worksheet.getColumn(i + 1).width = 30;
                        }*/
                        let width = data.headerName ? data.headerName.toString().length : 10;

                        worksheet.getColumn(i + 1).width = width < 10 ? 15 : width*2;
                        let cell = headerRow.getCell(i + 1);

                        if(data.headerName === 'WEIGTH'){
                            cell.value = data.headerName+' ('+this.weigthText+')';
                        }else if(data.headerName === 'VGM'){
                            cell.value = data.headerName+' ('+this.vgmText+')';
                        }else if(data.headerName === 'LENGHT'){
                            cell.value = data.headerName+' ('+this.lenghtText+')';
                        }else if(data.headerName === 'HEIGHT'){
                            cell.value = data.headerName+' ('+this.heigthText+')';
                        }else{
                            cell.value = data.headerName;
                        }
                        
                        cell.alignment = { vertical: 'middle', horizontal: 'center' }
                        i++;
                    }
                    
                    indiceColumns++;
                })

                this.formatedItems.map(async function(data){
                    let dataRow = worksheet.addRow();
                    let indice = 0;
                    keyColumns.map(function(data2, index2){
                        let cell = dataRow.getCell(indice + 1);
                        if(typeof data[data2] === 'boolean'){
                            cell.value = data[data2] ? 'YES' : 'NO';
                        }else{
                            cell.value = data[data2];
                        }
                        
                        indice++;
                    })
                })
                
                // save under export.xlsx
                workbook.csv.writeBuffer()
                .then(buffer => {
                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                    let time = hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
                    let today = DateFormater.formatOnlyDateWithSlash(new Date());
                    today = today.replaceAll("/", "");
                    let titulo = (`ContainerList${today}${time}.csv`).replaceAll(':','');
                    saveAs(new Blob([buffer]), titulo)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
                .catch(err => {
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                })
            } catch (error) {
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                this.$store.commit('visitas/messageMutation', error);
            }
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
    }

    async function onBtnExport(valor) {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;

        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
             await this.getExcel(rowData,this.$t('label.containersList'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }

    function getContainerList() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        let CargoListJson = {
            VisitId: this.Visit,
            CompanyBranchId: this.branch.CompanyBranchId,
            UserPreference: this.preference
        };

        this.$http.post('VisitCargo-list', CargoListJson, { root: 'CargoListJson'} )
        .then(response => {
            this.getPreferenceList();
            this.$http.ejecutar('GET', 'UnitMeasureWeigth-list', {
            }).then(responseWeigth => {
                this.$http.ejecutar('GET', 'UnitMeasureLongitude-list', {
                }).then(responseLongitude => {
                        this.dataWeight = responseWeigth.data.data;
                        this.dataLongitud = responseLongitude.data.data;
                        

                        this.filtroOptions.weigth = this.dataWeight[0].UnitMeasureId;
                        this.filtroOptions.vgm = this.dataWeight[0].UnitMeasureId;

                        this.filtroOptions.lenght = this.dataLongitud[0].UnitMeasureId;
                        this.filtroOptions.heigth = this.dataLongitud[0].UnitMeasureId;

                        this.rows = response.data.data;
                        this.nulo = String(response.data.data[0].Json);

                        this.$store.state.visitas.apiStateForm = ENUM.INIT;
                    }).catch( err => {
                        this.$store.state.visitas.messageError = err;
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                    });
            }).catch( err => {
                this.$store.state.visitas.messageError = err;
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            });
        }).catch( err => {
            this.$store.state.visitas.messageError = err;
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
        });
    }
    function filtroOpciones(data,value){
        if(value){
            let index = data.map(function(e) {
                return e.UnitMeasureId; 
            }).indexOf(value);
            if(index !== -1){
                return data[index].Value;
            }
            return 0;
        }else if(value === null && data.length !== 0){
            return data[0].Value;
        }
        return 0;
    }

    function getRowClass(params) {
        if ((params.node.data.FgSLS==false && params.node.data.FgSS==false) && (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES")) {
            return 'bg-confirmed'
        }
    }

    function getPreferenceList() {
    // this.isLoading = true;
        this.$http.get("UserPreference-list")
        .then(response => {
            
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
        // this.isLoading = false;
        });
    }
//computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                maxWidth:90,
                minWidth: 90,
                width: 90,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
                //pinned: 'left',
            },
        ]
        if (!this.FgGeneralCargo) {
            columnDefs.push({
                field: "bay",
                headerName: "BAY",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                width: 150,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "slot",
                headerName: "SLOT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                width: 150,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "siglas",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                resizable: true,
                cellClass: 'gb-cell',
                //pinned: 'left',
            });
        }else{
            columnDefs.push({
                field: "siglas",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                resizable: true,
                cellClass: 'gb-cell',
                //pinned: 'left',
            });
        }
        if(this.FgGeneralCargo && this.columnsOption.location){
            columnDefs.push({
                field: "VesselUbicationName",
                headerName: "LOCATION",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.FgGeneralCargo && this.columnsOption.hold){
            columnDefs.push({
                field: "VesselHoldName",
                headerName: "HOLD",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.FgGeneralCargo && this.columnsOption.hatchCover){
            columnDefs.push({
                field: "HatchCoverName",
                headerName: "HATCH COVER",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.columnsOption.size){
            columnDefs.push({
                field: "size",
                headerName: "SIZE (FT)",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.columnsOption.container_type){
            columnDefs.push({
                field: "container_type",
                headerName: "ISO CODE",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
      /*  if(this.columnsOption.seal1){
            columnDefs.push({
                field: "seal1",
                headerName: "SEAL 1", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.seal2){
            columnDefs.push({
                field: "seal2",
                headerName: "SEAL 2", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.seal3){
            columnDefs.push({
                field: "seal3",
                headerName: "SEAL 3", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.seal4){
            columnDefs.push({
                field: "seal4",
                headerName: "SEAL 4", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        } */
        if(this.columnsOption.Seals){
            columnDefs.push({
                field: "Seals",
                headerName: "SEALS", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.status){
            columnDefs.push({
                field: "status",
                headerName: "STATUS",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        } 
        if(this.columnsOption.shippinglinecode){
            columnDefs.push({
                field: "shippinglinecode",
                headerName: "CARRIER CODE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.carrier){
            columnDefs.push({
                field: "carrier",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.imdg_code){
            columnDefs.push({
                field: "imdg_code",
                headerName: "IMDG CODE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.pol){
            columnDefs.push({
                field: "pol",
                headerName: "LOAD PORT",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.pod){
            columnDefs.push({
                field: "pod",
                headerName: "DISCHARGE PORT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.delivery){
            columnDefs.push({
                field: "delivery",
                headerName: "DELIVERY PORT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.opt_port){
            columnDefs.push({
                field: "optport",
                headerName: "OPTIONAL PORT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.loading_port){
            columnDefs.push({
                field: "loading_port",
                headerName: "ORIGINAL PORT OF LOADING", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.transhipment_port){
            columnDefs.push({
                field: "transhipment_port",
                headerName: "TRANSSHIPMENT PORT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.tp_cargo_code){
            columnDefs.push({
                field: "tp_cargo_code",
                headerName: "TYPE CARGO CODE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.weigth){
            columnDefs.push({
                field: "weigth",
                headerName: "WEIGTH"+' ('+this.weigthText+')',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agNumberColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.height){
            columnDefs.push({
                field: "height",
                headerName: "HEIGHT"+' ('+this.heigthText+')',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agNumberColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.class){
            columnDefs.push({
                field: "class",
                headerName: "CLASS",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        
        //columna 2
        if(this.columnsOption.heading){
            columnDefs.push({
                field: "heading",
                headerName: "HEADING", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.commodity){
            columnDefs.push({
                field: "commodity",
                headerName: "COMMODITY", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.commodity_code){
            columnDefs.push({
                field: "commodity_code",
                headerName: "COMMODITY CODE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.commodity_id_number){
            columnDefs.push({
                field: "commodity_id_number",
                headerName: "COMMODITY ID NUMBER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.handlink_remark){
            columnDefs.push({
                field: "handlink_remark",
                headerName: "HANDLING REMARK", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.container_remark){
            columnDefs.push({
                field: "container_remark",
                headerName: "CONTAINER REMARK", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.special_instruction){
            columnDefs.push({
                field: "special_instruction",
                headerName: "SPECIAL INSTRUCTION", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.general_info){
            columnDefs.push({
                field: "general_info",
                headerName: "GENERAL INFO", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.descriptionofgoods){
            columnDefs.push({
                field: "descriptionofgoods",
                headerName: "DESCRIPTION OF GOODS", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.booking_number){
            columnDefs.push({
                field: "booking_number",
                headerName: "BOOKING NUMBER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.consignee){
            columnDefs.push({
                field: "consignee",
                headerName: "CONSIGNEE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.shipperown){
            columnDefs.push({
                field: "shipperown",
                headerName: "SHIPPER OWN", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.shipper){
            columnDefs.push({
                field: "shipper",
                headerName: "SHIPPER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.blno){
            columnDefs.push({
                field: "blno",
                headerName: "BL No", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dua){
            columnDefs.push({
                field: "dua",
                headerName: "DUA", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.length){
            columnDefs.push({
                field: "length",
                headerName: "LENGHT"+' ('+this.lenghtText+')', 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agNumberColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.group_type){
            columnDefs.push({
                field: "group_type",
                headerName: "GROUP TYPE", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.vgm){
            columnDefs.push({
                field: "vgm",
                headerName: "VGM"+' ('+this.vgmText+')', 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agNumberColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.description){
            columnDefs.push({
                field: "description",
                headerName: "DESCRIPTION", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.reefer_setting){
            columnDefs.push({
                field: "reefer_setting",
                headerName: "REEFER SETTING", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.plugged_in){
            columnDefs.push({
                field: "plugged_in_text",
                headerName: "PLUGGED IN?",
                filter: "agTextColumnFilter",
                sortable: true,
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.columnsOption.minimun_range_rf){
            columnDefs.push({
                field: "minimun_range_rf",
                headerName: "MINIMUM RANGE RF", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.maximun_range_rf){
            columnDefs.push({
                field: "maximun_range_rf",
                headerName: "MAXIMUM RANGE RF", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        //COLUMNA 3
        if(this.columnsOption.off_standard_dim_front){
            columnDefs.push({
                field: "off_standard_dim_front",
                headerName: "DIM FRONT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.off_standard_dim_back){
            columnDefs.push({
                field: "off_standard_dim_back",
                headerName: "DIM BACK", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.off_standard_dim_left){
            columnDefs.push({
                field: "off_standard_dim_left",
                headerName: "DIM LEFT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.off_standard_dim_right){
            columnDefs.push({
                field: "off_standard_dim_right",
                headerName: "DIM RIGHT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.off_standard_dim_top){
            columnDefs.push({
                field: "off_standard_dim_top",
                headerName: "DIM TOP", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }

        if(this.columnsOption.imdg_class_code){
            columnDefs.push({
                field: "imdg_class_code",
                headerName: "HAZARD CLASS", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.imdg_page_no){
            columnDefs.push({
                field: "imdg_page_no",
                headerName: "IMDG PAGE NO", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.un_number){
            columnDefs.push({
                field: "un_number",
                headerName: "UN NUMBER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.flash_point){
            columnDefs.push({
                field: "flash_point",
                headerName: "FLASH POINT", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.packaging_group){
            columnDefs.push({
                field: "packaging_group",
                headerName: "PACKAGING GROUP", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.emergency_schedule_no){
            columnDefs.push({
                field: "emergency_schedule_no",
                headerName: "EMERGENCY SCHEDULE NO", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.medical_firts_aid_guide_no){
            columnDefs.push({
                field: "medical_firts_aid_guide_no",
                headerName: "MEDICAL FIRST AID GUIDE NO", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        //COLUMNA 4
        if(this.columnsOption.orange_hazard_place_card_upper_part_identifier){
            columnDefs.push({
                field: "orange_hazard_place_card_upper_part_identifier",
                headerName: "ORANGE HAZARD PLACE CARD UPPER PART IDENTIFIER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.orange_hazard_place_card_lower_part_identifier){
            columnDefs.push({
                field: "orange_hazard_place_card_lower_part_identifier",
                headerName: "ORANGE HAZARD PLACE CARD LOWER PART IDENTIFIER", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dangerous_good_label_1){
            columnDefs.push({
                field: "dangerous_good_label_1",
                headerName: "DANGEROUS GOOD LABEL 1", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dangerous_good_label_2){
            columnDefs.push({
                field: "dangerous_good_label_2",
                headerName: "DANGEROUS GOOD LABEL 2", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dangerous_good_label_3){
            columnDefs.push({
                field: "dangerous_good_label_3",
                headerName: "DANGEROUS GOOD LABEL 3", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dangerous_label_optional){
            columnDefs.push({
                field: "dangerous_label_optional",
                headerName: "DANGEROUS LABEL OPTIONAL", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.dangerous_good_additional_information){
            columnDefs.push({
                field: "dangerous_good_additional_information",
                headerName: "DANGEROUS GOOD ADDITIONAL INFORMATION'S", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        

        if(this.columnsOption.stowageMov){
            columnDefs.push({
                field: "stowageMov",
                headerName: this.$t('label.stowageMovement'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
            });
        }
        if(this.columnsOption.portActivity){
            columnDefs.push({
                field: "portActivity",
                headerName: this.$t('label.portActivity'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
            });
        }

        if(this.columnsOption.FgConfirmed){
            columnDefs.push({
                field: "FgConfirmed",
                headerName: "CONFIRMED", 
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.ConfirmationDate){
            columnDefs.push({
                field: "ConfirmationDate",
                headerName: "CONFIRMATION",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DeviceCode){
            columnDefs.push({
                field: "DeviceCode",
                headerName: "DEVICE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.TransaLogin){
            columnDefs.push({
                field: "TransaLogin",
                headerName: "USER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        
        return columnDefs;
    }
    function columnsDefsEspecial(){
        let columnDefsEspe = [{
            field: "messagesContent",
            headerName: "",
            filter:false,
            lockPosition: true,
            suppressMovable: true,
            minWidth: 90,
            width: 90,
            autoHeight: true,
            cellClass: 'gb-cell',
            //pinned: 'left',
            cellRenderer: params => {
                let textAlert = '';
                let textError = '';
                if(params.value.Error.length !== 0){
                    textError = `<h6 class="text-danger"><svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Danger:"><use xlink:href="#exclamation-circle-fill"/></svg>&nbsp; ${params.value.Error.length}</h6>`
                }
                if(params.value.Alert.length !== 0){
                    textAlert = `<h6 class="text-warning"><svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Info:"><use xlink:href="#exclamation-triangle-fill"/></svg>&nbsp; ${params.value.Alert.length}</h6>`
                    if(textError !== ''){   
                        textAlert = textError.concat('', textAlert);
                    }
                }
                return textAlert === '' ? (textError === '' ? '' : textError) : textAlert;
            }
        }]
        columnDefsEspe.push(
            {
                field: 'especial',
                headerName: "hola",
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
                    '    <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Danger:"><use xlink:href="#transfer-fill"/></svg>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>'
                },
                filter:false,
                lockPosition: true,
                suppressMovable: true,
                maxWidth:55,
                minWidth: 55,
                width: 55,
                autoHeight: true,
                cellClass: 'gb-cell',
                //pinned: 'left',
                cellRenderer: params => {
                    return '';
                },
                checkboxSelection: true
                
            },
        )
        return [].concat(columnDefsEspe, this.columnDefs);
    }
    function formatedItems() {
        let computedItems = [];
        let _this = this;
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map(async (item,index) =>{
                if(item.CargoJson !== null && item.CargoJson !== undefined){
                    if(item.CargoJson.length !== 0){     
                        //Weigth: Weigth  Y VGM
                        let weigth = _this.filtroOpciones(item.CargoJson[0].WeigthJson,_this.filtroOptions.weigth);
                        let vgm = _this.filtroOpciones(item.CargoJson[0].VgmJson,_this.filtroOptions.vgm);
                        //longitud
                        let lenght = _this.filtroOpciones(item.CargoJson[0].LenghtJson,_this.filtroOptions.lenght);
                        let heigth = _this.filtroOpciones(item.CargoJson[0].HeightJson,_this.filtroOptions.heigth);

                        let messagesContent = {
                            Alert:item.CargoJson[0].AlertJson ? item.CargoJson[0].AlertJson : [],
                            Error: item.CargoJson[0].ErrorJson ? item.CargoJson[0].ErrorJson : [],
                        }

                        let stowageMov = this.$i18n.locale == "es" ? item.MovStowageNameEs : item.MovStowageNameEs;
                        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

                        computedItems.push({
                            id: index+1,
                            especial: item.CargoJson[0].ColorTransacStowage ? item.CargoJson[0].ColorTransacStowage : "#ffffff",
                            VisitCargoId: item.VisitCargoId ? item.VisitCargoId : '',
                            bay: item.BayCode ? item.BayCode : '',
                            slot: item.CodPosition ? item.CodPosition : '',
                            siglas: item.ContainerCode ? item.ContainerCode : '',
                            stowageMov : stowageMov ? stowageMov : '',
                            portActivity: item.PortActivityName ? item.PortActivityName : '',
                            VesselUbicationName: item['VesselUbicationName'+_lang] ?? '',
                            VesselHoldName: item.VesselHoldName ?? '',
                            HatchCoverName: item.HatchCoverName ?? '',
                            sizeid: item.CargoJson[0].SizeId ? item.CargoJson[0].SizeId : null,
                            size: item.CargoJson[0].TpCargoDetailCode ? item.CargoJson[0].TpCargoDetailCode : '',
                            sizeModal: item.CargoJson[0].Size ? item.CargoJson[0].Size : '',
                            polid: item.CargoJson[0].LoadPortId ? item.CargoJson[0].LoadPortId : null,
                            pol: item.CargoJson[0].LoadPort ? item.CargoJson[0].LoadPort : '',
                            podid: item.CargoJson[0].DischargePortId ? item.CargoJson[0].DischargePortId : null,
                            pod: item.CargoJson[0].DischargePort ? item.CargoJson[0].DischargePort : '',
                            weigthid: _this.filtroOptions.weigth ? _this.filtroOptions.weigth : null,
                            weigth: weigth ? weigth : 0, //holi
                            carrier: item.CargoJson[0].ShippingLineName ? item.CargoJson[0].ShippingLineName : '',
                            shippinglinecode: item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '',
                            container_type: item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '',
                            class: item.CargoJson[0].Class ? item.CargoJson[0].Class : '',
                            heightid: _this.filtroOptions.heigth ? _this.filtroOptions.heigth : null,
                            height: heigth ? heigth : 0, //holi
                            statusid: item.CargoJson[0].TpCargoStatusId ? item.CargoJson[0].TpCargoStatusId : null,
                            status: item.CargoJson[0].TpCargoStatusName ? item.CargoJson[0].TpCargoStatusName : '',
                            deliveryid: item.CargoJson[0].DeliveryPortId ? item.CargoJson[0].DeliveryPortId : null,
                            delivery: item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '',
                            optportid: item.CargoJson[0].OptionalPortId ? item.CargoJson[0].OptionalPortId : null,
                            optport: item.CargoJson[0].OptionalPort ? item.CargoJson[0].OptionalPort : '',
                            loading_portid: item.CargoJson[0].OriginalPortLoadingId ? item.CargoJson[0].OriginalPortLoadingId : null,
                            loading_port: item.CargoJson[0].OriginalPortLoading ? item.CargoJson[0].OriginalPortLoading : '',
                            transhipment_portid: item.CargoJson[0].TranshipmentPortId ? item.CargoJson[0].TranshipmentPortId : null,
                            transhipment_port: item.CargoJson[0].TranshipmentPort ? item.CargoJson[0].TranshipmentPort : '',
                            //columna 2
                            heading: item.CargoJson[0].HeadingName ? item.CargoJson[0].HeadingName : '',
                            headingid: item.CargoJson[0].HeadingId ? item.CargoJson[0].HeadingId : null,
                            commodityid: item.CargoJson[0].CommodityId ? item.CargoJson[0].CommodityId : null,
                            commodity: item.CargoJson[0].CommodityName ? item.CargoJson[0].CommodityName : '',
                            commodity_code: item.CargoJson[0].CommodityCode ? item.CargoJson[0].CommodityCode : '',
                            commodity_id_number: item.CargoJson[0].CommodityIdNumber ? item.CargoJson[0].CommodityIdNumber : '',
                            handlink_remark: item.CargoJson[0].HandlingRemark ? item.CargoJson[0].HandlingRemark : '',
                            container_remark: item.CargoJson[0].ContainerRemark ? item.CargoJson[0].ContainerRemark : '',
                            special_instruction: item.CargoJson[0].SpecialInstruction ? item.CargoJson[0].SpecialInstruction : '',
                            general_info: item.CargoJson[0].GeneralInfo ? item.CargoJson[0].GeneralInfo : '',
                            descriptionofgoods: item.CargoJson[0].DescriptionOfGoods ? removeTags(item.CargoJson[0].DescriptionOfGoods) : '',
                            booking_number: item.CargoJson[0].BookingNumber ? item.CargoJson[0].BookingNumber : '',
                            consignee: item.CargoJson[0].Consignee ? item.CargoJson[0].Consignee : '',
                            shipperown: item.CargoJson[0].ShipperOwn ? item.CargoJson[0].ShipperOwn : '',
                            shipper: item.CargoJson[0].Shipper ? item.CargoJson[0].Shipper : '',
                            blno: item.CargoJson[0].BlNo ? item.CargoJson[0].BlNo : '',
                            dua: item.CargoJson[0].Dua ? item.CargoJson[0].Dua : '',
                            lengthid: _this.filtroOptions.lenght ? _this.filtroOptions.lenght : null,
                            length: lenght ? lenght : 0, //holi
                            group_type: item.CargoJson[0].TpCargoName ? item.CargoJson[0].TpCargoName : '',
                            tp_cargo_code: item.CargoJson[0].TpCargoCode ? item.CargoJson[0].TpCargoCode : '',
                            vgmid: _this.filtroOptions.vgm ? _this.filtroOptions.vgm : null,
                            vgm: vgm ? vgm : 0, //holi
                            description: item.CargoJson[0].DescriptionType ? item.CargoJson[0].DescriptionType : '', //pruebas
                            reefer_setting: item.CargoJson[0].ReeferSetting ? item.CargoJson[0].ReeferSetting : '',
                            dry_refer: item.CargoJson[0].DryReefer !== null ? item.CargoJson[0].DryReefer : false,
                            minimun_range_rf: item.CargoJson[0].MinimumRangeRf ? item.CargoJson[0].MinimumRangeRf : '',
                            maximun_range_rf: item.CargoJson[0].MaximumRangeRf ? item.CargoJson[0].MaximumRangeRf : '',
                            //Columna 3
                            off_standard_dim_front: item.CargoJson[0].DimFront ? item.CargoJson[0].DimFront : 0,
                            off_standard_dim_back: item.CargoJson[0].DimBack ? item.CargoJson[0].DimBack : 0,
                            off_standard_dim_left: item.CargoJson[0].DimLeft ? item.CargoJson[0].DimLeft : 0,
                            off_standard_dim_right: item.CargoJson[0].DimRight ? item.CargoJson[0].DimRight : 0,
                            off_standard_dim_top: item.CargoJson[0].DimTop ? item.CargoJson[0].DimTop : 0,
                            imdg_code: item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '',
                            imdg_class_code : item.CargoJson[0].ImdgClassCode ? item.CargoJson[0].ImdgClassCode : '',
                            imdg_page_no: item.CargoJson[0].ImgdPageNo ? item.CargoJson[0].ImgdPageNo : '',
                            packaging_group_id: item.CargoJson[0].PackagingGroupId ? item.CargoJson[0].PackagingGroupId : null,
                            packaging_group: item.CargoJson[0].PackagingGroupCode ? item.CargoJson[0].PackagingGroupCode : '',
                            un_number: item.CargoJson[0].UnNumber ? item.CargoJson[0].UnNumber : '',
                            flash_point: item.CargoJson[0].FlashPoint ? item.CargoJson[0].FlashPoint : '',
                            emergency_schedule_no: item.CargoJson[0].EmergencySheduleNo ? item.CargoJson[0].EmergencySheduleNo : '',
                            medical_firts_aid_guide_no: item.CargoJson[0].MedicalGuide ? item.CargoJson[0].MedicalGuide : '',
                            //Columna 4:
                            orange_hazard_place_card_upper_part_identifier: item.CargoJson[0].ImdgCodePlaceCardLower ? item.CargoJson[0].ImdgCodePlaceCardLower : '',
                            orange_hazard_place_card_lower_part_identifier: item.CargoJson[0].ImdgCodePlaceCardUpper ? item.CargoJson[0].ImdgCodePlaceCardUpper : '',
                            dangerous_good_label_1: item.CargoJson[0].DangerousLabel1 ? item.CargoJson[0].DangerousLabel1 : '',
                            dangerous_good_label_2: item.CargoJson[0].DangerousLabel2 ? item.CargoJson[0].DangerousLabel2 : '',
                            dangerous_good_label_3: item.CargoJson[0].DangerousLabel3 ? item.CargoJson[0].DangerousLabel3 : '',
                            dangerous_label_optional: item.CargoJson[0].DangerousLabelOptional ? item.CargoJson[0].DangerousLabelOptional : '',
                            dangerous_good_additional_information: item.CargoJson[0].DescriptionOfGoodsDGS ? item.CargoJson[0].DescriptionOfGoodsDGS : '',
                            seal1: item.CargoJson[0].Seal1 ? item.CargoJson[0].Seal1 : '',
                            seal2: item.CargoJson[0].Seal2 ? item.CargoJson[0].Seal2 : '',
                            seal3: item.CargoJson[0].Seal3 ? item.CargoJson[0].Seal3 : '',
                            seal4: item.CargoJson[0].Seal4 ? item.CargoJson[0].Seal4 : '',
                            Seals: item.CargoJson[0].Seals ? item.CargoJson[0].Seals : '',
                            FgConfirmed: item.FgConfirmed === true ? _this.$t('label.yes') : 'NO',
                            ConfirmationDate: item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '',
                            DeviceCode: item.DeviceCode ? item.DeviceCode : '',
                            TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                            FgSLS: item.CargoJson[0].FgSLS,
                            FgSS: item.CargoJson[0].FgSS,

                            //optional
                            FgApplyOversize: item.CargoJson[0].FgApplyOversize,
                            FgApplySeal: item.CargoJson[0].FgApplySeal,
                            FgApplyRefrigeration: item.CargoJson[0].FgApplyRefrigeration,

                            plugged_in: item.CargoJson[0].PluggedIn === 1 ? true : false,
                            plugged_in_text: item.CargoJson[0].PluggedIn === 1 ? _this.$t('label.yes') : 'NO',

                            VesselBayId: item.CargoJson[0].VesselBayId ? item.CargoJson[0].VesselBayId : null,
                            VesselBayPosId: item.CargoJson[0].VesselBayPosId ? item.CargoJson[0].VesselBayPosId : null,
                            TpCargoClassId: item.CargoJson[0].TpCargoClassId ? item.CargoJson[0].TpCargoClassId : null,
                            ShippingLineId: item.CargoJson[0].ShippingLineId ? item.CargoJson[0].ShippingLineId : null,
                            TpCargoId: item.CargoJson[0].TpCargoId ? item.CargoJson[0].TpCargoId : null,
                            MetadataId: item.CargoJson[0].MetadataId ? item.CargoJson[0].MetadataId : null,
                            ImdgClassId: item.CargoJson[0].ImdgClassId ? item.CargoJson[0].ImdgClassId : null,
                            ImdgId: item.CargoJson[0].ImdgId ? item.CargoJson[0].ImdgId : null,
                            UnitMeasureFlashPointId: item.CargoJson[0].UnitMeasureFlashPointId ? item.CargoJson[0].UnitMeasureFlashPointId : null,

                            messagesContent:messagesContent,

                            UnitMeasureRfId: item.CargoJson[0].UnitMeasureRfId ? item.CargoJson[0].UnitMeasureRfId : null,

                            //position
                            MovStowageReasonDs: item.CargoJson[0].MovStowageReasonDs ? item.CargoJson[0].MovStowageReasonDs : null,
                            MovStowageReasonId: item.CargoJson[0].MovStowageReasonId ? item.CargoJson[0].MovStowageReasonId : null,
                            MovStowageReasonName: item.CargoJson[0].MovStowageReasonName ? item.CargoJson[0].MovStowageReasonName : null,
                            BaySlot: item.CargoJson[0].BaySlot ? item.CargoJson[0].BaySlot : null,
                            PreviusPosition: item.CargoJson[0].PreviusPosition ? item.CargoJson[0].PreviusPosition : null,
                            NewPosition: item.CargoJson[0].NewPosition ? item.CargoJson[0].NewPosition : null,

                            Procedence: item.Procedence,
                            YardNamePlanning: item.YardNamePlanning, 
                            FgDirect: item.FgDirect? 'YES' : 'NO'
                        })

                    }
                }
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }
    function weigthText(){
        if(this.filtroOptions.weigth === '' || this.filtroOptions.weigth === null ){
            return 'KGM';
        }else{
            let text = '';
            this.dataWeight.map(async (e) => {
                if(this.filtroOptions.weigth === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function vgmText(){
        if(this.filtroOptions.vgm === '' || this.filtroOptions.vgm === null ){
            return 'KGM';
        }else{
            let text = '';
            this.dataWeight.map(async (e) => {
                if(this.filtroOptions.vgm === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function lenghtText(){
        if(this.filtroOptions.lenght === '' || this.filtroOptions.lenght === null ){
            return 'CM';
        }else{
            let text = '';
            this.dataLongitud.map(async (e) => {
                if(this.filtroOptions.lenght === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function heigthText(){
        if(this.filtroOptions.heigth === '' || this.filtroOptions.heigth === null ){
            return 'CM';
        }else{
            let text = '';
            this.dataLongitud.map(async (e) => {
                if(this.filtroOptions.heigth === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function optionsListWeight() {
        if (this.dataWeight.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.dataWeight.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListLongitud() {
        if (this.dataLongitud.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.dataLongitud.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    function dropItemReport(newQuestion){
        if(newQuestion === 23){ 
            this.preference = 1; 
            this.SizeChanged = 50;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            this.changeAll = true;
            this.gridApi.paginationSetPageSize(parseInt(50));
            this.getContainerList();
            this.$emit('child-refresh',true);
        }
    }
    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }
    function changeAll(newQuestion){
        //columna 1
        this.columnsOption.location=newQuestion;
        this.columnsOption.size=newQuestion;
        this.columnsOption.pol=newQuestion;
        this.columnsOption.pod=newQuestion;
        this.columnsOption.weigth=newQuestion;
        this.columnsOption.carrier=newQuestion;
        this.columnsOption.shippinglinecode=newQuestion;
        this.columnsOption.container_type=newQuestion;
        this.columnsOption.class=newQuestion;
        this.columnsOption.height=newQuestion;
        this.columnsOption.status=newQuestion;
        this.columnsOption.delivery=newQuestion;
        this.columnsOption.opt_port=newQuestion;
        this.columnsOption.loading_port=newQuestion;
        this.columnsOption.transhipment_port=newQuestion;
        this.columnsOption.heading=newQuestion;
        //columna 2
        this.columnsOption.hold=newQuestion;
        this.columnsOption.commodity=newQuestion;
        this.columnsOption.commodity_code=newQuestion;
        this.columnsOption.commodity_id_number=newQuestion;
        this.columnsOption.handlink_remark=newQuestion;
        this.columnsOption.container_remark=newQuestion;
        this.columnsOption.special_instruction=newQuestion;
        this.columnsOption.general_info=newQuestion;
        this.columnsOption.descriptionofgoods=newQuestion;
        this.columnsOption.booking_number=newQuestion;
        this.columnsOption.consignee=newQuestion;
        this.columnsOption.shipperown=newQuestion;
        this.columnsOption.shipper=newQuestion;
        this.columnsOption.blno=newQuestion;
        this.columnsOption.dua=newQuestion;
        this.columnsOption.length=newQuestion;
        this.columnsOption.group_type=newQuestion;
        this.columnsOption.tp_cargo_code=newQuestion;
        this.columnsOption.vgm=newQuestion;
        this.columnsOption.description=newQuestion;
        this.columnsOption.plugged_in=newQuestion;
        this.columnsOption.reefer_setting=newQuestion;
        //columna 3
        this.columnsOption.hatchCover=newQuestion;
        this.columnsOption.minimun_range_rf=newQuestion;//38
        this.columnsOption.maximun_range_rf=newQuestion;
        this.columnsOption.off_standard_dim_front=newQuestion;
        this.columnsOption.off_standard_dim_back=newQuestion;
        this.columnsOption.off_standard_dim_left=newQuestion;
        this.columnsOption.off_standard_dim_right=newQuestion;
        this.columnsOption.off_standard_dim_top=newQuestion;
        this.columnsOption.imdg_code=newQuestion;
        this.columnsOption.imdg_class_code=newQuestion;
        this.columnsOption.imdg_page_no=newQuestion;
        this.columnsOption.un_number=newQuestion;
        this.columnsOption.flash_point=newQuestion;
        this.columnsOption.packaging_group=newQuestion;//50
        //columna 4
        this.columnsOption.emergency_schedule_no=newQuestion;
        this.columnsOption.medical_firts_aid_guide_no=newQuestion;
        this.columnsOption.orange_hazard_place_card_upper_part_identifier=newQuestion;
        this.columnsOption.orange_hazard_place_card_lower_part_identifier=newQuestion;
        this.columnsOption.dangerous_good_label_1=newQuestion;
        this.columnsOption.dangerous_good_label_2=newQuestion;
        this.columnsOption.dangerous_good_label_3=newQuestion;
        this.columnsOption.dangerous_label_optional=newQuestion;
        this.columnsOption.dangerous_good_additional_information=newQuestion;
        //this.columnsOption.PackagingGroupId= null;
        /*this.columnsOption.seal1= newQuestion;
        this.columnsOption.seal2= newQuestion;
        this.columnsOption.seal3= newQuestion;
        this.columnsOption.seal4= newQuestion; */
        this.columnsOption.Seals= newQuestion;
        this.columnsOption.FgConfirmed= newQuestion;
        this.columnsOption.ConfirmationDate= newQuestion;
        this.columnsOption.DeviceCode= newQuestion;
        this.columnsOption.TransaLogin= newQuestion;
        this.columnsOption.portActivity= newQuestion;
        this.columnsOption.Yard= newQuestion;
        this.columnsOption.Procedence= newQuestion;
        this.columnsOption.DirectDischarge= newQuestion;
    }

    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    export default {
        name: "container-list",
        data,
        beforeMount,
        mixins: [Reportes, AgGrid],
        methods:{
            getContainerList,
            filtroOpciones,
            getSelectedRows,
            onBtnCsv,
            onBtnExport,
            onGridReady,
            handleSubmitRestow,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            getPreferenceList,
        },
        computed:{
            columnDefs,
            columnsDefsEspecial,
            formatedItems,
            optionsListWeight,
            optionsListLongitud,
            weigthText,
            vgmText,
            lenghtText,
            heigthText,
            ...mapState({
                user: state => state.auth.user,
                branch: state => state.auth.branch,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,
                itinerarySelected: state => state.visitas.itinerarySelected,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,
            }),
            preferenceListFormatted
        },
        components:{
            AppModalMovimientoRestow,
        },
        watch:{
            changeAll,
            dropItemReport,
            textSearch,
            SizeChanged,
            FgSLS(newValue){
                if(newValue){
                    this.FgSS= false;
                    this.FgCancel= false;
                }
            },
            FgSS(newValue){
                if(newValue){
                    this.FgSLS= false;
                    this.FgCancel= false;
                }
            },
            FgCancel(newValue){
                if(newValue){
                    this.FgSS= false;
                    this.FgSLS= false;
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }   
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
    .bg-confirmed{
        background: #7bdf9b !important;
    }
</style>