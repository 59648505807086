import { render, staticRenderFns } from "./truck-scale.vue?vue&type=template&id=71b1b5ab&"
import script from "./truck-scale.vue?vue&type=script&lang=js&"
export * from "./truck-scale.vue?vue&type=script&lang=js&"
import style0 from "@/pages/visita/tabs/reporte/css/reporte.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./truck-scale.vue?vue&type=style&index=1&id=71b1b5ab&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports