<template>
    <div class="container-fluid ">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfCanceled') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm" 
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>   
        <CRow class="mb-3">
            <CCol sm="11" lg="3" xl="3" class="center-field ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getCanceledList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>    
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-3 text-right', input: 'col-sm-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"    
                    v-model="search"        
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field ">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class=" justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
        >   
            <CRow>
                <CCol sm="6" v-if="FgGeneralCargo">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">LOCATION</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.location"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" v-if="FgGeneralCargo">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">HOLD</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.hold"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" v-if="FgGeneralCargo">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">HATCH COVER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.hatchCover"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">LOAD PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.LoadPort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DISCHARGE PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DischargePort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl- 8 text-right">WEIGHT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Weigth"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">CARRIER CODE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLineCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8  text-right">CARRIER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4  d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLine"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">IMDG CODE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ImdgCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">UN NUMBER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnNumber"
                            />
                        </div>
                    </div>
                </CCol>
                <!--CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">ARRIVAL POSITION</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ArrivalPosition"
                            />
                        </div>
                    </div>
                </CCol-->
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">MOV STOWAGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">TRANSAC STOWAGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.TransacStowageName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">MOV STOWAGE REASON</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageReasonName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">REASON DESC</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageReasonDs"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DELIVERY PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DeliveryPort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">CONFIRMATION</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ConfirmationDate"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DEVICE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DeviceCode"
                            />
                        </div>
                    </div>
                </CCol>                
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">USER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.TransaLogin"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">YARD</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Yard"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">PROCEDENCE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Procedence"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIRECT DISCHARGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DirectDischarge"
                            />
                        </div>
                    </div>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton outline color="wipe" @click="modalColumn=false">
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                location: true,
                hold: true,
                hatchCover: true,
                LoadPort:true,
                DischargePort:true,
                Weigth:true,
                ShippingLineCode:true,
                ShippingLine:true,
                ImdgCode:true,
                UnNumber:true,
                //ArrivalPosition:true,
                MovStowageName:true,
                TransacStowageName:true,
                MovStowageReasonName:true,
                MovStowageReasonDs:true,
                ConfirmationDate:true,
                DeviceCode:true,
                TransaLogin:true,
                DeliveryPort: false,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            }, 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            nulo:'', 
            search: '',
            pageSize: '',
            labelUnitMeasure: 'TON', 
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;        
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        let rowDataFormated = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX: rowData[i].IdX,
                ContainerCode: rowData[i].ContainerCode,
                Size: rowData[i].Size ? rowData[i].Size : '',
                Status: rowData[i].Status ? rowData[i].Status : '',
                VesselUbicationName: rowData[i].VesselUbicationName ?? '',
                VesselHoldName: rowData[i].VesselHoldName ?? '',
                HatchCoverName: rowData[i].HatchCoverName ?? '',
                LoadPort: rowData[i].LoadPort ? rowData[i].LoadPort : '',
                DischargePort: rowData[i].DischargePort ? rowData[i].DischargePort : '',
                DeliveryPort: rowData[i].DeliveryPort ? rowData[i].DeliveryPort : '',
                Weigth: formatMilDecimal(parseFloat(rowData[i].Weigth).toFixed(2)),
                ShippingLineCode: rowData[i].ShippingLineCode ? rowData[i].ShippingLineCode : '',
                ShippingLine: rowData[i].ShippingLine ? rowData[i].ShippingLine : '',
                ImdgCode: rowData[i].ImdgCode ? rowData[i].ImdgCode : '',
                UnNumber: rowData[i].UnNumber ? rowData[i].UnNumber : '',
                ArrivalPosition: rowData[i].ArrivalPosition ? rowData[i].ArrivalPosition : '',
                MovStowageName: rowData[i].MovStowageName,
                Seals: rowData[i].Seals ? rowData[i].Seals : '',
                IsoCode: rowData[i].IsoCode ? rowData[i].IsoCode : '',
                DeviceCode: rowData[i].DeviceCode ? rowData[i].DeviceCode : '',
                TransaLogin: rowData[i].TransaLogin ? rowData[i].TransaLogin : '',
                TransacStowageName: rowData[i].TransacStowageName,
                MovStowageReasonName: rowData[i].MovStowageReasonName,
                MovStowageReasonDs: rowData[i].MovStowageReasonDs ? rowData[i].MovStowageReasonDs : '',
                ConfirmationDate: rowData[i].ConfirmationDate,
                Procedence: rowData[i].Procedence,
                YardNamePlanning: rowData[i].YardNamePlanning, 
                FgDirect: rowData[i].FgDirect
            });   
        }
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
            await this.getPdf(rowDataFormated,this.$t('label.listOfCanceled'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getExcel(rowData,this.$t('label.listOfCanceled'),valor,'N/A', this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;   
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
    }

    async function getCanceledList() {
        this.loadingOverlay = true;
        let dateStart = '';
        let dateEnd = '';

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);   

        let CargoSumaryJson = {
            UnitMeasureId: this.unitMeasureId,
            VisitId: this.Visit,
            CompanyBranchId: this.branch.CompanyBranchId,
            DateStart:dateStart,
            DateEnd:dateEnd,
        };
        await this.$http.post("VisitCargoCancel-list", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            let listado = [...response.data.data];
            let i = 1;
            this.nulo = String(response.data.data[0].Json);
                        
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                    IdX: i++
                };
            });
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-info text-white font-weight-bold'
        }
    }
    async function refreshComponent() {
        this.loadingOverlay = true;
        this.pageSize='';
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.unitMeasureId= '';
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getDateRange();
        await this.getUnitMeasureWeigthList();
        this.getCanceledList();
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }
    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function columnDefs(){
        let columnDefs = []

        if (this.FgGeneralCargo) {
            columnDefs = [
                {
                    field: "IdX",
                    headerName: "#",
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial gb-cell',
                },
                {
                    field: "Size",
                    headerName: "SIZE (FT)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "IsoCode",
                    headerName: "ISO CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Seals",
                    headerName: "SEALS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Status",
                    headerName: "STATUS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'center-cell-especial',
                },

            ]
        } else {
            columnDefs = [
                {
                    field: "IdX",
                    headerName: "#",
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "ArrivalPosition",
                    headerName: "ARRIVAL \nPOSITION",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial gb-cell',
                    minWidth: 180,
                    filter: "agTextColumnFilter",
                    suppressMovable: true,
                    lockPosition: true,
                    //pinned: 'left',
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial gb-cell',
                },
                {
                    field: "Size",
                    headerName: "SIZE (FT)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "IsoCode",
                    headerName: "ISO CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Seals",
                    headerName: "SEALS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Status",
                    headerName: "STATUS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'center-cell-especial',
                },
            ]
        }

        if(this.FgGeneralCargo && this.columnsOption.location){
            columnDefs.push({
                field: "VesselUbicationName",
                headerName: "LOCATION",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }

        if(this.FgGeneralCargo && this.columnsOption.hold){
            columnDefs.push({
                field: "VesselHoldName",
                headerName: "HOLD",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }

        if(this.FgGeneralCargo && this.columnsOption.hatchCover){
            columnDefs.push({
                field: "HatchCoverName",
                headerName: "HATCH COVER",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }

        if(this.columnsOption.ShippingLineCode){
            columnDefs.push({
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ShippingLine){
            columnDefs.push({
                field: "ShippingLine",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 120,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ImdgCode){
            columnDefs.push({
                field: "ImdgCode",
                headerName: "IMDG CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.LoadPort){
            columnDefs.push({
                field: "LoadPort",
                headerName: "LOAD PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DischargePort){
            columnDefs.push({
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DeliveryPort){
            columnDefs.push({
                field: "DischargePort",
                headerName: "DELIVERY PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        /*if(this.columnsOption.ArrivalPosition){
            columnDefs.push({
                field: "ArrivalPosition",
                headerName: "ARRIVAL \nPOSITION",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }*/
        if(this.columnsOption.Weigth){
            columnDefs.push({
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            });
        }
        if(this.columnsOption.UnNumber){
            columnDefs.push({
                field: "UnNumber",
                headerName: "UN NUMBER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.MovStowageName){
            columnDefs.push({
                field: "MovStowageName",
                headerName: "MOV \nSTOWAGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.TransacStowageName){
            columnDefs.push({
                field: "TransacStowageName",
                headerName: "TRANSAC \nSTOWAGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 220,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.MovStowageReasonName){
            columnDefs.push({
                field: "MovStowageReasonName",
                headerName: "MOV STOWAGE \nREASON",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 220,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.MovStowageReasonDs){
            columnDefs.push({
                field: "MovStowageReasonDs",
                headerName: "REASON \nDESC",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ConfirmationDate){
            columnDefs.push({
                field: "ConfirmationDate",
                headerName: "CONFIRMATION",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DeviceCode){
            columnDefs.push({
                field: "DeviceCode",
                headerName: "DEVICE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }        
        if(this.columnsOption.TransaLogin){
            columnDefs.push({
                field: "TransaLogin",
                headerName: "USER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        let _this = this.$i18n.locale;   
        if(this.rows.length !== 0 && this.nulo!="null"){
            let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            this.rows.map(function(item){
                computedItems.push({
                    IdX: item.IdX,
                    ContainerCode: item.ContainerCode,
                    Size: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    Status: item.TpCargoStatusName ? item.TpCargoStatusName : '',
                    VesselUbicationName: item['VesselUbicationName'+_lang] ?? '',
                    VesselHoldName: item.VesselHoldName ?? '',
                    HatchCoverName: item.HatchCoverName ?? '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                    Weigth: item.Weigth ? item.Weigth : 0,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLine: item.ShippingLineName ? item.ShippingLineName : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ArrivalPosition: item.BaySlot ? item.BaySlot : '',
                    MovStowageName: _this=='en' ? item.MovStowageNameEn : item.MovStowageNameEs,
                    Seals: item.Seals ? item.Seals : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    DeviceCode: item.DeviceCode ? item.DeviceCode : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                    //MovStowageName: item.MovStowageName ? item.MovStowageName : '',
                    TransacStowageName: _this=='en' ? item.TransacStowageNameEn : item.TransacStowageNameEs,

                    //TransacStowageName: item.TransacStowageName ? item.TransacStowageName : '',
                    //MovStowageReasonName: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                    MovStowageReasonName: _this=='en' ? item.MovStowageReasonNameEn : item.MovStowageReasonNameEs,

                    MovStowageReasonDs: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                    ConfirmationDate: item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '',

                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'

                })
            })
        }
        
        return computedItems;
    }

    export default {
        name: "canceled-list",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getCanceledList,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            validateDateRange,
            refreshComponent,
            getDateRange,
        },
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,       
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,  
            }),
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==28) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>