<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.transactionReversals')}}</h6>
            </CCol>
            <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow class="mb-2">
            <CCol sm="11" lg="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"  
                    v-model="search"          
                >
                </CInput> 
            </CCol>
            
            
            <CCol sm="11" lg="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9 px-0'}"
                    :label="$t('label.motive')"
                    :options="motiveListFormatted"
                    v-model.trim="FgApplyCrane"
                    :value.sync="FgApplyCrane"
                    @change="filterApplyCrane"
                />
            </CCol>
            <CCol sm="11" lg="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 text-right', input: 'col-sm-10 col-lg-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    :disabled="FgApplyCrane == 0"
                />
            </CCol>
            <CCol sm="11" lg="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 text-right', input: 'col-sm-10 col-lg-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" class="mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" >
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :getRowClass="getRowClass"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            loadingOverlay:false,
            nulo:'', 
            VisitCraneId: '',
            craneOptions: [],
            FgApplyCrane: null,
            motiveOptions: [],
            CraneAlias: '', 
            pageSize:'',
            search:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getMotiveList() {
        await this.$http.get("CancelGroupTransacReason-list")
        .then(response => {
            this.motiveOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }
    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.listOfImdgContainers'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
            this.loadingOverlay = true;
            let rowData = [];
            let motive = this.CraneAlias;
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            if(this.nulo!="null" && this.formatedItems.length !== 0) {
                if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
                await this.getExcelTransactionReversals(rowData,this.$t('label.transactionReverseList'),valor,this.CraneAlias, motive);
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }
            this.loadingOverlay = false;    
    }

    async function getTransactionReverseList () {
        this.loadingOverlay = true;

        let TransaReverseJson = {
            VisitId: this.Visit,
            VisitCraneId:this.VisitCraneId, //Id de la Grúa,
            FgApplyCrane: this.FgApplyCrane, //1//Requiere grúa 1, 0 no Requiere grúa, NULL sin filtro por tipo de operación de reverso 
        };

        await this.$http.post("VisitTransactionReverse-list", TransaReverseJson, { root: 'TransaReverseJson' })
        .then(response => {
            let listado = [...response.data.data];
            let i = 1;
            this.nulo = String(response.data.data[0].Json);
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                   
                };
            });

           
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.loadingOverlay = true;
        await this.getTransactionReverseList();
        this.loadingOverlay = false;
    }

    async function filterApplyCrane(e) {
        this.FgApplyCrane=e.target.value;
        this.loadingOverlay = true;
        this.VisitCraneId = "";
        await this.getTransactionReverseList();
        this.loadingOverlay = false;
    }

    function getRowClass(params) {
        if (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES") {
            return 'bg-confirmed'
        }
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.pageSize="";
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.CraneAlias= '';
        this.VisitCraneId= '';
        this.FgApplyCrane = null;
        this.gridApi.paginationSetPageSize(parseInt(50));
        await this.getCraneList();
        await this.getMotiveList();
        await this.getTransactionReverseList();
    }
                   
    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                //pinned: 'left',
            },
            {
                field: "PreviousPosition",
                headerName: this.$t('label.previousPosition'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 200,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "NewPosition",
                headerName: this.$t('label.CurrentPosition'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "ContainerCode",
                headerName: this.$t('label.containerCode'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 220,
                cellClass: 'gb-cell',
                //pinned: 'left',
            },
            {
                field: "TpCargoCode",
                headerName: this.$t('label.size')+" (FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "ImdgCode",
                headerName: this.$t('label.imdgCode'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "CraneAlias",
                headerName: this.$t('label.crane'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "DelayCustomName",
                headerName: this.$t('label.transaction'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "CancelTransacReasonName",
                headerName: this.$t('label.motive'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "Observation",
                headerName: this.$t('label.observation'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "TransaRegDate",
                headerName: this.$t('label.date'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "Login",
                headerName: this.$t('label.user'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
            {
                field: "DeviceCode",
                headerName: this.$t('label.device'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell',
            },
        ]      
        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            if(this.VisitCraneId !== '' || this.VisitCraneId !== null)
                this.CraneAlias = this.rows[0].CraneAlias;
            
            let _lang = this.$i18n.locale;  
            this.rows.map(function(item){
                computedItems.push({
                    IdX: item.Nro,
                    CancelTransacReasonId: item.CancelTransacReasonId ? item.CancelTransacReasonId : '',
                    ContainerCode: item.ContainerCode,
                    TpCargoCode: item.TpCargoCode ? item.TpCargoCode : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                    DelayCustomNameEn: item.DelayCustomNameEn ? item.DelayCustomNameEn: '',
                    DelayCustomNameEs: item.DelayCustomNameEs ? item.DelayCustomNameEs : '',
                    CancelTransacReasonNameEn: item.CancelTransacReasonNameEn? item.CancelTransacReasonNameEn : '',
                    CancelTransacReasonNameEs: item.CancelTransacReasonNameEs ? item.CancelTransacReasonNameEs : '',
                    DeviceCode: item.DeviceCode ? item.DeviceCode : '',

                    DelayCustomName: _lang=='en' ? item.DelayCustomNameEn : item.DelayCustomNameEs,
                    CancelTransacReasonName: _lang=='en' ? item.CancelTransacReasonNameEn : item.CancelTransacReasonNameEs,

                    PreviousPosition: item.PreviousPosition ? item.PreviousPosition : '',
                    NewPosition: item.NewPosition ? item.NewPosition : '',
                    Observation: item.Observation ? item.Observation : '',
                    Login: item.Login ? item.Login : '',
                    TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                })
            })
        }
        return computedItems;
    }


    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    function motiveListFormatted(){
        let _lang = this.$i18n.locale; 
        if(this.motiveOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.motiveOptions.map(function(e){
                
                    chart.push({
                        value: e.FgApplyCrane ? 1 : 0, 
                        label: _lang=='en' ? e.CancelTransacReasonNameEn : e.CancelTransacReasonNameEs,
                    })
                
            })
            return chart;
        }
    }

    export default {
        name: "transaction-reversals-list",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getTransactionReverseList,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            getCraneList,
            filterCrane,
            filterApplyCrane,
            refreshComponent,
            getMotiveList,
        },
        computed:{
            columnDefs,
            formatedItems,
            craneListFormatted,
            motiveListFormatted,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
                tabIndex: state => state.visitas.tabIndex,
            }),

        },
        
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==39) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss">
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }

    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>