import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { isUndefined } from 'lodash';

function data() {
  return {
    dataBuque:{
      CountryName:'',
      DatePlanning:'',
      VesselFlagRoute:'',
    },
  }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getPdf(rowData, titulo, optionHeaderReport) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';
    for (let index = 0; index < this.columnDefs.length; index++) {
        //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
        columns.push({
            title: this.columnDefs[index].headerName,
            dataKey: this.columnDefs[index].field
        });
    }
    
    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [612, 792],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha = DateFormater.formatTimeZoneToDateTime(hoy);

        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed') + ": " + fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user') + ": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user') + ": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }
    
    const Index = this.myDataItinirary.map(function(e) {
        return e.ItineraryId; 
    }).indexOf(this.ItineraryId);
    
    let voyajeData = '';
    if(Index > -1){
        this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+', '+this.myDataItinirary[Index].CountryName;
        this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
        this.dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;
        voyajeData = "ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;

    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    let inicio = 145;
    
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        tableWidth: tableWidthBody,
        margin: { bottom: 45 },
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center"
        },
        columnStyles: {
            overflow: 'linebreak',
        },
       /* styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap',
           // font: 'arial',
            fontSize: font,
            cellPadding: 2,
            halign: "center",
            overflowColumns: 'linebreak'
        },*/
        didParseCell: function (rowData) {
            if(optionHeaderReport == 'containerByPortLineSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];    
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 4){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 5 && rowData.column.index <= 6){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 7 && rowData.column.index <= 8){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 9 && rowData.column.index <= 10){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 11 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 13 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                }
            } else if(optionHeaderReport == 'imdgContainerSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 1){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 2;
                    }
                
                    if(rowData.column.index >= 2 && rowData.column.index <= 5){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 4;
                    }
                    
                    if(rowData.column.index >= 6 && rowData.column.index <= 9){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 4;
                    }

                    if(rowData.column.index >= 10 && rowData.column.index <= 13){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 4;
                    }
                }
            } else if(optionHeaderReport == 'delayList'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 13;
                    }
                
                    if(rowData.column.index >= 13 && rowData.column.index <= 15){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 3;
                    }
                    
                    if(rowData.column.index >= 16 && rowData.column.index <= 18){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 19 && rowData.column.index <= 21){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                }
            }  else if(optionHeaderReport == 'transportSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 8){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 6;
                    }
                    
                    if(rowData.column.index >= 9 && rowData.column.index <= 11){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 12 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 3;
                    }
                }
            }else if(optionHeaderReport == 'TypeLoadPerCraneSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index == 2){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = rowData.row.raw.DeckMax+2;
                    }
                    
                    if(rowData.column.index >= (4+rowData.row.raw.DeckMax)){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = rowData.row.raw.HoldMax+2;
                    }
                }
            }    
        },
    });
  
    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getPdfArray(rowData, titulo) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    
    let columnDefs = Object.keys(rowData[0]);
    
    for (let index = 0; index < columnDefs.length; index++) {
        columns.push({
            title: columnDefs[index],
            dataKey: columnDefs[index]
        });
    }
    
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [612, 792],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha =  DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed') + ": " + fecha, 40, 570, {
                align: 'left'
            });
            if (this.loadText !== undefined && this.loadText !== null) {
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: " + this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            } else {
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }
    const Index = this.myDataItinirary.map(function(e) {
        
        return e.ItineraryId; 
    }).indexOf(this.ItineraryId);

    let voyajeData = '';
    if(Index > -1){
        this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+', '+this.myDataItinirary[Index].CountryName;
        this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
        this.dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;
        voyajeData = "ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData }); 

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }
    
    let inicio = 140;
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        /*styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap',
            //font: 'arial',
            fontSize: font,
            cellPadding: 2,
            halign: "center",
            overflowColumns: 'linebreak'
        },*/
    });

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getExcel(rowData, titulo, excel, companyName, membrete=true, nombreLibro) {
    const workbook = new Excel.Workbook();
    let nombreOpcional = nombreLibro ? nombreLibro : titulo;
    const worksheet = workbook.addWorksheet(nombreOpcional);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let columnsDefs2 = [];
    let keyColumns = [];
    let bandera = true;

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });

        if (columnsDefs[0].field =='messagesContent') {
            for (let i = 0; i < columnsDefs.length; i++) {
                if ((columnsDefs[i].field != 'messagesContent') && (columnsDefs[i].field != 'especial')) {
                    columnsDefs2.push(columnsDefs[i]);
                }
            }
            columnsDefs = columnsDefs2;
            keyColumns.splice(0, 2);
            y=y-3;
        }      
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }
        
    //if (isUndefined(un)) un = 'TON';
    if(membrete){
        if (excel) {
            if(companyName === undefined){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
            
                if(Index > -1){      
                    this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                } else {
                    let itinerarySelected = this.itinerarySelected;
                    this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                    this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                    this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
                }

            } 
            if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(companyName){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "2")   
            }else if(companyName === undefined){ 
                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
            }

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = companyName ? 60 : 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                
            if(companyName){
            }else if(companyName === undefined){     
                const row2 = worksheet.getRow(2);    
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = this.dataBuque.DatePlanning;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = titulo;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //COLUMNA 2
            let division = Math.floor(y / 3);
            let rango1 = numeroLetra(division);
            let rango11 = numeroLetra(division+1);
            let rango2 = numeroLetra(division*2);
            let rango21 = numeroLetra((division * 2) +1);
            let rango3 = numeroLetra(y);
            
            const hoy = new Date();

            if(companyName){
                worksheet.mergeCells("A4:" + rango1 +"4");
                const customCellCompany = worksheet.getCell("A4");
                worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                customCellCompany.height = 50;
                customCellCompany.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };

                customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCellCompany.value = this.$t('label.company')+": "+companyName;
                customCellCompany.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                    left: { style: 'thin' },
                }
            }else if(companyName === undefined){
                worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }

            const customCell6 = companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }
                        
            //linea
            worksheet.mergeCells("A5:" + rango3 +"5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }
    }
    

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    headerRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
   // headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

async function getExcelArray(rowData, titulo, excel, companyName, vesselInfo = true, nombreLibro, tableHeader) {
    const workbook = new Excel.Workbook();
    let nombreOpcional = nombreLibro ? nombreLibro : titulo;
    const worksheet = workbook.addWorksheet(nombreOpcional);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;

    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else{
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera = false;
    }

    //if (isUndefined(un)) un = 'TON';
    if (excel) {
        if(vesselInfo){
            if(companyName === undefined){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                
                if(Index > -1){      
                    this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                    this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                } else {
                    let itinerarySelected = this.itinerarySelected;
                    this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                    this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                    this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
                }
            }
        }
        
        if (y < 10) letra = (y < 2 ? 12 : 8);
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });

        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        if(vesselInfo){
            if(companyName){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "2");
            }else if(companyName === undefined){ 
                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
            }
        }else{
            if(numeroLetra(y) === "B"){
                worksheet.mergeCells("C1:F2");
            }else{  
                worksheet.mergeCells("C1:" + numeroLetra(y) + "2");
            }
        }
        const customCellCompanyBranch = worksheet.getCell("C1");
        const row1 = worksheet.getRow(1);
        if(vesselInfo){
            row1.height = companyName ? 60 : 30;
        }else{
            row1.height = 60;
        }
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: vesselInfo ? (letra) : 12,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

        if(vesselInfo){
            if(companyName){
            }else if(companyName === undefined){     
                const row2 = worksheet.getRow(2);    
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = this.dataBuque.DatePlanning;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }
        }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + ( numeroLetra(y) === "B" ? "F" : numeroLetra(y) ) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //COLUMNA 2
        let division = Math.floor((y === 2 ? 4 : y ) / 3);
        let rango1 = numeroLetra(division);
        let rango11 = numeroLetra(division+1);
        let rango2 = numeroLetra(division*2);
        let rango21 = numeroLetra((division * 2) +1);
        let rango3 = numeroLetra((y === 2 ? 4 : y ));
        
        const hoy = new Date();

        if(vesselInfo){
            if(companyName){
                worksheet.mergeCells("A4:" + rango1 +"4");
                const customCellCompany = worksheet.getCell("A4");
                worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                customCellCompany.height = 50;
                customCellCompany.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };

                customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCellCompany.value = this.$t('label.company')+": "+companyName;
                customCellCompany.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                    left: { style: 'thin' },
                }
            }else if(companyName === undefined){
                worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }
        }else{
            worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
            worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
        }

        const customCell6 = vesselInfo ? (companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4")) : worksheet.getCell("A4");
        customCell6.height = 50;
        customCell6.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        customCell6.value = this.$t('label.printed')+": "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        customCell6.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //usuario
        const customCell7 = vesselInfo ? (companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4")) : worksheet.getCell(rango21 + "4");
        customCell7.height = 50;
        customCell7.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        customCell7.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        }
                    
        //linea
        worksheet.mergeCells("A5:" + rango3 +"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
   /* headerRow.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
    } */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'cccccc'
            }
        }
        cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        
        cell.value = bandera ? data.headerName : data;
        cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function (data) {
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }            
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = { 
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }            
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;
            
            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            }).catch(err => {
                console.log(err);
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}



export default {
  data,
  methods: {
    getPdf,
    getPdfArray,
    getExcel,
    getExcelArray,
    numeroLetra
  },
  computed:{
    ...mapState({
        StowagePlanning: state => state.planificacionestiba.planificacionId,
        EdiFileId: state => state.planificacionestiba.EdiFileId,
        dropItem: state => state.planificacionestiba.dropItem,
        myDataItinirary: state => state.planificacionestiba.myDataItinirary,
        user: state => state.auth.user,
        ItineraryId: state => state.planificacionestiba.ItineraryId,
    }),
  },
}