<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal()"
      :closeOnBackdrop="false"
      size="xl"
    >
      
        <CRow class="justify-content-center m-2">
              <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start  ">
              </CCol>
              <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end  ">
                  <CButton 
                      color="danger"
                      class="mr-1"
                      size="sm"
                      @click="onBtnPdf()"
                      >
                      <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                  </CButton>
                  <CButton
                      color="excel"
                      class="mr-1"
                      size="sm"
                      @click="onBtnExport(true)"
                  >
                      <CIcon name="cil-file-excel"/>&nbsp; XSLX
                  </CButton>
                  <CButton
                      color="watch"
                      size="sm"
                      @click="onBtnExport(false)"
                  >
                      <CIcon name="cil-file-excel"/>&nbsp; CSV
                  </CButton>

              </CCol>
              <CCol sm="12">
                <dataTableExtended
                  class="align-center-row-datatable"
                  hover
                  sorter
                  column-filter
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items="items"
                  :fields="fields"
                  :loading="loadingOverlay"
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="5"
                  pagination
                >
                  <template #loading>
                      <loading/>
                  </template>
                  <template #RowNumber="{ item }">
                    <td class="center-cell">
                      {{ item.RowNumber }}
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
        </CRow>
        <template #footer>
          <CButton color="wipe" @click="closeModal">
            <CIcon name="x" />&nbsp; {{$t('button.exit')}}
          </CButton>
        </template>
      
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import ReportesVisitas from '@/_mixins/reportesVisitas';

function data() {
  return {
    nulo: '',
    titleModal: '',
    loadingOverlay: false,
    showModal:false,
    items: [],
  };
}
//methods
function closeModal() {
  this.$emit('closeModal');
  this.showModal=false;
}

function setData(modal) {
  this.loadingOverlay = true;
  let listado = Array;
  listado = modal.DetailJson;
  this.items = listado.map(listado => Object.assign({}, this.items, {       
    RowNumber: listado.IdX,
    orden: listado.OrderCode ? listado.OrderCode : '',
    fechaEmi: listado.CreationDate ? DateFormater.formatDateTimeWithSlash(listado.CreationDate) : 'N/A',
    fechaEmb: listado.EmbarkDate ? DateFormater.formatDateTimeWithSlash(listado.EmbarkDate) : 'N/A',
    tiempo: listado.TimeHrs ? listado.TimeHrs : 0,
    grua: listado.CraneAlias ? listado.CraneAlias : '',
    bodega: listado.VesselHoldName ? listado.VesselHoldName : '',
    dispositivos: listado.DeviceCode ? listado.DeviceCode : '',
    TransaLogin: listado.TransaLogin ? listado.TransaLogin : 'N/A',
    fecha: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlash(listado.TransaRegDate) : 'N/A',
  }));
 
  this.loadingOverlay = false; 
}


    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];

        if(this.nulo!="null" && this.items.length !== 0) {
          if(this.$i18n.locale == 'es'){
            this.items.forEach(function (item) {
              rowData.push({
                '#': item.RowNumber,
                'NRO ORDEN': item.orden,
                'FECHA DE EMISION': item.fechaEmi,
                'FECHA DE EMBARQUE': item.fechaEmb,
                'TIEMPOS (HRS)': item.tiempo,
                'GRUA': item.grua,
                'BODEGA': item.bodega,
                'DISPOSITIVO': item.dispositivos,
                'USUARIO': item.TransaLogin,
                'FECHA': item.fecha,
              })
            });
          } else if(this.$i18n.locale == 'en'){
            this.items.forEach(function (item) {
              rowData.push({
                '#': item.RowNumber,
                'ORDER NO.': item.orden,
                'EMISSION DATE': item.fechaEmi,
                'SHIPPING DATE': item.fechaEmb,
                'TIME (HRS)': item.tiempo,
                'CRANE': item.grua,
                'HOLD': item.bodega,
                'DEVICE': item.dispositivos,
                'USER': item.TransaLogin,
                'DATE': item.fecha,
              })
            });
          }
            
            await this.getPdfArray(rowData,this.$t('label.vehicleBinSummary'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(value) {
        this.loadingOverlay = true;
        let rowData = [];

        let valores = []; 
        let currentDate = new Date();

        valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        if(this.nulo!="null" && this.items.length !== 0) {
          if(this.$i18n.locale == 'es'){
            this.items.forEach(function (item) {
              rowData.push({
                '#': item.RowNumber,
                'NRO ORDEN': item.orden,
                'FECHA DE EMISION': item.fechaEmi,
                'FECHA DE EMBARQUE': item.fechaEmb,
                'TIEMPOS (HRS)': item.tiempo,
                'GRUA': item.grua,
                'BODEGA': item.bodega,
                'DISPOSITIVO': item.dispositivos,
                'USUARIO': item.TransaLogin,
                'FECHA': item.fecha,
              })
            });
          } else if(this.$i18n.locale == 'en'){
            this.items.forEach(function (item) {
              rowData.push({
                '#': item.RowNumber,
                'ORDER NO.': item.orden,
                'EMISSION DATE': item.fechaEmi,
                'SHIPPING DATE': item.fechaEmb,
                'TIME (HRS)': item.tiempo,
                'CRANE': item.grua,
                'HOLD': item.bodega,
                'DEVICE': item.dispositivos,
                'USER': item.TransaLogin,
                'DATE': item.fecha,
              })
            });
          }
          await this.getExcelArray(rowData,this.$t('label.vehicleBinSummary'),value);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }
//computed
function fields() {
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,
      _style: 'width:1%; text-align:center;',
    },
    { key: 'orden', label:this.$t('label.noOrder'), _style: 'width:8%;', _classes:'text-left'},
    { key: 'fechaEmi', label: this.$t('label.emissionDate'), _style: 'width: 12%;', _classes:'text-left'},
    { key: 'fechaEmb', label: this.$t('label.shippingDate'), _style: 'width: 12%;', _classes:'text-left'},
    { key: 'tiempo', label: this.$t('label.times')+' (HRS)', _style: 'width: 10%;', _classes:'text-left'},
    { key: 'grua', label: this.$t('label.crane'), _style: 'width: 10%;', _classes:'text-left'},
    { key: 'bodega', label: this.$t('label.hold'), _style: 'width: 10%;', _classes:'text-left'},
    { key: 'dispositivos', label: this.$t('label.device'), _style: 'width: 15%;', _classes:'text-left'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 10%;', _classes:'text-left'},
    { key: 'fecha', label: this.$t('label.date'), _style: 'width: 12%;', _classes:'text-center'},
  ];
}
export default {
  name: 'modal-vehicle-bin-summary-details',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral, ReportesVisitas],
  directives: UpperCase,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true;  
        if (this.modal!==true) {
          this.titleModal = this.$t('label.detail')+': '+this.modal.LicensePlate+' - '+this.modal.ImplementAlias;
          
          this.setData(this.modal);
        }
      }
    },
  },
  methods: {
    closeModal,
    setData,
    onBtnPdf,
    onBtnExport,
  },
  computed: {
    fields,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,  
      branch: (state) => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss">

</style>
