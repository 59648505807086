import {
  required,
  minLength,
  maxLength,
  alpha,
  helpers,
  decimal,
} from 'vuelidate/lib/validators';
import {
  onlyMayusTextEspecial,
  SoloContenedores,
  onlyMayusText,
  onlyAlphanumeric,
  onlyAlphanumeric3,
  onlyAlphanumeric5,
  SoloDecimales,
  requiredSelect,
  UtcZone,
  validationSeal,
} from '@/_validations/validacionEspeciales'

const charValue = (char, special = false) =>{
  let values = {
      A:10,
      B:12,
      C:special?2:13,
      D:14,
      E:15,
      F:16,
      G:17,
      H:special?4:18,
      I:19,
      J:20,
      K:21,
      L:special?0:23,
      M:24,
      N:25,
      O:26,
      P:27,
      Q:28,
      R:29,
      S:30,
      T:31,
      U:special?9:32,
      V:34,
      W:35,
      X:36,
      Y:37,
      Z:38,
  }

  return values[char];
}

async function containerCodeValidation(text) {
  try {
      let chars = text.split('');
      /*
      if( chars.length != (11)){
          throw ('lengthCodeInvalid')
      }
      */

      const special = text.substr(0,3) == "HLCU";

      let charValues = await chars.map((element, index)=>{
          //let result = false; 
          let value;
          /*
          
          if(index < 3){
              result = onlyAlpha(element);    
          } else if(index == 3){
              result = onlyUniqueAlpha(element);    
          } else {
              result = onlyNumbers(element);
          } 

          if(!result && (index < 3)){
              throw (`OnlyUpperCaseLetterInPosition#${index+1}`)
          } else if (!result && (index == 3)){
              throw (`OnlySpecificUpperCaseLetterInPosition#${index+1}#(U, J, Z)`)
          } else if (!result){
              throw (`OnlyNumberInPosition#${index+1}`)
          }
          */
          
          if(index< 4){
              value = charValue(element, special);
          } else {
              value = parseInt(element);
          } 

          if( index == (chars.length-1))
              return value;
          
          value = parseInt(value * Math.pow(2, index));

          return value;
      });

      let plusValues = 0;

      await charValues.forEach((element, index) => {
          if (index<(charValues.length-1))
              plusValues = parseInt(plusValues+element);
      });
      
      let resultDevided = Math.floor(plusValues/11) ;
      let resultTimes = parseInt(resultDevided*11)
      let lastChar = parseInt(plusValues - resultTimes);

      

      if(lastChar == 10){
          lastChar = 0;
      }

      if (charValues[charValues.length-1] != lastChar){
          throw (`checkDigitInvalid`);
      } 
          
      return true;
  } catch (e){
      return false;
  }
}

export const FormInspection = (verifySeal1, verifySeal2, verifySeal3, verifySeal4) => {

  let seal1Valid = {};
  let seal2Valid = {};
  let seal3Valid = {};
  let seal4Valid = {};

  if(verifySeal1){ seal1Valid = { required } }
  if(verifySeal2){ seal2Valid = { required } }
  if(verifySeal3){ seal3Valid = { required } }
  if(verifySeal4){ seal4Valid = { required } }


  return {
    FormInspection: {  
      inspection: {
        containerCode: { 
          required,
          onlyAlphanumeric,
          SoloContenedores,
          checkDigit: containerCodeValidation,
          minLength: minLength(11),
          maxLength: maxLength(11)
        
        },
        inspectionTypeId: { required },
        visitCargoId: { required },
      },

      damage: {
        damageTypeId: { required },
        locationId: { required },
        width: { decimal },
        height: { decimal },
        inspectionMomentId: { required }, 
        responsible: { },
        hour: { required },
        date: { required },
        observation: { },
      },
      seals: {
        hour: { required },
        date: { required },
        inspectionMomentId: { required },
       

        Seal1: {
          sealConditionId: seal1Valid,
          seal: { },
          inspection: { maxLength: maxLength(20), seal1Valid, validationSeal},
          observation: { maxLength: maxLength(20), onlyAlphanumeric5 },
        },
        Seal2: {
          sealConditionId: seal2Valid ,
          seal: { },
          inspection: { maxLength: maxLength(20), seal2Valid, validationSeal},
          observation: { maxLength: maxLength(20), onlyAlphanumeric5},
        },
        Seal3: {
          sealConditionId: seal3Valid,
          seal: {  },
          inspection: { maxLength: maxLength(20),seal3Valid, validationSeal},
          observation: { maxLength: maxLength(20), onlyAlphanumeric5},
        },
        Seal4: {
          sealConditionId: seal4Valid,
          seal: { },
          inspection: { maxLength: maxLength(20), seal4Valid, validationSeal},
          observation: { maxLength: maxLength(20), onlyAlphanumeric5},
        },
      },
    
    }
  }
}