
<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalDetails
            :modal="showModalDetails" 
            @closeModal="showModalDetails=false" 
        />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.vehicleBinSummary') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>

            </CCol>
        </CRow>      
        <CRow>
            <CCol sm="11" lg="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 text-right', input: 'col-sm-10 col-lg-9'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="5" class="center-field  ">
            </CCol>
            <CCol sm="11" lg="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 text-right', input: 'col-sm-10 col-lg-4'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import modalDetails from './modal-vehicle-bin-summary-details';

    //Data
    function data() {
        return {
            craneOptions: [],
            holdOptions: [],
            formatedItems: [],

            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
     
            loadingOverlay:false,

            nulo:'',
            pageSize:'',
            search:'',
            showModalDetails: false,
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'seeDetails'){
                    this.showModalDetails=event.data;
                    if(event.value !== ""){
                       // this.getOrderReport(event.data);
                    }
                }               
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'seeDetails'){
                   tippy('#mySeeDetailsButton', {
                        content: this.$t('label.see')+' '+this.$t('label.details'),
                    })
                } 
            },
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ClientName:rowData[i].ClientName,
                LicensePlate:rowData[i].LicensePlate,
                ImplementAlias:rowData[i].ImplementAlias,
                CantEmbarked:rowData[i].CantEmbarked,
                EstimatedTon: rowData[i].EstimatedTonOrigin,
                WeighedTon:rowData[i].WeighedTonOrigin,
            });
        } 

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getPdf(rowDataFormated,this.$t('label.vehicleBinSummary'),1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(value) {
        this.loadingOverlay = true;
        let rowData = [];

        let valores = []; 
        let currentDate = new Date();

        valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));

        let rowDataFormated = [];

        for (let i = 0; i < rowData.length; i++) {
            rowDataFormated.push({
                IdX:rowData[i].IdX,
                ClientName:rowData[i].ClientName,
                LicensePlate:rowData[i].LicensePlate,
                ImplementAlias:rowData[i].ImplementAlias,
                CantEmbarked:rowData[i].CantEmbarked,
                EstimatedTon: rowData[i].EstimatedTon,
                WeighedTon:rowData[i].WeighedTon,
            });
        } 

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.vehicleBinSummary'),value, valores,1);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }

    async function getVehicleBinSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;
        let _this = this;
        let SummaryJson = {
            VisitId: this.Visit,
        };

        await this.$http.post("VisitOrderSummaryByImplement", SummaryJson, { root: 'SummaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;

            for (i=0; i < listado.length; i++) {
                this.formatedItems.push({
                    seeDetails: _this.$t('label.see')+' '+_this.$t('label.details'),
                    IdX: listado[i].IdX,
                    ClientName: listado[i].ClientName ? listado[i].ClientName : '',
                    LicensePlate: listado[i].LicensePlate ? listado[i].LicensePlate : '',
                    ImplementAlias: listado[i].ImplementAlias ? listado[i].ImplementAlias : '',
                    CantEmbarked: listado[i].CantEmbarked ? listado[i].CantEmbarked : 0,
                    EstimatedTon: listado[i].EstimatedTon ? listado[i].EstimatedTon : 0,
                    EstimatedTonOrigin: listado[i].EstimatedTon ? formatMilDecimal(parseFloat(listado[i].EstimatedTon).toFixed(2)) : '0,00',
                    WeighedTon: listado[i].WeighedTon ? listado[i].WeighedTon : 0,
                    WeighedTonOrigin: listado[i].WeighedTon ? formatMilDecimal(parseFloat(listado[i].WeighedTon).toFixed(2)) : '0,00',
                    DetailJson: listado[i].DetailJson ? listado[i].DetailJson : '',
                })
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function refreshComponent() {
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize='';
        this.search=''; 
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.getVehicleBinSummary();
        this.$emit('child-refresh',true);
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'seeDetails',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeDetails",
                cellRenderer: params => {
                    if(params.data.DetailJson.length != 0){
                        return  `
                            <div align="center">
                                <button id="mySeeDetailsButton" class="btn mr-1 btn-watch btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.08 20.51" role="img" class="c-icon">
                                        <g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M15,0A16.17,16.17,0,0,0,0,10.25a16.15,16.15,0,0,0,30.08,0A16.16,16.16,0,0,0,15,0Zm0,17.09a6.84,6.84,0,1,1,6.84-6.84A6.84,6.84,0,0,1,15,17.09ZM15,6.15a4.11,4.11,0,1,0,4.1,4.1A4.1,4.1,0,0,0,15,6.15Z"></path></g></g>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'gb-cell px-1',
            },
            {
                field: "IdX",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                width: 70,
                minWidth: 70,
                cellClass: 'gb-cell ',
            },
            {
                field: "ClientName", 
                headerName: this.$t('label.transport'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'gb-cell ',
            },
            {
                field: "LicensePlate",
                headerName: this.$t('label.vehicle'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 200,
                cellClass: 'gb-cell ',
            },
            {
                field: "ImplementAlias",
                headerName: this.$t('label.Gamela'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'gb-cell ',
            },
            {
                field: "CantEmbarked",
                headerName: this.$t('label.Loadeds'),
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && params.value !== 0){
                        variable = parseFloat(params.value).toFixed(2);
                    }
                    return new Intl.NumberFormat("de-DE").format(variable);
                },
                filter: "agNumberColumnFilter",
            },
            {
                field: "EstimatedTon",
                headerName: this.$t('label.estimatedTons'),
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    } 

                    return variable;
                },
                filter: "agNumberColumnFilter",
            },
            {
                field: "WeighedTon",
                headerName: this.$t('label.tonsWeighed'),
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 210,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    } 

                    return variable;
                },
                filter: "agNumberColumnFilter",
            },
        ]

      
        return columnDefs;
    }

    export default {
        name: "vehicle-bin-summary",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getVehicleBinSummary,
            onQuickFilterChanged,
            onPageSizeChanged,
            refreshComponent,
        },
        computed:{
            columnDefs,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch, 
            }),
        },
        components:{
            tippy,
            modalDetails,
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==64) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        }
    };
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>