
<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalBinWeighingSummary 
            :modal="showModalBinWeighingSummary" 
            @closeModal="showModalBinWeighingSummary=false" 
            @child-refresh="datatableRefresh=true" 
        />
        <CTabs variant="tabs"  @update:activeTab="handleTab" :active-tab="0">
            <CTab :title="$t('label.weighingReport')"  :active="isActiveTab(0)">
                <CRow class="my-3">
                    <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start  ">
                        <!--h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.weighingDetailReport') }}</h6-->
                    </CCol>
                    <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end  ">
                        <CButton
                            color="excel"
                            class="mr-1"
                            size="sm"
                            @click="showModal"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XSLX
                        </CButton>
                    </CCol>
                </CRow>      
                <CRow class="justify-content-center" style="min-height: 300px;">
                    <CCol sm="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                        <div style="width: 100%;">
                            <ColumnwithGroupLabel
                                :textSize="'12px'"
                                :seriesGrafica="seriesMetalScrapCarrierWeighing" 
                                :groupsGrafica="groupsMetalScrapCarrierWeighing"
                                :titleYaxis="titleMetalScrapCarrierWeighing"
                            ></ColumnwithGroupLabel>
                        </div>
                    </CCol>
                </CRow>
                <CRow class="justify-content-center" style="min-height: 300px;">
                    <CCol sm="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                        <div style="width: 100%;">
                            <ColumnwithGroupLabel
                                :textSize="'12px'"
                                :seriesGrafica=" seriesMetalScrapYardWeighing" 
                                :groupsGrafica="groupsMetalScrapYardWeighing"
                                :titleYaxis="titleMetalScrapYardWeighing"
                            ></ColumnwithGroupLabel>
                        </div>
                    </CCol>
                </CRow>
            </CTab>   
        </CTabs> 
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import BinWeighing from '@/_mixins/mixinBinWeighing';
    import modalBinWeighingSummary from '@/pages/visita/tabs/reporte/listas/bin-weighing-summary/modal-bin-weighing-summary';
    import ColumnwithGroupLabel from "@/pages/visita/tabs/reporte/listas/bin-weighing-summary/components/ColumnwithGroupLabel";

    //Data
    function data() {
        return {
            loadingOverlay:false,
            datatableRefresh: false,

            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),

            itemsByCarrier: [],
            itemsByYard: [],

            activeTab: 0,

            showModalBinWeighingSummary: '',

        };
    }
    //mounted

    //methods
    function handleTab(tab) {
        this.activeTab = tab;
    }

    function isActiveTab(current) {
        return this.activeTab == current;
    }

    async function refreshComponent() {
        this.showModalBinWeighingSummary= '';
    }   

    function getMetalScrapCarrierWeighingList() {
      this.loadingOverlay = true;

          if (this.$store.state.visitas.VisitId != ''){
              this.itemsByCarrier= [];

              this.$http.ejecutar('GET', 'VisitMetalScrapCarrierWeighing',{ VisitId: this.Visit })
              .then(response => {

                if(response.data.data.length > 0 ){ 
                    this.itemsByCarrier = response.data.data;
                }

              }).catch( err => {
                  this.$notify({
                      group: 'container',
                      title: '¡Error!',
                      text: err,
                      type: "error"
                  });
              }).then(() => {
                  this.loadingOverlay = false;
              });
          } else {
            this.loadingOverlay = false;
          }
    }

    function getMetalScrapYardWeighingList() {
      this.loadingOverlay = true;

          if (this.$store.state.visitas.VisitId != ''){
              this.itemsByYard= [];

              this.$http.ejecutar('GET', 'VisitMetalScrapYardWeighing',{ VisitId: this.Visit })
              .then(response => {

                if(response.data.data.length > 0 ){ 
                    this.itemsByYard = response.data.data;
                }

              }).catch( err => {
                  this.$notify({
                      group: 'container',
                      title: '¡Error!',
                      text: err,
                      type: "error"
                  });
              }).then(() => {
                  this.loadingOverlay = false;
              });
          } else {
            this.loadingOverlay = false;
          }
    }

    function showModal(){
        if(this.itemsByCarrier.length > 0){
            this.showModalBinWeighingSummary=true;
        }else{
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailble'),
                type: 'error',
            });
        }

    }
    //computeds
    function groupsMetalScrapCarrierWeighing(){
        let data = [];
        if(this.itemsByCarrier.length > 0){
            this.itemsByCarrier.map(async(item,index) => {
                data.push({
                    title: item.CarrierAlias,
                    cols: 1
                });
            })
        }
        return data;
    }

    function seriesMetalScrapCarrierWeighing(){
        let data = [];
        if(this.itemsByCarrier.length > 0){
            this.itemsByCarrier.map(async(item,index) => {
                let WeighedTon =  item.WeighedTon;
                
                let TotalVoyages = item.TotalVoyages;
                data.push({
                    name: this.$t('label.tonsWeighed'),
                    x : this.$t('label.voyages') + ': ' + (TotalVoyages ? TotalVoyages : 0),
                    y : WeighedTon ? WeighedTon : 0,
                    fillColor: "#013565"
                });
            })
        }
        return data;
    }

    function titleMetalScrapCarrierWeighing(){
        return this.$t('label.tonsWeighed');
    }

    function groupsMetalScrapYardWeighing(){
        let data = [];
        if(this.itemsByYard.length > 0){
            this.itemsByYard.map(async(item,index) => {
                data.push({
                    title: item.YardName,
                    cols: 1
                });
            })
        }
        return data;
    }

    function  seriesMetalScrapYardWeighing(){
        let data = [];
        if(this.itemsByYard.length > 0){
            this.itemsByYard.map(async(item,index) => {
                let WeighedTon =  item.WeighedTon;
                
                let TotalVoyages = item.TotalVoyages;
                data.push({
                    name: this.$t('label.tonsWeighed'),
                    x : this.$t('label.voyages') + ': ' + (TotalVoyages ? TotalVoyages : 0),
                    y : WeighedTon ? WeighedTon : 0,
                    fillColor: "#ec631b"
                });
            })
        }
        return data;
    }

    function titleMetalScrapYardWeighing(){
        return this.$t('label.tonsWeighed');
    }

    export default {
        name: "bin-weighing-summary",
        data,
        mixins: [ReportesVisitas, BinWeighing],
        props: ['value'],
        methods:{
            handleTab,
            isActiveTab,
            refreshComponent,
            getMetalScrapCarrierWeighingList,
            getMetalScrapYardWeighingList,
            showModal
        },
        computed:{
            seriesMetalScrapCarrierWeighing,
            groupsMetalScrapCarrierWeighing,
            titleMetalScrapCarrierWeighing,
            seriesMetalScrapYardWeighing,
            groupsMetalScrapYardWeighing,
            titleMetalScrapYardWeighing,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,   
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch, 
                messageError : state=> state.visitas.messageError,
                apiState: state => state.visitas.apiState,
                apiStateForm: state => state.visitas.apiStateForm,
                VisitId: state => state.visitas.VisitId,
                collapseVisits: state => state.visitas.collapseVisits,
                bulkCarrier: state => state.visitas.bulkCarrier
            }),
        },
        components:{
            modalBinWeighingSummary,
            ColumnwithGroupLabel,
        },
        watch:{
            dropItemReport: async function (newValue) {
                if(newValue == 67 && this.VisitId != ''){
                    this.getMetalScrapCarrierWeighingList();
                    this.getMetalScrapYardWeighingList();
                }
            },
            active: function(newVal) {
                if(newVal)
                    this.activeTab = 0;
            },
            datatableRefresh: function () {
                if (this.datatableRefresh) {                  
                    this.getMetalScrapCarrierWeighingList();
                    this.getMetalScrapYardWeighingList();
                    this.datatableRefresh = false;
                    this.showModalBinWeighingSummary= "";
                }
            },
        }
    };
</script>

<style lang="scss">
    
</style>