<template>
    <div>
        <CModalExtended
            class="backgroundModal"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.movement')+' '+tituloModal"
            :show.sync="$store.state.planificacionestiba.modalMovimientoRestow"
        >   
            <div class="container-fluid">
                <CRow>
                    <CCol sm="12">
                        <CSelect
                            :label="$t('label.movementType')"
                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                            disabled
                            v-uppercase
                            :options="optionsTransacStowage"
                            v-model="formMovimiento.TransacStowageId"
                            required 
                        >
                        </CSelect>
                    </CCol>
                    <CCol sm="12">
                        <CSelect
                            :label="typeLabel"
                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                            addLabelClasses="required"
                            v-model="formMovimiento.MovStowageReasonId"
                            :value.sync="formMovimiento.MovStowageReasonId"
                            :addInputClasses="{ 'is-invalid': $v.formMovimiento.MovStowageReasonId.$error }"
                            :options="optionsMovStowageReason"
                            @blur="$v.formMovimiento.MovStowageReasonId.$touch()"
                        >
                            <template #invalid-feedback v-if="$v.formMovimiento.MovStowageReasonId.$error">
                                <div class='text-danger' v-if="!$v.formMovimiento.MovStowageReasonId.required">{{$t('label.required')}}</div>
                            </template>
                        </CSelect>
                    </CCol>
                    <CCol sm="12">
                        <CTextarea
                            :label="$t('label.MOVIMIENTO.observation')"
                            v-uppercase
                            v-model="$v.formMovimiento.MovStowageReasonDs.$model"
                            :addInputClasses="{ 'is-invalid': $v.formMovimiento.MovStowageReasonDs.$error }"
                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                            required
                            :plaintext="false"
                            :readonly="false"
                            :lazy="(true,500)"
                        >
                            <template #invalid-feedback>
                                <div v-if="$v.formMovimiento.MovStowageReasonDs.$error">
                                </div>
                            </template>
                        </CTextarea>
                    </CCol>
                    <CCol sm="12" v-if="modalMovimientoRestow">
                        <CRow v-if="optionalPosition">
                            <CCol sm="12" >
                                <CInput
                                    v-model.trim="positionActual"
                                    v-uppercase
                                    disabled
                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                    :label="$t('label.ARRIVAL_POSITION')"
                                />
                            </CCol>
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="6" >
                                        <CSelect
                                            addLabelClasses="required"
                                            label="Bay code"
                                            v-model="$v.formMovimiento.BayCode.$model"
                                            :addInputClasses="{ 'is-invalid': $v.formMovimiento.BayCode.$error }"
                                            :value.sync="formMovimiento.BayCode"
                                            :options="optionListBayAll"
                                            @input="checkBay"
                                            @blur="$v.formMovimiento.BayCode.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formMovimiento.BayCode.$error">
                                                <div class='text-danger' v-if="!$v.formMovimiento.BayCode.required">{{$t('label.required')}}</div>
                                            </template>
                                        </CSelect>
                                    </CCol>
                                    <CCol sm="6" >
                                        <CSelect
                                            addLabelClasses="required"
                                            :label="$t('label.DEPARTURE_POSITION')"
                                            v-model="$v.formMovimiento.NewPosition.$model"
                                            :addInputClasses="{ 'is-invalid': $v.formMovimiento.NewPosition.$error }"
                                            :value.sync="formMovimiento.NewPosition"
                                            :options="optionListSlotAll"
                                            @blur="$v.formMovimiento.NewPosition.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formMovimiento.NewPosition.$error">
                                                <div class='text-danger' v-if="!$v.formMovimiento.NewPosition.required">{{$t('label.required')}}</div>
                                                <div class='text-danger' v-if="!$v.formMovimiento.NewPosition.numeric">{{$t('label.onlyNumber')}}</div>
                                            </template>
                                        </CSelect>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCol>
              </CRow>
            </div>
            <div slot="footer">
                <CButton
                    size="sm"
                    color="success"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >
                    <div v-if="apiStateLoading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    size="sm"
                    color="danger"
                    @click="anularData"
                    class="m-2"
                    v-if="verificacionRestow"
                    :disabled="apiStateLoading"
                >
                <CIcon name="cil-update"/>&nbsp;
                    {{$t('button.annul')}}
                </CButton>
                <CButton
                    size="sm"
                    color="dark"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                <CIcon name="cil-chevron-circle-left-alt"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import  { FormMovimiento }  from '@/_validations/planificacion-estiba/containerdataValidations';
    //data
    function data() {
        return {
            tituloModal: 'RESTOW',
            formMovimiento: {
                TransacStowageId:null,
                MovStowageReasonId:null, // ID DE CONTROL DE MOTIVO 
                MovStowageReasonDs:"", // OBSERVACION INDICADAPOR EL USUARIO 
                BayCode: null,
                NewPosition: null, 
            },
            slotAll:[],
            bayAll:[],
            ListTransacStowage:[],
            ListMovStowageReason:[]
        }
    }
    //methods
    function registerData(){
        this.$v.formMovimiento.$touch();
        if (this.$v.formMovimiento.$pending || this.$v.formMovimiento.$error) return;

        let StowagePlanningCargoJson = {
            StowagePlanningCargoId : this.containerList[0].StowagePlanningCargoId,
            MovStowageReasonId : this.formMovimiento.MovStowageReasonId,
            MovStowageReasonDs : this.formMovimiento.MovStowageReasonDs,
            NewPosition : this.formMovimiento.NewPosition,
        }

        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar("PUT", "StowagePlanningCargoReason-update", StowagePlanningCargoJson, { root: 'StowagePlanningCargoJson' })
        .then(response => {
            let res = [...response.data.data];

            this.$store.commit('planificacionestiba/messageMutation',  res[0].Response);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADED;
            this.$emit('submited', res[0]);
            this.$store.state.planificacionestiba.modalMovimientoRestow = false;
        }).catch((err) => {
            this.$store.commit('planificacionestiba/messageMutation', err);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
        });
    }
    function anularData(){
        this.$swal.fire({
            title: this.$t('label.anular_movimiento_restow'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#42AA91',
            cancelButtonColor: '#E1373F',
            confirmButtonText: this.$t('button.confirm'),
            cancelButtonText: this.$t('button.cancel')
        }).then((result) => {
            if (result.isConfirmed) {   
                let StowagePlanningCargoJson = {
                    StowagePlanningCargoId : this.containerList[0].StowagePlanningCargoId
                }
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar("PUT", "StowagePlanningCargoRestow-Deactivate", StowagePlanningCargoJson, { root: 'StowagePlanningCargoJson' })
                .then(response => {
                    let res = [...response.data.data];

                    this.$store.commit('planificacionestiba/messageMutation',  res[0].Response);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADED;
                    this.$emit('submited', res[0]);
                    this.$store.state.planificacionestiba.modalMovimientoRestow = false;
                }).catch((err) => {
                    this.$store.commit('planificacionestiba/messageMutation', err);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
            }else {
                // this.$swal('Operación cancelada');
            }
        })
    }
    function cerrarModal(){
        if(this.apiStateLoading)
            return
        this.$store.state.planificacionestiba.modalMovimientoRestow = false;
    }
    function checkBay(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.bayAll.map(function(e) {
                return e.BayCode; 
            }).indexOf(id);
            if(index !== -1){
                this.slotAll = this.bayAll[index].PositionJson;
            }
        }else{
            this.formMovimiento.NewPosition = '';
            this.slotAll = [];
        }
    }
    //computed
    function positionActual(){
        if(this.modalMovimientoRestow){
            if(this.containerList.length !== 0){
                return this.containerList[0].BaySlot;
            }else{
                return '';
            }
        }else{
            return '';
        }
    }
    function optionalPosition(){
        if(this.modalMovimientoRestow){
            if(this.movimientoOptions.FgSLS){
                return true;
            }else if(this.movimientoOptions.FgSS){
                return true;
            }else if(this.movimientoOptions.FgCancel){
                return false;
            }
        }else{
            return true;
        }
    }
    function verificacionRestow(){
        if(this.modalMovimientoRestow){
            return this.containerList[0].MovStowageReasonId !== null;
        }else{
            return false;
        }
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function typeLabel(){
        if(this.modalMovimientoRestow){
            if(this.movimientoOptions.FgSLS){
                return this.$t('label.AUTHORIZED_BY');
            }else if(this.movimientoOptions.FgSS){
                return this.$t('label.AUTHORIZED_BY');
            }else if(this.movimientoOptions.FgCancel){
                return this.$t('label.CANCELADO');
            }
        }else{
            return 'NINGUNO';
        }
    }
    function optionListBayAll(){
        if (this.bayAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.bayAll.map(function(e) {
                chart.push({
                    value: e.BayCode,
                    label: e.BayCode,
                });
            });
            return chart;
        }
    }
    function optionListSlotAll(){
        if (this.slotAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.slotAll.map(function(e) {
                chart.push({
                    value: e.CodPosition,
                    label: e.CodPosition,
                });
            });
            return chart;
        }
    }
    function optionsTransacStowage() {
        if (this.ListTransacStowage.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListTransacStowage.map(async(e) => {
                const variable = e.TransacStowageName.replaceAll('-','_');
                const text = this.$t(`label.MOVIMIENTO.${variable}`);
                chart.push({
                    value: e.TransacStowageId,
                    label: text,
                });
            });
            return chart;
        }
    }
    function optionsMovStowageReason() {
        if (this.ListMovStowageReason.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ListMovStowageReason.map(function(e) {
                if (e.FgActMovStowageReason === true) {
                    chart.push({
                        value: e.MovStowageReasonId,
                        label: this.$i18n.locale == 'en' ? e.MovStowageReasonNameEn : e.MovStowageReasonNameEs,
                    });
                }
            });
            return chart;
        }
    }
    function modalMovimientoRestow(newQuestion){
        if(newQuestion === false){
            this.formMovimiento.TransacStowageId = null;
            this.formMovimiento.MovStowageReasonId = null;
            this.formMovimiento.MovStowageReasonDs = "";
            this.formMovimiento.BayCode = null;
            this.formMovimiento.NewPosition = null; 
            this.$v.$reset();
        }else{
            try {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'StowagePlanningTransacStowage-list', {  }, '').then(responseTransacStowage => {
                    if(responseTransacStowage.status === 200){
                        const data = responseTransacStowage.data.data;
                        this.ListTransacStowage = data;
                        
                        if(this.movimientoOptions.FgSLS){
                            this.formMovimiento.TransacStowageId = data[1].TransacStowageId;
                        }else if(this.movimientoOptions.FgSS){
                            this.formMovimiento.TransacStowageId = data[2].TransacStowageId;
                        }else if(this.movimientoOptions.FgCancel){
                            this.formMovimiento.TransacStowageId = data[0].TransacStowageId;
                        }
                        if(this.formMovimiento.TransacStowageId){
                            this.$http.ejecutar('GET', 'MovStowageReason-by-transac-list', { TransacStowageId: this.formMovimiento.TransacStowageId }, '').then(responseMovStowageReason => {
                                if(responseMovStowageReason.status === 200){
                                    this.$http.ejecutar('GET', 'StowagePlanningFreeVesselBayPos-by-Size', { 
                                        StowagePlanningId:this.planificacionId,
                                        EdiFileId:this.EdiFileId, 
                                        Size:this.containerList[0].size
                                    }, '').then(responseBay => {
                                        if(responseBay.status === 200){
                                            this.bayAll = responseBay.data.data;
                                            this.ListMovStowageReason = responseMovStowageReason.data.data;
                                            
                                            if(this.containerList[0].NewPosition !== null){
                                                let variableIndex  = this.containerList[0].NewPosition.toString();
                                                
                                                const Index = this.bayAll.map(function(e) {
                                                    return e.BayCode; 
                                                }).indexOf(variableIndex.charAt(0)+variableIndex.charAt(1)+variableIndex.charAt(2));
                                                if(Index > -1){
                                                    this.slotAll = this.bayAll[Index].PositionJson;
                                                    this.formMovimiento.BayCode = variableIndex.charAt(0)+variableIndex.charAt(1)+variableIndex.charAt(2);
                                                    const Index2 = this.slotAll.map(function(e) {
                                                        return e.CodPosition; 
                                                    }).indexOf(this.containerList[0].NewPosition);

                                                    if(Index2 > -1){
                                                        this.formMovimiento.NewPosition = this.slotAll[Index2].CodPosition;
                                                    }
                                                }
                                            }

                                            if(this.containerList[0].MovStowageReasonId !== null){
                                                const Index3 = this.ListMovStowageReason.map(function(e) {
                                                    return e.MovStowageReasonId; 
                                                }).indexOf(this.containerList[0].MovStowageReasonId);

                                                if(Index3 > -1){
                                                    this.formMovimiento.MovStowageReasonId = this.ListMovStowageReason[Index3].MovStowageReasonId;
                                                }
                                            }

                                            if(this.containerList[0].MovStowageReasonDs !== null){
                                                this.formMovimiento.MovStowageReasonDs = this.containerList[0].MovStowageReasonDs;
                                            }

                                            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                                        }else{
                                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                        }
                                    }).catch(err => {
                                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('maquina/messageMutation', err);
                                    })
                                }else{
                                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                }
                            }).catch(err => {
                                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                this.$store.commit('maquina/messageMutation', err);
                            });
                        }else{
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                        }
                        
                    }else{
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                }).catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    this.$store.commit('maquina/messageMutation', err);
                });
            } catch (error) {
                this.$store.state.planificacionestiba.apiState = ENUM.ERROR;
                this.$store.commit('planificacionestiba/messageMutation', error);
            }
        }
    }
    export default {    
        name:'modal-movimiento-restow',
        data,
        props: {
            movimientoOptions:{
                type:Object,
                required:true,
                default: () => {}
            },
            containerList:{
                type: Array,
                required: true,
                default: () => [],
            },
        },
        validations(){ return FormMovimiento(this.optionalPosition) },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            }
        },
        methods:{
            registerData,
            anularData,
            cerrarModal,
            checkBay
        },
        computed:{
            optionListBayAll,
            optionListSlotAll,
            positionActual,
            optionsTransacStowage,
            optionsMovStowageReason,
            verificacionRestow,
            typeLabel,
            apiStateLoading,
            apiStateFormLoading,
            optionalPosition,
            ...mapState({
                modalMovimientoRestow: (state) => state.planificacionestiba.modalMovimientoRestow,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
            })
        },
        watch: {
            modalMovimientoRestow,
        }
    }
</script>