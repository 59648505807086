var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('modalListDamageImages',{attrs:{"edit":false,"modal":_vm.ShowModalDamageImageList},on:{"closeModal":function($event){_vm.ShowModalDamageImageList=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"8"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.return'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.return'),\n              placement: 'top',\n            }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":_vm.closeCollapse}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('label.inspectionDamage')+': '+_vm.containerCode))])],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"4"}})],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"fields":_vm.fieldsDamage,"items":_vm.itemsDamage,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingDamage,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.FilesJson.length>0)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.see')+_vm.$t('label.image'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.see')+$t('label.image'),\n              placement: 'top-end',\n            }"}],staticClass:"mx-1",attrs:{"size":"sm","color":"watch"},on:{"click":function($event){_vm.ShowModalDamageImageList=item}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }