<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.transactions')+' - '+$t('label.tablets') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-11 col-lg-3 col-xl-3 text-right', input: 'col-sm-11 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="12" lg="5" xl="5" class="center-field">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-11 col-lg-8 col-xl-8 text-right', input: 'col-sm-11 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,

   
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
            },
            DriverName: '',
            previousDate: new Date(),
            laterDate: new Date(),
            disabledFilterByPreference: false,
            nulo:'', 
            pageSize:'',
            search: '',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let currentDate = new Date();
        let rowData = [];
        let valores = []; 
        valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        let title = this.$t('label.transactions')+' - '+this.$t('label.tablets');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowData, title, valor, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    function getTabletsList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array; 
        this.$http.get("VisitShipmentOrderTransactionReport-by-VisitId", {VisitId: this.Visit})
        .then(response => {
            listado = [...response.data.data];
            let _lang = this.$i18n.locale;
            let nulo = String(listado[0]?.Json);
            if (nulo=="null" || listado ==''){
                return false;
            }
            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                Nro: listado.Nro ? listado.Nro : '',
                OrderCode: listado.OrderCode ? listado.OrderCode : '',
                CarrierAlias: listado.CarrierAlias ? listado.CarrierAlias : '',
                LicensePlate: listado.LicensePlate ? listado.LicensePlate : '',
                ImplementAlias: listado.ImplementAlias ? listado.ImplementAlias : '',
                DriverName: listado.DriverName ? listado.DriverName : '',
                TransactionActivity: _lang=='en' ? listado.TransactionActivityEn : listado.TransactionActivityEs,
                CraneName: listado.CraneName ? listado.CraneName : 'N/A',
                VesselHoldName: listado.VesselHoldName ? listado.VesselHoldName : 'N/A',
                TransactionTurnName: _lang=='en' ? listado.TransactionTurnNameEn : listado.TransactionTurnNameEs,
                VisitLapseCode: listado.VisitLapseCode ? listado.VisitLapseCode : '',
                DeviceCode: listado.DeviceCode ? listado.DeviceCode : '',
                CompanyName: listado.CompanyName ? listado.CompanyName : '',
                TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
                TransactionBegin: listado.TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado.TransactionBegin) : 'N/A',
                TransactionFinish: listado.TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado.TransactionFinish) : 'N/A',
                TransactionDate: listado.TransactionDate ? DateFormater.formatDateTimeWithSlash(listado.TransactionDate) : 'N/A',
                TransaRegDate: listado.TransaRegDate ? DateFormater.formatDateTimeWithSlash(listado.TransaRegDate) : 'N/A',
            }));
            
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

//computed
    function columnDefs(){
        let columnDefs = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderCode",
                    headerName: this.$t('label.noOrder'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "CarrierAlias",
                    headerName: this.$t('label.transport'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "LicensePlate",
                    headerName: this.$t('label.vehicle'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "ImplementAlias",
                    headerName: this.$t('label.Gamela'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'gb-cell',
                    //pinned: 'left'
                },
                {
                    field: "DriverName",
                    headerName: this.$t('label.driver'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransactionActivity",
                    headerName: this.$t('label.transactionType'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 200,
                },
                {
                    field: "CraneName",
                    headerName: this.$t('label.crane'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: this.$t('label.hold'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "TransactionTurnName",
                    headerName: this.$t('label.turn'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VisitLapseCode",
                    headerName: this.$t('label.lapse'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DeviceCode",
                    headerName: this.$t('label.device'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "CompanyName",
                    headerName: this.$t('label.company'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransaLogin",
                    headerName: this.$t('label.user'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransactionBegin",
                    headerName: this.$t('label.from'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 200,
                },
                {
                    field: "TransactionFinish",
                    headerName: this.$t('label.to'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 200,
                },
                {
                    field: "TransactionDate",
                    headerName: this.$t('label.transactionDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 200,
                },
                {
                    field: "TransaRegDate",
                    headerName: this.$t('label.registerDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 180,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },
            ]
            return columnDefs;
    }
    export default {
        name: "orders-by-vehicle",
        data,
        beforeMount,
        props: {CurrentModuleId: Boolean},
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onBtnExport,
            onGridReady,
            getTabletsList,
            onQuickFilterChanged,
            onPageSizeChanged,
        },
        computed:{
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },

        watch:{
            dropItemReport: function (newValue) {
                if (newValue==50&&this.Visit!=''&&this.CurrentModuleId) {
                    this.getTabletsList();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style lang="scss">

    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }

    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

</style>