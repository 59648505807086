
<template>
    <div class="container-fluid  ">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start  ">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.summaryCraneLoadType') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end  ">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm" 
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>      
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"   
                    v-model="search"           
                >
                
                </CInput>  
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CSelect
                    :value.sync="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    v-model.trim="preference"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="getTypeLoadPerCraneSummary"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    
                />
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            formatedItems: [],
            columnDefs: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
            preference: 1,
            preferenceOptions: [],
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            nulo:'',
            search: '',
            pageSize: '',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            for (let index = 1; index < rowData.length; index++) {
                rowData[index].GrossTonDeck = formatMilDecimal(parseFloat(rowData[index].GrossTonDeck).toFixed(2));
                rowData[index].GrossTonHold = formatMilDecimal(parseFloat(rowData[index].GrossTonHold).toFixed(2));
            }
            await this.getPdf(rowData,this.$t('label.summaryCraneLoadType'), 'TypeLoadPerCraneSummary');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false; 
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length != 0) {
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.summaryCraneLoadType'),valor, this.CraneAlias,'N/A','N/A');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;    
    }

    async function getTypeLoadPerCraneSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let Object = {};
        let i = 0;

        let SummaryJson = {
            VisitId: this.Visit,
            VisitCraneId: this.VisitCraneId,
            UserPreference: this.preference
        };

        await this.$http.post("VisitCargoTypeByCraneSummary", SummaryJson, { root: 'SummaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            
            Object['section'] = 'totalLoad';
            Object['Nro'] = '';
            Object['ContainerType'] = '';
            Object['GrossTonDeck'] = 'DECK';
            Object['GrossTonHold'] = 'HOLD';
            Object['TotalDeck'] = 'DECK';
            Object['TotalHold'] = 'HOLD';
            for (let index = 0; index < listado[0].DeckJson.length; index++) {
                Object['Deck_'+index] = 'DECK';
                i++;
            };
            Object['DeckMax'] = i;
            i = 0;
            for (let index = 0; index < listado[0].HoldJson.length; index++) {
                Object['Hold_'+index] = 'HOLD';
                i++;
            };
            Object['HoldMax'] = i;
            this.formatedItems.push(Object);
            listado.map((item) => {
                Object = {};
                Object['Nro'] = item.IdX ? item.IdX : '';
                Object['ContainerType'] = item.ContainerType ? item.ContainerType : '';
                Object['GrossTonDeck'] = item.GrossTonDeck ? item.GrossTonDeck : 0;
                Object['GrossTonHold'] = item.GrossTonHold ? item.GrossTonHold : 0;
                Object['TotalDeck'] = item.TotalDeck ? item.TotalDeck : 0;
                Object['TotalHold'] = item.TotalHold ? item.TotalHold : 0;
                Object['DeckJson'] = item.DeckJson ? item.DeckJson : [];
                Object['HoldJson'] = item.HoldJson ? item.HoldJson : [];
                for (let index = 0; index < item.DeckJson.length; index++) {
                    Object['Deck_'+index] = item.DeckJson[index].CantDeck ? item.DeckJson[index].CantDeck : 0;
                };
                for (let index = 0; index < item.HoldJson.length; index++) {
                    Object['Hold_'+index] = item.HoldJson[index].CantHold ? item.HoldJson[index].CantHold : 0;
                };
                this.formatedItems.push(Object);
            });
            this.columnDefs = this.ColumnDefs();
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {            
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {            
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
        this.loadingOverlay = true;
        await this.getTypeLoadPerCraneSummary();
        this.loadingOverlay = false;
    }

    function getRowClass(params) {
        if (params.node.data.DischargePort=="TOTAL") {
            return 'bg-edit font-weight-bold'
        }

        if (params.node.data.section=="totalLoad") {
            return 'bg-secondary font-weight-bold'
        }
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize ="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.VisitCraneId= '';
        this.preference= 1;
        await this.getPreferenceList();
        await this.getCraneList();
        await this.getTypeLoadPerCraneSummary();
        this.$emit('child-refresh',true);
    }

    //computed
    function isTotal(params) {
        return params.data.section === 'totalLoad';
    }

    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }
    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }
    function ColumnDefs(){
            let column = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true,
                },
                {
                    field: "ContainerType",
                    headerName: this.$t('label.size')+' (FT)',
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
            ];
            if (this.formatedItems.length != 0) {
                for (let a = 0; a < this.formatedItems[1].DeckJson.length; a++) {
                column.push({
                    field: 'Deck_'+a,
                    headerName: this.formatedItems[1].DeckJson[a].CraneAlias,
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    filter: "agNumberColumnFilter",
                    colSpan: (params) => {
                        if (Header(params) && a == 0) {
                            return this.formatedItems[1].DeckJson.length+2;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (Header(params)) {
                            return 'center-cell-especial text-color-negrita legend-color-1 text-white';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                });
            }
            }
            column.push(
                {
                    field: 'TotalDeck',
                    headerName: this.$t('label.total'),
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    filter: "agNumberColumnFilter",
                },
                {
                    field: 'GrossTonDeck',
                    headerName: this.$t('label.gross')+' (TON)',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        if(params.value !== null && params.value !== '' && !Header(params)){    
                            let variable = 0.00;
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                            return variable;
                        }else{
                            return params.value
                        }
                    },
                    filter: "agNumberColumnFilter",
                }
            );
            if (this.formatedItems.length != 0) {
                for (let a = 0; a < this.formatedItems[1].HoldJson.length; a++) {
                    column.push({
                        field: 'Hold_'+a,
                        headerName: this.formatedItems[1].HoldJson[a].CraneAlias,
                        headerClass: 'center-cell-especial',
                        lockPosition: true,
                        filter: "agNumberColumnFilter",
                        colSpan: (params) => {
                            if (Header(params) && a == 0) {
                                return this.formatedItems[1].HoldJson.length+2;
                            } else {
                                return 1;
                            }
                        },
                        cellClass: (params) => {
                            if (Header(params)) {
                                return 'center-cell-especial text-color-negrita legend-color-2 text-white';
                            } else {
                                return 'center-cell-especial';
                            }
                        },
                    });
                }
            }
            column.push(
                {
                    field: 'TotalHold',
                    headerName: this.$t('label.total'),
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    filter: "agNumberColumnFilter",
                },
                {
                    field: 'GrossTonHold',
                    headerName: this.$t('label.gross')+' (TON)',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                        if(params.value !== null && params.value !== '' && !Header(params)){ 
                            let variable = 0.00;   
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                            return variable;
                        }else{
                            return params.value;
                        }
                    },
                    filter: "agNumberColumnFilter",
                }
            );
            return column;
    }

    function Header(params) {
        return params.data.section === 'totalLoad';
    }
    
    export default {
        name: "type-load-per-crane-summary",
        data,
        beforeMount,
        mounted,
        mixins: [ReportesVisitas, AgGrid],
        props: ['value'],
        methods:{
            ColumnDefs,
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getTypeLoadPerCraneSummary,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            getPreferenceList,
            getCraneList, 
            filterCrane,
            refreshComponent,
        },
        computed:{
            Header,
            isTotal,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,    
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
            }),
            preferenceListFormatted,
            craneListFormatted,
        },
        components:{
            
        },
        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==38) {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>
<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>