<template>
  <div>
    <CCollapse :show="activeItem(0)">
      <CButton 
          color="danger"
          class="mr-1 d-none"
          @click="getPdf()"
          >
          <CIcon name="cil-file-pdf"/>&nbsp; PDF 
      </CButton>
    </CCollapse>
    <CCollapse :show="activeItem(23)">
        <containerlist />
    </CCollapse>
    <CCollapse :show="activeItem(24)">
        <imdgList ></imdgList>
    </CCollapse>
    <CCollapse :show="activeItem(25)">
        <restowlist />
    </CCollapse>
    <CCollapse :show="activeItem(26)">
        <reefer-list ></reefer-list>
    </CCollapse>
    <CCollapse :show="activeItem(27)">
        <loadlist />
    </CCollapse>
    <CCollapse :show="activeItem(28)">
        <canceled-list />
    </CCollapse>
    <CCollapse :show="activeItem(29)">
        <discharge-list />
    </CCollapse>
    <CCollapse :show="activeItem(30)">
        <vgm-detail />
    </CCollapse>
    <CCollapse :show="activeItem(31)">
        <containerByTypeStatus />
    </CCollapse>
    <CCollapse :show="activeItem(32)">
        <containerByTypeSummary />
    </CCollapse>
    <CCollapse :show="activeItem(33)">
        <containerByPortLineSummary />
    </CCollapse>
    <CCollapse :show="activeItem(34)">
        <portSummary />
    </CCollapse>
    <CCollapse :show="activeItem(35)">
        <imdgContainerSummary />
    </CCollapse>
    <CCollapse :show="activeItem(36)">
        <oversizedList />
    </CCollapse>
    <CCollapse :show="activeItem(38)">
        <typeLoadPerCraneSummary />
    </CCollapse>
    <CCollapse :show="activeItem(39)">
        <transactionReversalsList />
    </CCollapse>
    <CCollapse :show="activeItem(41)">
        <inspection-done />
    </CCollapse>

    <CCollapse :show="activeItem(50)">
        <transactions-tablets />
    </CCollapse>
    <CCollapse :show="activeItem(52)">
        <orders-by-carrier />
    </CCollapse>
    <CCollapse :show="activeItem(53)">
        <OdersByDriver />
    </CCollapse>
    <CCollapse :show="activeItem(54)">
        <orders-by-vehicle />
    </CCollapse>
    <CCollapse :show="activeItem(55)">
        <orders-by-scrap-container />
    </CCollapse>
    <CCollapse :show="activeItem(56)">
        <orders-by-yard />
    </CCollapse>
    <CCollapse :show="activeItem(65)">
        <OrdersByWeight />
    </CCollapse>
    <CCollapse :show="activeItem(70)">
        <OrdersNotWeight />
    </CCollapse>
    <CCollapse :show="activeItem(72)">
        <OrdersByLapse />
    </CCollapse>
    <CCollapse :show="activeItem(66)">
        <BinsReturned />
    </CCollapse>
    <CCollapse :show="activeItem(57)">
        <truck-scale />
    </CCollapse>
    <CCollapse :show="activeItem(60)">
        <sumaries-daily-report />
    </CCollapse>
    <CCollapse :show="activeItem(61)">
        <SumariesFinalReport />
    </CCollapse>
    <CCollapse :show="activeItem(62)">
        <transport-summary />
    </CCollapse>
    <CCollapse :show="activeItem(63)">
        <yard-client-summary />
    </CCollapse>
    <CCollapse :show="activeItem(64)">
        <vehicle-bin-summary />
    </CCollapse>
    <CCollapse :show="activeItem(67)">
        <bin-weighing-summary />
    </CCollapse>
    <CCollapse :show="activeItem(73)">
        <InactiveMovements />
    </CCollapse>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";

//LIST|
import Containerlist from './listas/container-list';
import imdgList from './listas/imdg-list';
import TransactionsTablets from './transactions-tablets';

import OrdersByCarrier from './listas/orders-by-carrier';
import OdersByDriver from './listas/orders-by-driver';
import OrdersByVehicle from './listas/orders-by-vehicle';
import OrdersByScrapContainer from './listas/orders-by-scrap-container';
import OrdersByYard from './listas/orders-by-yard';
import OrdersByWeight from './listas/orders-by-weight';
import OrdersNotWeight from './listas/orders-not-weight';
import OrdersByLapse from './listas/shipment-order-by-lapse';
import TruckScale from './listas/truck-scale';
import BinsReturned from './listas/bins-returned';
import TransportSummary from './listas/transport-summary';
import yardClientSummary from './listas/yard-client-summary';
import VehicleBinSummary from './listas/vehicle-bin-summary';
import SumariesDailyReport from './listas/sumaries-daily-report';
import SumariesFinalReport from './listas/sumay-final-report';
import binWeighingSummary from './listas/bin-weighing-summary/bin-weighing-summary';
import InactiveMovements from './listas/inactive-movements';

import VgmDetail from './listas/vgm-detail';
import Restowlist from './listas/restow-list';
import Loadlist from './listas/load-list';
import CanceledList from './listas/canceled-list';
import DischargeList from './listas/discharge-list';
import ReeferList from './listas/reefer-list.vue';
import containerByTypeSummary from './listas/container-by-type-summary';
import containerByPortLineSummary from './listas/container-by-port-line-summary';
import portSummary from './listas/port-summary';
import imdgContainerSummary from './listas/imdg-container-summary';
import containerByTypeStatus from './listas/container-type-status-summary';
import oversizedList from './listas/oversized-list';
import typeLoadPerCraneSummary from './listas/type-load-per-crane-summary';
import transactionReversalsList from './listas/transaction-reversals-list';
import InspectionDone from './inspection-done';

function data() {
  return {
    items: [],
    loadingTable: false,
    loadingOverlay: false,
    showModal: false,
    refrescarTabla: false,
    supplieItem: '',
    VisitId: '',
    anio: '2021',
    mes: '9',
    newUnit: false,
  };
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

async function getPdf() {
    const documento = "REPORT OF DAMAGE";
    
    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let resta = 0;
    let tcentro = 300;
    let ancho = 535;
    let anchoReal = 595;
    let font = 8;
    let largo = 842;
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [anchoReal, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        let fecha = new Date().toLocaleString();

        doc.setFontSize(8);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed') + ": " + fecha, 40, largo-40, {
                align: 'left'
            });
            doc.text(this.$t('label.user') + ": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 30, largo-40, {
                align: 'right'
            });
            doc.text(this.$t('label.pageReport') + " "+ String(i) + this.$t('label.ofReport') + " "+ String(pageCount), doc.internal.pageSize.width - 20, largo-25, {
                align: 'right'
            })
        }
    }
    //LINEA 1------------------------------------------------------------------------------
    //CUADRO 1
    doc.setFontSize(9);
    doc.rect(30, 40, 155, 80);
    doc.addImage(myBase64Image, "JPEG", 33, 43, 149, 73);
    doc.rect(185, 40, 240, 80)
    doc.rect(395, 50, 20, 15)
    doc.text("DAMAGE REPORTS/ Reporte  de daños", tcentro-25, 60, 'center');
    doc.rect(395, 85, 20, 15)
    doc.text("SEAL DISCREPANCE/ Discrepancia de Precinto", tcentro-10, 96, 'center');
    doc.rect(425, 40, 140, 40)
    doc.text("DAY/Dia:", tcentro+147, 49, 'center');
    doc.text("MONTH/Mes:", tcentro+155, 63, 'center');
    doc.text("YEAR/Año:", tcentro+150, 77, 'center');
    doc.setFontSize(8);
    doc.rect(425, 40, 140, 80)
    doc.text("NRO-OCKVRP-2021- ________", tcentro+185, 105, 'center');
    //LINEA 2------------------------------------------------------------------------------
    doc.setFontSize(9);
    doc.setFillColor("#FCD5B4");
    doc.rect(30, 120, ancho, 20,"FD");
    doc.text("REPORT OF DAMAGE IN CONTAINERS AND SEALED DISCREPANCIES", tcentro, 133, 'center');
    //LINEA 3------------------------------------------------------------------------------
    doc.setFontSize(7.5);
    doc.setFillColor("#CCC");
    doc.rect(30, 140, ancho/5, 30);
    doc.text("VESSEL/ Buque:", 33, 150, 'left');
    doc.rect(30+(ancho/5), 140, ancho/5, 30);
    doc.text("VOYAGE/ Viaje:", 33+(ancho/5), 150, 'left');
    doc.rect(30+((ancho/5)*2), 140, (ancho/5)-30, 30);
    doc.text("PIER N°/ N° Muelle:", 33+((ancho/5)*2), 150, 'left');
    doc.rect(((ancho/5)*3), 140, ancho/5, 30);
    doc.text("SLOT/BAY/ Posición/Baya:", ((ancho/5)*3)+3, 150, 'left');
    doc.rect(((ancho/5)*4), 140, (ancho/5)+30, 30);
    doc.text("DATE HOUR AT OPRTS/ Fecha-Hora", ((ancho/5)*4)+3, 150, 'left');
    doc.text("de las op.", ((ancho/5)*4)+3, 162, 'left');
    //LINEA 4------------------------------------------------------------------------------
    doc.rect(30, 170, (ancho/4)-10, 30);
    doc.text("DISCH PORT/ Puerto de descarga:", 33, 180, 'left');
    doc.rect(20+(ancho/4), 170, (ancho/4)-10, 30);
    doc.text("LOAD PORT/ Puerto de embarque:", 23+(ancho/4), 180, 'left');
    doc.rect(10+((ancho/4)*2), 170, (ancho/4)+17, 30);
    doc.text("BL N°:", 13+((ancho/4)*2), 180, 'left');
    doc.rect(27+((ancho/4)*3), 170, (ancho/4)+3, 30);
    doc.text("ORIGINAL SEAL/ Precinto original:", 30+((ancho/4)*3), 180, 'left');
    //LINEA 5------------------------------------------------------------------------------
    doc.rect(30, 200, (ancho/4)-10, 30);
    doc.text("NEW SEAL/ Nuevo precinto:", 33, 210, 'left');
    doc.rect(20+(ancho/4), 200, ancho-(ancho/4)+10, 30);
    doc.text("CONDITION OF THE SEAL/ Condición del precinto:", 23+(ancho/4), 210, 'left');
    doc.text("Intact/ Intacto", 25+(ancho/4), 222, 'left');
    doc.rect(80+(ancho/4), 212, 20, 15)
    doc.text("Broken/ Roto", 105+(ancho/4), 222, 'left');
    doc.rect(159+(ancho/4), 212, 20, 15)
    doc.text("Sin P.", 185+(ancho/4), 222, 'left');
    doc.rect(206+(ancho/4), 212, 20, 15)
    doc.text("Different Doc/ Diferente Doc", 235+(ancho/4), 222, 'left');
    doc.rect(332+(ancho/4), 212, 20, 15)
    doc.text("OTHER/ Otro", 360+(ancho/4), 222, 'left');
    doc.rect(408+(ancho/4), 212, 20, 15)
    //LINEA 6------------------------------------------------------------------------------
    doc.rect(30, 230, ancho/5, 40);
    doc.text("CONTAINER NUMBER/Nro de", 33, 240, 'left');
    doc.text("contenedor:", 33, 250, 'left');
    doc.rect(30+(ancho/5), 230, ancho/5, 40);
    doc.text("SIZE/ Tamaño:", 33+(ancho/5), 240, 'left');
    doc.text("20'", 33+(ancho/5), 255, 'left');
    doc.rect(45+(ancho/5), 248, 20, 15)
    doc.text("40'", 70+(ancho/5), 255, 'left');
    doc.rect(82+(ancho/5), 248, 20, 15)
    doc.text("__", 105+(ancho/5), 260, 'left');
    doc.rect(115+(ancho/5), 248, 20, 15)
    doc.rect(30+((ancho/5)*2), 230, (ancho/5)-30, 40);
    doc.text("CONDITION/", 33+((ancho/5)*2), 240, 'left');
    doc.text("Condición:", 33+((ancho/5)*2), 250, 'left');
    doc.text("Full", 33+((ancho/5)*2), 263, 'left');
    doc.rect(47+((ancho/5)*2), 253, 20, 15)
    doc.text("Mty", 71+((ancho/5)*2), 263, 'left');
    doc.rect(84+((ancho/5)*2), 253, 20, 15)
    doc.rect(((ancho/5)*3), 230, (ancho/3)+66, 40);
    doc.text("TYPE/ Tipo:", ((ancho/5)*3)+3, 240, 'left');
    doc.text("DC__   HC__   FR__   FB__   OT__   PT__   VT__   TK__   RF__", ((ancho/5)*3)+8, 255, 'left');
    //LINEA 7-----------------------------------------------------------------------------
    doc.rect(30, 270, (ancho/3)-10, 50);
    doc.text("OBSERVATION OF THE SEAL/ Observación del \nprecinto:", 33, 280, 'left');
    doc.rect(30, 270, ((ancho/3)*3), 50);
    doc.text("TYPE OF OPERATION/ Tipo de operación:", (ancho/3)+23, 280, 'left');
    doc.text("Before/Antes", (ancho/3)+23, 293, 'left');
    doc.rect((ancho/3)+70, 285, 15, 10)
    doc.text("Discharge/Descarga", (ancho/3)+115, 293, 'left');
    doc.rect((ancho/3)+187, 285, 15, 10)
    doc.text("Shift aboard/Cambio a bordo", (ancho/3)+225, 293, 'left');
    doc.rect((ancho/3)+327, 285, 15, 10)
    doc.text("During/Durante", (ancho/3)+23, 313, 'left');
    doc.rect((ancho/3)+78, 305, 15, 10)
    doc.text("Load/Carga", (ancho/3)+103, 313, 'left');
    doc.rect((ancho/3)+145, 305, 15, 10)
    doc.text("Shift ashore/Desplazarse a tierra", (ancho/3)+173, 313, 'left');
    doc.rect((ancho/3)+285, 305, 15, 10)
    doc.text("Other/Otro", (ancho/3)+325, 313, 'left');
    doc.rect((ancho/3)+365, 305, 15, 10)
    //LINEA 8------------------------------------------------------------------------------
    doc.rect(30, 320, ancho, 20);
    doc.text("DAMAGE CODE / CODIGO DE DAÑOS", tcentro, 333, 'center');
    //LINEA 9------------------------------------------------------------------------------
    doc.rect(30, 340, (ancho/8), 30);
    doc.text("C:\n CUT/Corte", 65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*2)+5, 30);
    doc.text("PH:\n Pin Hole/Agujereado", (ancho/8)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*3), 30);
    doc.text("M: Missing/\n Desaparecido", ((ancho/8)*2)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*4), 30);
    doc.text("CI: Caved in/\n Hundido en", ((ancho/8)*3)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*5), 30);
    doc.text("RU: \n Rusted/Oxidado", ((ancho/8)*4)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*6), 30);
    doc.text("R: \n Ripped/Rasgado", ((ancho/8)*5)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*7)+5, 30);
    doc.text("BO: \n Bulgedout/Abultado", ((ancho/8)*6)+65, 352, 'center');
    doc.rect(30, 340, ((ancho/8)*8), 30);
    doc.text("PA: Patched/\n Parcheado", ((ancho/8)*7)+65, 352, 'center');
    //LINEA 10------------------------------------------------------------------------------
    doc.rect(30, 370, (ancho/8), 30);
    doc.text("BR:\n Broken/Roto", 65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*2)+5, 30);
    doc.text("D:\n Dented/Abollado", (ancho/8)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*3), 30);
    doc.text("BO: Bad odor/\n Mal olor", ((ancho/8)*2)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*4), 30);
    doc.text("DI: Distorted\n Distorsionado", ((ancho/8)*3)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*5), 30);
    doc.text("BE: \n Bent/Doblado", ((ancho/8)*4)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*6), 30);
    doc.text("L: \n Leak/Filtración", ((ancho/8)*5)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*7)+5, 30);
    doc.text("H: \n Holed/Agujereado", ((ancho/8)*6)+65, 382, 'center');
    doc.rect(30, 370, ((ancho/8)*8), 30);
    doc.text("O: Others/\n Otros", ((ancho/8)*7)+65, 382, 'center');
    //LINEA 11------------------------------------------------------------------------------
    doc.rect(30, 400, ancho, 200);
    doc.text("INDICATE THE DAMAGE AREA OF THE CONTAINER/ Indique la zona de daños del contenedor:", 33, 415, 'left');
    doc.addImage(myBase64Image, "JPEG", 33, 415, ancho-33, 180);
    doc.text("See overleaf for codes", tcentro, 595, 'center');
    //LINEA 12------------------------------------------------------------------------------
    doc.rect(30, 600, ancho, 20);
    doc.text("Brief description of damage / Breve descripción de daños", tcentro, 613, 'center');
    //LINEA 13------------------------------------------------------------------------------
    doc.rect(30, 620, (ancho/3), 30);
    doc.text("Hurt / Daño", 33, 630, 'left');
    doc.rect(30, 620, (ancho/3)*2, 30);
    doc.text("Location / Localización:", (ancho/3)+33, 630, 'left');
    doc.rect(30, 620, (ancho/3)*3, 30);
    doc.text("Dimensions / Dimensiones:", ((ancho/3)*2)+33, 630, 'left');
    //LINEA 14------------------------------------------------------------------------------
    doc.rect(30, 650, ancho, 100);
    doc.text("OBSERVATION / OBSERVACIÓN", 33, 660, 'left');
    //LINEA 15------------------------------------------------------------------------------
    doc.rect(30, 750, (ancho/3), 40);
    doc.text("Operator - Dispatcher / Operador - Despachador", 33, 760, 'left');
    doc.rect(30, 750, (ancho/3)*2, 40);
    doc.text(this.user.CompanyName+" Supervisor/ Supervisor por\n"+" "+this.user.CompanyName, (ancho/3)+33, 760, 'left');
    doc.rect(30, 750, (ancho/3)*3, 40);
    doc.text("Received by / Recibido por:", ((ancho/3)*2)+33, 760, 'left');


    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}


async function llenado () {
  this.items = [];
  
  await this.$http.get("VisitReportFinalOperations-by-VisitId", { VisitId: this.Visit })
  .then(response => {
    this.items = [...response.data.data][0];
    let bandera = false;

    let cuadro1 = [];
    let cuadro2 = [];
    let cuadro3 = [];
    //CUADRO 1
    this.items.TpCargoJson.forEach(x => {
      cuadro1.push({
        RESUMEN: x.TpCargoDetailCode,
        DESCARGA_LLENOS: x.DischargeFull,
        DESCARGA_VACIOS: x.DischargeEmpty,
        EMBARQUE_LLENOS: x.LoadFull,
        EMBARQUE_VACIOS: x.LoadEmpty,
        MOVILIZADOS_RETORNADOS: x.MovilizedReturned,
        TOTAL: x.Total,  
      });
      bandera = true;
    });
    
    if (bandera) {
      //TOTAL CUADRO 1
      cuadro1.push({
        RESUMEN: "TOTAL",
        DESCARGA_LLENOS: this.items.TpCargoJson[0].TpCargoTotalJson[0].DischargeFull,
        DESCARGA_VACIOS: this.items.TpCargoJson[0].TpCargoTotalJson[0].DischargeEmpty,
        EMBARQUE_LLENOS: this.items.TpCargoJson[0].TpCargoTotalJson[0].LoadFull,
        EMBARQUE_VACIOS: this.items.TpCargoJson[0].TpCargoTotalJson[0].LoadEmpty,
        MOVILIZADOS_RETORNADOS: this.items.TpCargoJson[0].TpCargoTotalJson[0].MovilizedReturned,
        TOTAL: this.items.TpCargoJson[0].TpCargoTotalJson[0].Total,  
      });

      cuadro1.push({
        RESUMEN: "TEUS",
        DESCARGA_LLENOS: this.items.TpCargoJson[0].TpCargoTeusJson[0].DischargeFull,
        DESCARGA_VACIOS: this.items.TpCargoJson[0].TpCargoTeusJson[0].DischargeEmpty,
        EMBARQUE_LLENOS: this.items.TpCargoJson[0].TpCargoTeusJson[0].LoadFull,
        EMBARQUE_VACIOS: this.items.TpCargoJson[0].TpCargoTeusJson[0].LoadEmpty,
        MOVILIZADOS_RETORNADOS: this.items.TpCargoJson[0].TpCargoTeusJson[0].MovilizedReturned,
        TOTAL: this.items.TpCargoJson[0].TpCargoTeusJson[0].Total,  
      });

      cuadro1.push({
        RESUMEN: this.$t('label.weight'),
        DESCARGA_LLENOS: this.items.TpCargoJson[0].TpCargoWeightJson[0].DischargeFull,
        DESCARGA_VACIOS: this.items.TpCargoJson[0].TpCargoWeightJson[0].DischargeEmpty,
        EMBARQUE_LLENOS: this.items.TpCargoJson[0].TpCargoWeightJson[0].LoadFull,
        EMBARQUE_VACIOS: this.items.TpCargoJson[0].TpCargoWeightJson[0].LoadEmpty,
        MOVILIZADOS_RETORNADOS: this.items.TpCargoJson[0].TpCargoWeightJson[0].MovilizedReturned,
        TOTAL: this.items.TpCargoJson[0].TpCargoWeightJson[0].Total,  
      });
    }  
    
    //CUADRO 2
    bandera=false;
    cuadro2.push({
        LINEA: 'RESUMEN',
        CANTIDAD_DL: 'DESCARGA LLENOS',
        TEUS_DL: '',
        CANTIDAD_DV: 'DESCARGA VACIOS',
        TEUS_DV: '',
        CANTIDAD_ML: 'MOVILIZADOS LLENOS',
        TEUS_ML: '',
        CANTIDAD_T: 'TOTALES',
        TEUS_T: '',
    });
    
    let arrtotales = [0,0,0,0,0,0,0,0];
    this.items.LinesSummary1Json.forEach(x => {
      arrtotales[0]+= x.DischargeFullCant;
      arrtotales[1]+= x.DischargeFullTeus;
      arrtotales[2]+= x.DischargeEmptyCant;
      arrtotales[3]+= x.DischargeEmptyTeus;
      arrtotales[4]+= x.MovilizedFullCant;
      arrtotales[5]+= x.MovilizedFullTeus;
      arrtotales[6]+= x.TotalCant;
      arrtotales[7]+= x.TotalTeus;
      cuadro2.push({
        LINEA: x.ShippingLineCode,
        CANTIDAD_DL: x.DischargeFullCant,
        TEUS_DL: x.DischargeFullTeus,
        CANTIDAD_DV: x.DischargeEmptyCant,
        TEUS_DV: x.DischargeEmptyTeus,
        CANTIDAD_ML: x.MovilizedFullCant,
        TEUS_ML: x.MovilizedFullTeus,
        CANTIDAD_T: x.TotalCant,
        TEUS_T: x.TotalTeus,
      });
      bandera = true;
    });
    //TOTAL CUADRO 2
    if (bandera) {
      cuadro2.push({
        LINEA: "TOTAL",
        CANTIDAD_DL: arrtotales[0],
        TEUS_DL: arrtotales[1],
        CANTIDAD_DV: arrtotales[2],
        TEUS_DV: arrtotales[3],
        CANTIDAD_ML: arrtotales[4],
        TEUS_ML: arrtotales[5],
        CANTIDAD_T: arrtotales[6],
        TEUS_T: arrtotales[7],
      });
    }

    //CUADRO 3
    bandera=false;
    cuadro3.push({
        LINEA: 'RESUMEN',
        CANTIDAD_DL: 'EMBARQUE LLENOS',
        TEUS_DL: '',
        CANTIDAD_DV: 'EMBARQUE VACIOS',
        TEUS_DV: '',
        CANTIDAD_ML: 'MOVILIZADOS VACIOS',
        TEUS_ML: '',
        CANTIDAD_T: 'TOTALES',
        TEUS_T: '',
    });
    
    arrtotales = [0,0,0,0,0,0,0,0];
    this.items.LinesSummary2Json.forEach(x => {
      arrtotales[0]+= x.LoadFullCant;
      arrtotales[1]+= x.LoadFullTeus;
      arrtotales[2]+= x.LoadEmptyCant;
      arrtotales[3]+= x.LoadEmptyTeus;
      arrtotales[4]+= x.MovilizedEmptyCant;
      arrtotales[5]+= x.MovilizedEmptyTeus;
      arrtotales[6]+= x.TotalCant;
      arrtotales[7]+= x.TotalTeus;
      cuadro3.push({
        LINEA: x.ShippingLineCode,
        CANTIDAD_DL: x.LoadFullCant,
        TEUS_DL: x.LoadFullTeus,
        CANTIDAD_DV: x.LoadEmptyCant,
        TEUS_DV: x.LoadEmptyTeus,
        CANTIDAD_ML: x.MovilizedEmptyCant,
        TEUS_ML: x.MovilizedEmptyTeus,
        CANTIDAD_T: x.TotalCant,
        TEUS_T: x.TotalTeus,
      });
      bandera = true;
    });
    //TOTAL CUADRO 3
    if (bandera) {
      cuadro3.push({
        LINEA: "TOTAL",
        CANTIDAD_DL: arrtotales[0],
        TEUS_DL: arrtotales[1],
        CANTIDAD_DV: arrtotales[2],
        TEUS_DV: arrtotales[3],
        CANTIDAD_ML: arrtotales[4],
        TEUS_ML: arrtotales[5],
        CANTIDAD_T: arrtotales[6],
        TEUS_T: arrtotales[7],
      });
    }
    
    let rowData = [
    {
      cuadro1: cuadro1,
      cuadro2: cuadro2,
      cuadro3: cuadro3,
    }];
    
    this.reporteExcel(rowData[0],'INFORME FINAL DE OPERACIONES');
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
}

async function reporteExcel(rowData, titulo) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    //INFO BUQUE
    let tituloExcel = "";
    
    if(this.itinerarySelected.Eta!=undefined) tituloExcel+=", ETA: "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    if(this.itinerarySelected.Etd!=undefined) tituloExcel+=", ETD: "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
    if(this.itinerarySelected.Ata!=undefined) tituloExcel+=", ATA: "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    if(this.itinerarySelected.Atd!=undefined) tituloExcel+=", ATD: "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);

    tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+tituloExcel+" - Viaje: "+this.itinerarySelected.Voyage;
  
    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;

    columnsDefs = Object.keys(rowData.cuadro1[0]);
    keyColumns = columnsDefs;
    y = 15;
    bandera = false;

    if (y < 10) letra = 8;
    worksheet.mergeCells('A1:A1');
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:A1');

    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;

    worksheet.mergeCells("B1:" + numeroLetra(y) + "1");

    const customCell = worksheet.getCell("B1");
    const row = worksheet.getRow(1);
    row.height = 60;
    customCell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell.note = {
        margins: {
            insetmode: 'auto',
            inset: [10, 10, 5, 5]
        }
    }
    customCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        },
    }
    customCell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
    customCell.value = tituloExcel;

    worksheet.mergeCells("A2:O2");
    const customCell2 = worksheet.getCell("A2");
    customCell2.height = 50;
    customCell2.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'cccccc'
        }
    }
    customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell2.value = titulo;
    customCell2.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    }

    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //headerRow.height = 50;

    //CUADRO 1
    let i = 0;
    columnsDefs.map(function (data) {
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        cell.value = data.replace("_"," ");
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })

    rowData.cuadro1.map(async function (data) {
      let dataRow = worksheet.addRow();
      let indice = 0;
      let bandera=false;
      keyColumns.map(function (data2) {
          let cell = dataRow.getCell(indice + 1);
          cell.value = data[data2];
          if ((cell.value=="TOTAL") || (cell.value=="TEUS") || (cell.value=="PESO") || (cell.value=="WEIGHT")) bandera = true;

          if (bandera) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                },
            }
          }
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
          indice++;
      })
    })

    //CUADRO 2
    worksheet.addRow();
    let data = rowData.cuadro2[0];
    //TITULO COMBINADO
    if (data.LINEA == "RESUMEN") {
      let lineaActual = rowData.cuadro1.length + 5;
      //Grupo 1
      let cellA4 = worksheet.getCell("A"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.LINEA;
      //Grupo 2
      worksheet.mergeCells("B"+lineaActual+":C"+lineaActual);
      cellA4 = worksheet.getCell("C"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_DL;
      //Grupo 3
      worksheet.mergeCells("D"+lineaActual+":E"+lineaActual);
      cellA4 = worksheet.getCell("D"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_DV;
      //Grupo 4
      worksheet.mergeCells("F"+lineaActual+":G"+lineaActual);
      cellA4 = worksheet.getCell("F"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_ML;
      //Grupo 5
      worksheet.mergeCells("H"+lineaActual+":I"+lineaActual);
      cellA4 = worksheet.getCell("I"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_T;
    }
    
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //headerRow.height = 50;

    columnsDefs = Object.keys(rowData.cuadro2[1]);
    keyColumns = columnsDefs;
    i = 0;
    let spliteo = [];
    
    columnsDefs.map(function (data) {
        let valor = bandera ? data.length : data.length;
        
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
          long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        
        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        
        spliteo = data.split("_");
        cell.value = spliteo[0];
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })
    
    rowData.cuadro2.map(async function (data) {
      if (data.LINEA != "RESUMEN") {
          let dataRow = worksheet.addRow();
          let indice = 0;
          let bandera=false;
          keyColumns.map(function (data2) {
              let cell = dataRow.getCell(indice + 1);
              cell.value = data[data2];
              if (cell.value=="TOTAL") bandera = true;

              if (bandera) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '262626'
                    }
                }
                cell.font = {
                    color: {
                      argb: 'ffffff'
                    }
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
              cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
              indice++;
          })
      }
    })

    //CUADRO 3
    worksheet.addRow();
    worksheet.addRow();
    data = rowData.cuadro3[0];
    //TITULO COMBINADO
    if (data.LINEA == "RESUMEN") {
      let lineaActual = rowData.cuadro1.length + rowData.cuadro2.length + 7;
      //Grupo 1
      let cellA4 = worksheet.getCell("A"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.LINEA;
      //Grupo 2
      worksheet.mergeCells("B"+lineaActual+":C"+lineaActual);
      cellA4 = worksheet.getCell("C"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_DL;
      //Grupo 3
      worksheet.mergeCells("D"+lineaActual+":E"+lineaActual);
      cellA4 = worksheet.getCell("D"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_DV;
      //Grupo 4
      worksheet.mergeCells("F"+lineaActual+":G"+lineaActual);
      cellA4 = worksheet.getCell("F"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_ML;
      //Grupo 5
      worksheet.mergeCells("H"+lineaActual+":I"+lineaActual);
      cellA4 = worksheet.getCell("I"+lineaActual);
      cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      cellA4.font = {
          name: "Calibri",
          family: 4,
          underline: false,
          bold: true
      };
      cellA4.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '262626'
          }
      }
      cellA4.font = {
          color: {
            argb: 'ffffff'
          }
      }
      cellA4.value = data.CANTIDAD_T;
    }

    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //headerRow.height = 50;

    columnsDefs = Object.keys(rowData.cuadro3[1]);
    keyColumns = columnsDefs;
    i = 0;
    spliteo = [];
    
    columnsDefs.map(function (data) {
        let valor = bandera ? data.length : data.length;
        
        let long = 10;
        if ((valor >= 5) && (valor <= 12)) {
            long = 20;
        } else if ((valor >= 11) && (valor <= 18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor >= 27) {
            long = 40;
        }

        
        worksheet.getColumn(i + 1).width = long;
        let cell = headerRow.getCell(i + 1);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '262626'
            }
        }
        
        spliteo = data.split("_");
        cell.value = spliteo[0];
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        i++;
    })
    
    rowData.cuadro3.map(async function (data) {
      if (data.LINEA == "RESUMEN") {
          /*//Grupo 1
          worksheet.mergeCells("C4:F4");
          let cellA4 = worksheet.getCell("D4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'cafbf3'
              }
          }
          cellA4.value = data.Cont20FtDeck;
          //Grupo 2
          worksheet.mergeCells("G4:J4");
          cellA4 = worksheet.getCell("H4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'f9b115'
              }
          }
          cellA4.value = data.Cont20FtHold;
          //Grupo 3
          worksheet.mergeCells("K4:N4");
          cellA4 = worksheet.getCell("L4");
          cellA4.alignment = { vertical: 'middle', horizontal: 'center' }
          cellA4.font = {
              name: "Calibri",
              family: 4,
              underline: false,
              bold: true
          };
          cellA4.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: 'cccccc'
              }
          }
          cellA4.value = data.Cont20FtTotal;*/
      } else {
          let dataRow = worksheet.addRow();
          let indice = 0;
          let bandera=false;
          keyColumns.map(function (data2) {
              let cell = dataRow.getCell(indice + 1);
              cell.value = data[data2];
              if (cell.value=="TOTAL") bandera = true;

              if (bandera) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '262626'
                    },
                    color: {
                      argb: 'ffffff'
                    }
                }
                cell.font = {
                    color: {
                      argb: 'ffffff'
                    }
                }
              }
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
              cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
              indice++;
          })
      }
    })
    
    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
    .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
            group: 'container',
            text: 'Reporte generado exitosamente',
            type: "success"
        });
    })
  }

  const monthNames = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO","JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

async function alianza () {
  await this.$http.get("VisitStrategicAllianceReport", { Year: this.anio, Month: this.mes })
  .then(response => {
    let listado = [...response.data.data];
    
    let rowData = [];
    
    //CUADRO 1
    listado.forEach(x => {
      rowData.push({
        No: x.IdX,
        BUQUE: x.VesselName,
        VIAJE: x.Voyage,
        AGENCIA: x.Agency,
        ESTIBA: x.Stevedore,  
        MUELLE: x.BerthName,  
        ATRAQUE: x.Ata,   
        HORA: x.AtaHour,  
        INICIO_OPS: x.BeginningOperations,  
        HORA_: x.BeginningOperationsHour,
        FULL: x.DischargeFull,
        MTY: x.DischargeEmpty,
        FULL_: x.LoadFull,
        MTY_: x.LoadEmpty,
        TERMINO_OPS: x.EndOperations,
        HORA_T: x.EndOperationsHour,
        DESATRAQUE: x.Atd,
        HORA_D: x.AtdHour,
      });
    });

    if (rowData.length > 0) this.reporteAlianzaEstrategica(rowData,'BUQUES ATENDIDOS EN '+monthNames[this.mes]+' '+this.anio);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

async function reporteAlianzaEstrategica(rowData, titulo) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("ALIANZA ESTRATÉGICA");
    const documento = "ALIANZA ESTRATEGICA".toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    //INFO BUQUE
  
    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    columnsDefs = Object.keys(rowData[0]);
    keyColumns = columnsDefs;
    y = 18;
    bandera = false;

    if (y < 10) letra = 8;
    worksheet.mergeCells('A1:A1');
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:A1');

    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;

    worksheet.mergeCells("B1:" + numeroLetra(y) + "1");

    const customCell = worksheet.getCell("B1");
    const row = worksheet.getRow(1);
    row.height = 60;
    customCell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell.note = {
        margins: {
            insetmode: 'auto',
            inset: [10, 10, 5, 5]
        }
    }
    customCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        },
    }
    customCell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
    customCell.value = "ALIANZA ESTRATEGICA COMERCIAL CON BOLIVARIANA DE PUERTOS S.A.";
    worksheet.addRow();

    worksheet.mergeCells("A3:R3");
    const customCell1 = worksheet.getCell("M³");
    customCell1.height = 30;
    customCell1.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell1.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell1.value = titulo;

    const hoy = new Date().toLocaleString();
    worksheet.mergeCells("A4:L4");
    worksheet.mergeCells("M4:O4");
    const customCell2 = worksheet.getCell("M4");
    customCell2.height = 50;
    customCell2.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell2.value = "IMPRESIÓN:  "+hoy;
    worksheet.mergeCells("P4:R4");
    const customCell4 = worksheet.getCell("P4");
    customCell4.height = 50;
    customCell4.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell4.value = "USUARIO: " + this.user.UserName + " " + this.user.UserLastName;

    worksheet.mergeCells("A5:J5");
    let ccell = worksheet.getCell("A5");
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.mergeCells("K5:L5");
    ccell = worksheet.getCell("K5");
    ccell.height = 50;
    ccell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        }
    };
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    ccell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    ccell.value = "DESCARGA";

    worksheet.mergeCells("M5:N5");
    ccell = worksheet.getCell("N5");
    ccell.height = 50;
    ccell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        }
    };
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    ccell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    ccell.value = "CARGA";
    
    worksheet.mergeCells("O5:R5");
    ccell = worksheet.getCell("O5");
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //CUADRO 1
    let i = 0;
    columnsDefs.map(function (data) {
      let valor = bandera ? data.headerName.length : data.length;
      let long = 10;
      if ((valor >= 5) && (valor <= 12)) {
          long = 20;
      } else if ((valor >= 11) && (valor <= 18)) {
          long = 25;
      } else if ((valor >= 19) && (valor <= 26)) {
          long = 30;
      } else if (valor >= 27) {
          long = 40;
      }

      worksheet.getColumn(i + 1).width = long;
      let cell = headerRow.getCell(i + 1);
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '808080'
          }
      }
      cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
      }
      cell.value = data.replace("_"," ");
      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      i++;
    })

    rowData.map(async function (data) {
      let dataRow = worksheet.addRow();
      let indice = 0;
      keyColumns.map(function (data2) {
          let cell = dataRow.getCell(indice + 1);
          cell.value = data[data2];
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
          indice++;
      })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
    .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
            group: 'container',
            text: 'Reporte generado exitosamente',
            type: "success"
        });
    })
}

async function atendidos () {
  await this.$http.get("VisitVesselsServicedReport", { Year: this.anio, Month: this.mes })
  .then(response => {
    let listado = [...response.data.data];
    let bandera = true;
    
    let cuadro = [];
    let rowData = [];
    let arrtotales = [1,0,0,0,0,0,0];
    //CUADRO 1
    listado.forEach(x => {
      if (x.LineMovsJson) {
        x.LineMovsJson.forEach(y => {
          if (bandera) {
            cuadro.push({
              No: x.IdX,
              BUQUE: x.VesselName,
              VIAJE: x.Voyage,
              ATRAQUE: x.Ata ? DateFormater.formatOnlyDateWithSlash(x.Ata) : '',
              ZARPE: x.Atd ? DateFormater.formatOnlyDateWithSlash(x.Ata) : '',
              LINEA: y.ShippingLineCode,
              IMPORT: y.ImportTotal,  
              FULL: y.ImportFull,  
              EMPTY: y.ImportEmpty,   
              EXPORT: y.ExportTotal,  
              FULL_: y.ExportFull,  
              EMPTY_: y.ExportEmpty,
              FACTURA_No: '',
              FECHA_FACTURACION: '',
              BASE_IMPONIBLE: '',
              TOTAL_USD: '',
              BOLIPUERTOS: '',
              COMPANY: '',
            });
          } else {
            cuadro.push({
              No: '',
              BUQUE: '',
              VIAJE: '',
              ATRAQUE: '',
              ZARPE: '',
              LINEA: y.ShippingLineCode,
              IMPORT: y.ImportTotal,  
              FULL: y.ImportFull,  
              EMPTY: y.ImportEmpty,   
              EXPORT: y.ExportTotal,  
              FULL_: y.ExportFull,  
              EMPTY_: y.ExportEmpty,
              FACTURA_No: '',
              FECHA_FACTURACION: '',
              BASE_IMPONIBLE: '',
              TOTAL_USD: '',
              BOLIPUERTOS: '',
              COMPANY: '',
            });

            arrtotales[0]+= 1;
            arrtotales[1]+= parseInt(y.ImportTotal);
            arrtotales[2]+= parseInt(y.ImportFull);
            arrtotales[3]+= parseInt(y.ImportEmpty);
            arrtotales[4]+= parseInt(y.ExportTotal);
            arrtotales[5]+= parseInt(y.ExportFull);
            arrtotales[6]+= parseInt(y.ExportEmpty);
          }
          bandera = false;
        });
        cuadro.push({
          No: 'TOTAL',
          BUQUE: '',
          VIAJE: '',
          ATRAQUE: '',
          ZARPE: '',
          LINEA: arrtotales[0],
          IMPORT: arrtotales[1],  
          FULL: arrtotales[2],  
          EMPTY: arrtotales[3],   
          EXPORT: arrtotales[4],  
          FULL_: arrtotales[5],  
          EMPTY_: arrtotales[6],
          FACTURA_No: '',
          FECHA_FACTURACION: '',
          BASE_IMPONIBLE: '',
          TOTAL_USD: '',
          BOLIPUERTOS: '',
          COMPANY: '',
        });
      } else {
         cuadro.push({
            No: x.IdX,
            BUQUE: x.VesselName,
            VIAJE: x.Voyage,
            ATRAQUE: x.Ata ? DateFormater.formatOnlyDateWithSlash(x.Ata) : '',
            ZARPE: x.Atd ? DateFormater.formatOnlyDateWithSlash(x.Ata) : '',
            LINEA: '',
            IMPORT: 0,  
            FULL: 0,  
            EMPTY: 0,   
            EXPORT: 0,  
            FULL_: 0,  
            EMPTY_: 0,
            FACTURA_No: '',
            FECHA_FACTURACION: '',
            BASE_IMPONIBLE: '',
            TOTAL_USD: '',
            BOLIPUERTOS: '',
            COMPANY: '',
          });

        cuadro.push({
          No: 'TOTAL',
          BUQUE: '',
          VIAJE: '',
          ATRAQUE: '',
          ZARPE: '',
          LINEA: 0,
          IMPORT: 0,  
          FULL: 0,  
          EMPTY: 0,   
          EXPORT: 0,  
          FULL_: 0,  
          EMPTY_: 0,
          FACTURA_No: '',
          FECHA_FACTURACION: '',
          BASE_IMPONIBLE: '',
          TOTAL_USD: '',
          BOLIPUERTOS: '',
          COMPANY: '',
        });
      }      

      rowData.push(cuadro);
      cuadro = [];
      arrtotales = [1,0,0,0,0,0,0];
      
      bandera=true;
    });
    //OJO DESACTIVAR ESTO, ES PARA VER 2 CUADROS
    //rowData.push(cuadro);
    if (rowData.length > 0) this.reporteBuquesAtendidos(rowData,'BUQUES ATENDIDOS EN '+monthNames[8]+' '+this.anio);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

async function reporteBuquesAtendidos(rowData, titulo) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("BUQUES ATENDIDOS");
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
  //INFO BUQUE

  let y = 0;
  let letra = 12;
  let columnsDefs = [];
  let keyColumns = [];
  let bandera = true;

  columnsDefs = Object.keys(rowData[0][0]);
  keyColumns = columnsDefs;
  y = 18;
  bandera = false;

  if (y < 10) letra = 8;
  worksheet.mergeCells('A1:A1');
  const response1 = await this.$http.getFile('base64', {
      path: this.user.LogoRoute.replace('Public/', '', null, 'i')
  });

  const divisiones1 = this.user.LogoRoute.split(".");
  const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
  const imageId = workbook.addImage({
      base64: myBase64Image,
      extension: divisiones1[1],
  });
  worksheet.addImage(imageId, 'A1:A1');

  const rowIndex1 = worksheet.getRow(1);
  rowIndex1.height = 100;

  worksheet.mergeCells("B1:" + numeroLetra(y) + "1");

  const customCell = worksheet.getCell("B1");
  const row = worksheet.getRow(1);
  row.height = 60;
  customCell.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell.note = {
      margins: {
          insetmode: 'auto',
          inset: [10, 10, 5, 5]
      }
  }
  customCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: 'f2f2f2'
      },
  }
  customCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
  }
  customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
  customCell.value = titulo;

  const hoy = new Date().toLocaleString();
  worksheet.mergeCells("A2:L2");
  worksheet.mergeCells("M2:O2");
  const customCell2 = worksheet.getCell("M2");
  customCell2.height = 50;
  customCell2.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
  customCell2.value = "IMPRESIÓN:  "+hoy;
  worksheet.mergeCells("P2:R2");
  const customCell4 = worksheet.getCell("P2");
  customCell4.height = 50;
  customCell4.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
  customCell4.value = "USUARIO: " + this.user.UserName + " " + this.user.UserLastName;

  rowData.forEach(x => {
    let i = 0;
    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    headerRow.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    //TITULO COMBINADO
    let dataRow1 = worksheet.addRow();
    let cellA4 = dataRow1.getCell(i+1);
    let valAct = cellA4.address.slice(1);
    // worksheet.mergeCells("A"+valAct+":F"+valAct);
    worksheet.mergeCells("G"+valAct+":L"+valAct);
    cellA4 = worksheet.getCell("G"+valAct);
    
    cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    cellA4.font = {
        color: {
          argb: 'ffffff'
        }
    }
    cellA4.value = "CANTIDAD DE CONTENEDORES MOVILIZADOS (TDR)";
    cellA4.border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };

    //CUADRO 1
    i = 0;
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
   /* headerRow.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };*/

    let inicio = headerRow._number+1; 
    let final= 0;
    let inicio2 = headerRow._number-1; 
    let final2= headerRow._number;

    worksheet.mergeCells("A"+inicio2+":A"+final2); 
    worksheet.mergeCells("B"+inicio2+":B"+final2); 
    worksheet.mergeCells("C"+inicio2+":C"+final2); 
    worksheet.mergeCells("D"+inicio2+":D"+final2); 
    worksheet.mergeCells("E"+inicio2+":E"+final2); 
    worksheet.mergeCells("F"+inicio2+":F"+final2);
    worksheet.mergeCells("M"+inicio2+":M"+final2); 
    worksheet.mergeCells("N"+inicio2+":N"+final2); 
    worksheet.mergeCells("O"+inicio2+":O"+final2); 
    worksheet.mergeCells("P"+inicio2+":P"+final2); 
    worksheet.mergeCells("Q"+inicio2+":Q"+final2); 
    worksheet.mergeCells("R"+inicio2+":R"+final2); 
//ws.getCell('A1').border
    worksheet.getCell("A"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("B"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("C"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("D"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("E"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("F"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("M"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("N"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("O"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("P"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("Q"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("R"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };

    worksheet.getCell("A"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("B"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("C"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("D"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("E"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("F"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("M"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("N"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("O"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("P"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("Q"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("R"+inicio2).font = { color: {  argb: 'ffffff' } };

    columnsDefs.map(function (data) {
      let valor = bandera ? data.headerName.length : data.length;
      let long = 10;
      if ((valor >= 5) && (valor <= 12)) {
          long = 20;
      } else if ((valor >= 11) && (valor <= 18)) {
          long = 25;
      } else if ((valor >= 19) && (valor <= 26)) {
          long = 30;
      } else if (valor >= 27) {
          long = 40;
      }

      worksheet.getColumn(i + 1).width = long;
      let cell = headerRow.getCell(i + 1);
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '808080'
          }
      }
      cell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }; 
      let valAct = cell.address.slice(1);
      cell.value = data.replace("_"," ");
      if (data=="BOLIPUERTOS") {
        cell.value = "PARTICIPACION ALIANZA \nBOLIPUERTOS POR \nFACTURACION (60%)";
        worksheet.getColumn(i + 1).width = 35;          
        worksheet.getRow(valAct).height = 50;
      } else if (data=="COMPANY") {
        cell.value = "PARTICIPACION ALIANZA \nOCKxd POR \nFACTURACION (40%)";
        worksheet.getColumn(i + 1).width = 35;
      }

      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      i++;
    })
    let b2 = 0;
    let btotal = false;
    x.map(async function (data) {
      let dataRow = worksheet.addRow();

      let indice = 0;
      keyColumns.map(function (data2) {
        let cell = dataRow.getCell(indice + 1);
        
        final = cell._row._number - 1; 
        /*let letra = cell.address[0];
        if (b2 < letrasMerge.length) {
          if(letrasMerge.indexOf(letra) !== -1){
            let valAct = cell.address.slice(1, -1);
            console.log(cell.address);
            console.log(letra+(valAct+x.length));
            //worksheet.mergeCells(cell.address+":"+letra+x.length);

          }
        }*/
        cell.value = data[data2];
        if (cell.value=="TOTAL") btotal = true;

        if (btotal) {
          cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: '808080'
              },
              color: {
                argb: 'ffffff'
              }
          }
          cell.font = {
              color: {
                argb: 'ffffff'
              }
          }
        }

        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        indice++;
      })
    })
    
    worksheet.mergeCells("A"+inicio+":A"+final);
    worksheet.mergeCells("B"+inicio+":B"+final);
    worksheet.mergeCells("C"+inicio+":C"+final);
    worksheet.mergeCells("D"+inicio+":D"+final);
    worksheet.mergeCells("E"+inicio+":E"+final);
    worksheet.mergeCells("M"+inicio+":R"+final);
    cellA4 = worksheet.getCell("M"+inicio);
    cellA4.value = "PARA SER LLENADO EN ADMON CENTRAL";
  });

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/", "");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
  //return false;
  workbook.xlsx.writeBuffer()
  .then(buffer => {
      saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
      this.$notify({
          group: 'container',
          text: 'Reporte generado exitosamente',
          type: "success"
      });
  })
}

function activeItem(item) {
  return this.dropItemReport === item;
}

export default {
  name: 'index-reporte',
  data,
  mixins: [GeneralMixin],
  components: {
      TransactionsTablets,
      VehicleBinSummary,
      yardClientSummary,
      TransportSummary,
      SumariesDailyReport,
      SumariesFinalReport,
      BinsReturned,
      TruckScale,
      OrdersByYard,
      OrdersNotWeight,
      OrdersByLapse,
      OrdersByWeight,
      OrdersByScrapContainer,
      OrdersByVehicle,
      OdersByDriver,
      OrdersByCarrier,
      Containerlist,
      imdgList,
      VgmDetail,
      Restowlist,
      Loadlist,
      CanceledList,
      DischargeList,
      ReeferList,
      containerByTypeSummary,
      containerByPortLineSummary,
      portSummary,
      imdgContainerSummary,
      containerByTypeStatus,
      oversizedList,
      typeLoadPerCraneSummary,
      transactionReversalsList,
      InspectionDone,
      binWeighingSummary,
      InactiveMovements
  },
  methods: {
    numeroLetra,
    reporteExcel,
    llenado,
    atendidos,
    alianza,
    reporteBuquesAtendidos,
    reporteAlianzaEstrategica,
    activeItem,
    getPdf
  },
  computed: {
    ...mapState({
        Visit: state => state.visitas.VisitId,
        user: (state) => state.auth.user,
        itinerarySelected: state => state.visitas.itinerarySelected,
        dropItemReport: state => state.visitas.dropItemReport,
    })
  },
};
</script>
<style scope>
</style>


